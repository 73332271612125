import Journal from "@/models/journal";
import BaseProvider from "./baseprovider"
import Item from '@/models/item';
import NameJournal from '@/models/name/namejournalmodel';

export default class JournalProvider extends BaseProvider {
    public async fetchJournals(entityIds?: number[], name?: boolean): Promise<Journal[] | NameJournal[]> {
        let p: string = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("name", [name]);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/Journals${p}`);

        const journals = response.data.map((d: any) => this.getJournal(d));

        return journals;
    }

    public async fetchAvailableJournals(storeIds: number[], type: number, flowType: number): Promise<Journal[]> {
        let p: string = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p.addParams("type", [type], false);
        p = p.addParams("flowType", [flowType], false);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/Journals/Available${p}`);

        const journals = response.data.map((d: any) => this.getJournal(d));

        return journals;
    }

    public async fetchLinkedJournals(journalUid: string): Promise<Journal[]> {
        const response = await (await this.getApiV2()).get(`/Journals/${journalUid}/Journals`);

        const journals = response.data.map((d: any) => this.getJournal(d));

        return journals;
    }

    private getJournal(data: any): Journal {
        const journal = new Journal(data);

        return journal;
    }

    public async getJournalFromUid(journalUid: string): Promise<Journal> {
        const response = await (await this.getApiV2()).get(`/journals/${journalUid}`);

        return this.getJournal(response.data);
    }

    public async saveJournal(journal: Journal): Promise<Journal> {
        const response = await (await this.getApiV2()).post(`/journals/`, journal);

        const journals = this.getJournal(response.data);

        return journals;
    }

    public async postJournalImports (source: string) {
        const response = await (await this.getApiV2()).post(`/journals/Import/${source}`);

        const journals = response.data.map((d: any) => this.getJournal(d));

        return journals;
    }
}