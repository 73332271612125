<template>
  <div class="empty-indicator" v-bind="$attrs">
    <img v-if="indicator === 'empty_list'"
				:width="width"
				 src="@/assets/img_empty_list.svg"
				 alt='Empty list'/>
		<img v-else-if="indicator === 'no_result'"
				 :width="width"
				 src="@/assets/img_no_result.svg"
				 alt='Empty list'/>
		<img v-else
				 :width="width"
				 src="@/assets/img_error.svg"
				 alt='Empty list'/>
    <span class="description">{{ description }}</span>
  </div>
</template>
<style lang="scss">
.empty-indicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .description {
    margin-top: 2em;
    text-align: center;
  }
}
</style>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

type EmptyIndicatorType = 'empty_list' | 'no_result' | 'error'

export default defineComponent({
  props: {
    description: String,
    indicator: {
			type: String as PropType<EmptyIndicatorType>
		},
    width: {
      type: Number,
      default: 220,
    }
  },

  setup(props) {
		const imgSrc = computed(() => `@/assets/img_${props.indicator}.svg`)

		return  {
			imgSrc
		}
  },
});
</script>

