import MatrixTemplate from "@/models/matrixtemplate";
import BaseProvider from "./baseprovider"
import { PagedParams } from '@/models/paged/pagedparams';
import PagedMatrixTemplates from '@/models/paged/pagedmatrixtemplates';
import MatrixViewModel from "@/models/view/matrixviewmodel";
import Matrix from "@/models/matrix";
import MatrixButtonViewModel from "@/models/view/matrixbuttonviewmodel";
import MatrixButton from "@/models/matrixbutton";
import ItemLevel from "@/models/itemlevel";

export default class MatrixTemplateProvider extends BaseProvider {
    public async fetchPagedMatrixTemplates(entityIds: number[], pagedParams?: PagedParams): Promise<PagedMatrixTemplates> {
        let p: string = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc',
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/matrixtemplates${p}`);

        const pagedMatrixTemplates = new PagedMatrixTemplates(response.data);

        return pagedMatrixTemplates;
    }

    public async fetchAvailableMatrices(entityIds: number[], excludedIds: number[]): Promise<Matrix[]> {
        let p: string = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("excludedIds", excludedIds, true);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/matrixtemplates/matrices/Available${p}`);

        const matrices = response.data.map((d: any) => new Matrix(d));

        return matrices;
    }

    public async getMatrixTemplateFromUid(matrixtemplateUid: string): Promise<MatrixTemplate> {
        const response = await (await this.getApiV2()).get(`/matrixtemplates/${matrixtemplateUid}`);

        return new MatrixTemplate(response.data);
    }

    public async saveMatrixTemplate(matrixtemplate: MatrixTemplate): Promise<MatrixTemplate> {
        const response = await (await this.getApiV2()).post(`/matrixtemplates/`, matrixtemplate);

        return new MatrixTemplate(response.data);
    }

    public async fetchTemplateMatrixViewModels(matrixTemplateUid: string): Promise<MatrixViewModel[]> {
        const response = await (await this.getApiV2()).get(`/matrixtemplates/${matrixTemplateUid}/matrices`);

        const matrices = response.data.map((d: any) => this.getMatrixViewModel(d));

        return matrices;
    }

    public async fetchMatrixTemplatesCount(entityIds: number[]): Promise<number> {
        let p: string = '';

        p = p.addParams("entityIds", entityIds, true);
        
        p = p !== '' ? `?${p}` : ''

        const response = await (await this.getApiV2()).get(`/matrixtemplates/Count${p}`)

        return response.data
    }

    private getMatrixViewModel(data: any): MatrixViewModel {
        const matrix = new MatrixViewModel({
            matrix: new Matrix(data),
            buttons: data.buttons?.map((b: any) => this.getMatrixButtonViewModel(b)),
            order_number: data.order_number,
        });

        return matrix;
    }

    private getMatrixButtonViewModel(data: any): MatrixButtonViewModel {
        const button = new MatrixButtonViewModel({
            button: new MatrixButton(data),
            column_index: data.column_index,
            row_index: data.row_index,
            itemLevel: null,
            item_ask_price: data.item_ask_price,
            item_ask_weight: data.item_ask_weight,
            item_has_propositions: data.item_has_propositions,
            item_is_menu: data.item_is_menu,
            item_is_negative: data.item_is_negative,
            item_is_returnable_deposit: data.item_is_returnable_deposit,
        });

        if (button.itemId && data.item_level1_id) {
            button.itemLevel = new ItemLevel({
                id: data.item_level1_id,
                color: data.item_level1_color,
            })
        }

        return button;
    }

    public async saveMatrix(matrix: Matrix, matrixTemplateUid: string): Promise<Matrix> {
        const response = await (await this.getApiV2()).post(`/matrixtemplates/${matrixTemplateUid}/matrix`, matrix);

        const savedMatrix = new Matrix(response.data);

        return savedMatrix;
    }

    public async saveMatrixViewModel(matrix: MatrixViewModel, matrixTemplateUid: string): Promise<MatrixViewModel> {
        const response = await (await this.getApiV2()).post(`/matrixtemplates/${matrixTemplateUid}/matrix`, {
            ...matrix.matrix,
            buttons: matrix.buttons?.map((button) => {
                return {
                    ...button.button,
                    row_index: button.row_index,
                    column_index: button.column_index,
                };
            }),
        });

        const savedMatrix = this.getMatrixViewModel(response.data);

        return savedMatrix;
    }

    public async reorderMatrixViewModels(matrices: MatrixViewModel[], matrixTemplateUid: string): Promise<void> {
        const body = matrices.map(m => {
            return {
                uid: m.uid,
                order_number: m.order_number,
            };
        });

        const response = await (await this.getApiV2()).post(`/matrixtemplates/${matrixTemplateUid}/Reorder`, body);

        return Promise.resolve();
    }

    public async linkMatrix(matrixTemplateUid: string, matrixUid: string, orderNumber: number): Promise<void> {
        const response = await (await this.getApiV2()).post(`/matrixtemplates/${matrixTemplateUid}/LinkMatrix/${matrixUid}/OrderNumber/${orderNumber}`);

        return Promise.resolve();
    }

    public async unlinkMatrix(matrixTemplateUid: string, matrixUid: string): Promise<void> {
        const response = await (await this.getApiV2()).post(`/matrixtemplates/${matrixTemplateUid}/UnlinkMatrix/${matrixUid}`);

        return Promise.resolve();
    }

    // public async getMatrixButtonActionViewModels(entityIds: number[], filters: {}): Promise<MatrixButtonViewModel[]> {
    //     let p: String = '';
    //     p = p.addParams("entityId", entityIds, true);
    //     p = p.addParams("actionTypes", ['PushMatrix']);
    //     // p = p.addParams("filters", [filters], true);
    //     p = p !== '' ? `?${p}` : '';

    //     const response = await (await this.getApiV2()).get(`/matrixtemplates/Buttons${p}`);

    //     const buttons = response.data.map((d: any) => this.getMatrixButtonViewModel(d));

    //     return buttons;
    // }
}