<template>
  <div class="login-container">
    <Card class="login">
      <template #header>
        <img width="220" src="@/assets/logo-restomax.svg" />
      </template>
      <template #subtitle>
        <h2 class="welcome-text" v-html="$t('Login.WelcomePortal')"></h2>
      </template>
      <template #content>
        <form autocomplete="off" class="p-fluid">
          <br />
          <div class="field">
            <label for="login">{{ $t('Login.Email') }}</label>
            <InputText autocomplete="off" spellcheck="false" id="login" v-model="form.login"
                       :placeholder="$t('Login.EmailPlaceholder')" />
          </div>
          <div class="field">
            <label for="password">{{ $t('Login.Password') }}</label>
            <InputText autocomplete="off" spellcheck="false" id="password" v-model="form.password" type="password"
                       :placeholder="$t('Login.PasswordPlaceholder')" @keypress.enter="login()" />
          </div>
          <a :href="getLink('reset-password')">
            {{ $t('Login.Reset') }}
          </a>
          <br/><br/>
          <InlineMessage severity="error"
                         :closable="false"
                         v-if="error">
            {{
                error?.response?.data?.error_description ? $t(error?.response?.data?.error_description) :
                error?.response?.data?.error ? $t(error?.response?.data?.error) :
                error?.response?.data ? $t(error?.response?.data) :
                $t('Login.Invalid')
            }}
          </InlineMessage>
          <br/><br/>
          <Button class="p-button-rounded p-button-l background-gradient-sunrise"
            :label="$t('Login.Connect')" icon="fas fa-chevron-right" @click="login"
            color="primary" elevation="2" :loading="isLoading" iconPos="right">
          </Button>
        </form>
      </template>
      <template #footer>
        <div>
          <div class="login-features">
            <div class="vertical-icon">
              <i class="fas fa-tachometer-alt" />
              <div class="title">{{ $t('Login.Dashboard') }}</div>
            </div>
            <div class="vertical-icon">
              <i class="fas fa-box-open" />
              <div class="title">{{ $t('Login.Item') }}</div>
            </div>
            <div class="vertical-icon">
              <i class="fas fa-cubes" />
              <div class="title">{{ $t('Login.Stock') }}</div>
            </div>
            <div class="vertical-icon">
              <i class="fas fa-ticket-alt" />
              <div class="title">{{ $t('Login.Receipt') }}</div>
            </div>
            <div class="vertical-icon">
              <i class="fas fa-id-card" />
              <div class="title">{{ $t('Login.Customer') }}</div>
            </div>
            <div class="vertical-icon">
              <i class="fas fa-chart-pie" />
              <div class="title">{{ $t('Login.Reporting') }}</div>
            </div>
          </div>
          <div class="appVersion">v{{ version }}</div>
        </div>
        <div class="flex flex-row justify-content-end">
          <a class="ml-2" style="cursor: pointer"
             @click="changeLanguage('fr')">
            <CountryFlag v-if="$route.params.locale !== 'fr'" size="small" country="fr" />
          </a>
          <a class="ml-2" style="cursor: pointer"
             @click="changeLanguage('nl')">
            <CountryFlag v-if="$route.params.locale !== 'nl'" size="small" country="nl" />
          </a>
          <a class="ml-2" style="cursor: pointer"
             @click="changeLanguage('en')">
            <CountryFlag v-if="$route.params.locale !== 'en'" size="small" country="gb" />
          </a>
        </div>
      </template>
    </Card>
  </div>
</template>
<style lang="scss">
@import "@/scss/_variables.scss";

@keyframes slidebackground {
  from {
    background-position-x: 0%;
  }

  to {
    background-position-x: 100%;
  }
}

.plugandpos-wrap.route-login,
.plugandpos-wrap.route-home {
  background: url("@/assets/restomax-login-background@2x.png") 50% center no-repeat;
  background-size: cover;
  animation-name: "slidebackground";
  animation-duration: 60s;
  animation-direction: alternate;
  animation-iteration-count: 3;

  .bo-content {
    margin-left: 0;
    background: none;
  }
}

.v-container {
  height: 100%;
}

.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @include for-md-up {
    justify-content: center;
  }
}

.login {
  padding: 20px 30px;
  width: 340px;

  text-align: center;
}

.login-features {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto auto auto;
}

.welcome-text {
  font-size: 1.25rem;
  font-weight: 200;

  em {
    font-style: normal;
    font-weight: 400;
  }
}
</style>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { authState } from '@/states/auth'
import { useRoute, useRouter } from 'vue-router'
import RouteHelper from '@/helpers/routehelper'
import CountryFlag from 'vue-country-flag-next'
import { useI18n } from 'vue-i18n'
import moment from 'moment/moment'

import packageJson from '@/../package.json'

export default defineComponent({
  components: {CountryFlag},
  setup() {
    const i18n = useI18n();
    const route = useRoute();
    const router = useRouter();
    const globalUser = computed(() => authState.state.globalUser);
    const accessToken = computed(() => authState.state.accessToken);
    const version = packageJson.version;
    const isLoading = ref(false);

    const getLink = (path: string) => RouteHelper.getLink(path);

    const form = reactive({
      login: null,
      password: null,
    });

    const error = ref();

    const login = async () => {
      isLoading.value = true;
      error.value = null;
      try {
        const loginResponse: string =
            await authState
              .dispatch({
                type: "login",
                login: form.login,
                password: form.password,
              });

        if (loginResponse) {
          if (!authState.state.organization!.is_system) await router.push({ name: "dashboardca" });
          else if (authState.state.organization!.id == 2000) await router.push({ name: "credentials" });
          else await router.push({ name: "dashboardlicenses" });
        }
      } catch (e: any) { error.value = e; }
      isLoading.value = false;
    };

    const loginWithTokens = async (accessToken: string, refreshToken: string) => {
      isLoading.value = true;
      error.value = null;
      try {
        const loginResponse = await authState
              .dispatch({
                type: 'loginWithTokens',
                accessToken,
                refreshToken
              });
        if (loginResponse) {
          if (!authState.state.organization!.is_system) await router.push({ name: "dashboardca" });
          else if (authState.state.organization!.id == 2000) await router.push({ name: "credentials" });
          else await router.push({ name: "dashboardlicenses" });
        } else await router.push({ name: "login" }); // Remove tokens from url
      } catch (e: any) { error.value = e; await router.push({ name: "login" }); } // Remove tokens from url
      isLoading.value = false;
    }

    const changeLanguage = async (language: string) => {
      error.value = null;
      i18n.locale.value = language;
      moment.locale(language)
      //axios.defaults.headers.common['Accept-Language'] = language;
      await router.push({ params: { locale: language } });
    };

    onMounted(async () => {
      const accessToken = route.query.accessToken;
      const refreshToken = route.query.refreshToken;

      if (accessToken && refreshToken)
        await loginWithTokens(accessToken as string, refreshToken as string);
    })


    return {
      i18n, changeLanguage,
      error,
      form,
      login,
      globalUser,
      accessToken,
      version,
      isLoading,
      getLink,
    };
  },
});
</script>
