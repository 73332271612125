<template>
  <div class="bo-view">
    <div class="bo-view-header mr-3 flex flex-column lg:flex-row flex-nowrap align-items-center">
      <div class="flex flex-row">
        <div class="flex flex-column w-auto">
          <h1 class="p-0">{{ $filters.capitalizeFirstLetter(t("dashboard")) }}</h1>
          <small class="p-muted mt-1" v-text="$filters.capitalizeFirstLetter(t('period_selector_warning'))" />
        </div>
        <BODropDownNumber v-show="currentPage === 'dashboardca'" class="ml-3" :modelValue="globalState.state.vatDisplay"
          @update:modelValue="globalState.dispatch('setVATDisplay', $event)" optionLabel="label" optionValue="id" :items="[
            { id: VATDisplayType.TVAC, label: $filters.cflt('vat_display_tvac') },
            { id: VATDisplayType.HTVA, label: $filters.cflt('vat_display_htva') },
            { id: VATDisplayType.BOTH, label: $filters.cflt('vat_display_both') }
          ]" :filter="false" :showClear="false" />
      </div>
      <div class="flex flex-column md:flex-row w-full md:w-auto">
        <div class="flex flex-row align-items-center justify-content-evenly mt-3 lg:mt-0">
          <Button class="p-button-vertical p-button-link mr-0 snpmm:mr-2"
            :label="$filters.capitalizeFirstLetter(t('refresh'))" icon="fas fa-file-download" @click="fetchAll" />
          <period-selector :default="globalState.state.period" @periodChanged="periodChanged" />
        </div>
      </div>
    </div>
    <div class="bo-view-body mb-2">
      <div>
        <!-- {{ report?.dashboardProvider?.receivedID }} - {{ report?.dashboardProvider?.requestID }} -->
        <!-- Do not use v-if here, as reports should stay loaded in DOM -->
        <!-- <loader v-show="loading" /> -->
        <!-- <div v-show="!loading"> -->
        <CA ref="dashboardCA" v-show="currentPage === 'dashboardca'" />
        <report-z ref="dashboardReportZ" v-show="currentPage === 'dashboardreportz'" />
        <item-levels ref="dashboardItemLevels" v-show="currentPage === 'dashboarditemlevels'" />
        <c-a-global-users ref="dashboardCAGlobalUsers" v-show="currentPage === 'dashboardcaglobalusers'" />
        <point-global-users ref="dashboardPointGlobalUsers" v-show="currentPage === 'dashboardpointglobalusers'" />
        <sales-details ref="dashboardSalesDetails" v-show="currentPage === 'dashboardsalesdetails'" />
        <listing ref="dashboardListing" v-show="currentPage === 'dashboardlisting'" />
        <datawarehouse ref="dashboardDatawarehouse" v-show="currentPage === 'dashboarddatawarehouse'" />
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import {computed, reactive, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {startOfToday, startOfTomorrow} from "@/models/common/period";
import {useI18n} from "vue-i18n";

import PeriodSelector from "@/components/Dashboard/PeriodSelector.vue";
import CA from "./Dashboard/CA.vue";
import ReportZ from "./Dashboard/ReportZ.vue";
import ItemLevels from "./Dashboard/ItemLevels.vue";
import CAGlobalUsers from "./Dashboard/CAGlobalUsers.vue";
import PointGlobalUsers from "./Dashboard/PointGlobalUsers.vue";
import SalesDetails from "./Dashboard/SalesDetails.vue";
import Listing from "./Dashboard/Listing.vue";
import Datawarehouse from "./Dashboard/Datawarehouse.vue";
import {globalState} from '@/states/global';

import BODropDownNumber from "@/components/Form/BODropDownNumber.vue";
import {VATDisplayType} from "@/models/enums/vatdisplay.enum";
import {useDebounceFn} from '@vueuse/core'

export default {
  components: {
    BODropDownNumber,
    PeriodSelector,
    CA,
    ReportZ,
    ItemLevels,
    CAGlobalUsers,
    PointGlobalUsers,
    SalesDetails,
    Listing,
    Datawarehouse,
  },
  setup() {
    const router = useRouter();
    const t = useI18n().t;

    // const loading = ref(true);
    const dashboardCA = ref();
    const dashboardReportZ = ref();
    const dashboardItemLevels = ref();
    const dashboardCAGlobalUsers = ref();
    const dashboardPointGlobalUsers = ref();
    const dashboardSalesDetails = ref();
    const dashboardListing = ref();
    const dashboardDatawarehouse = ref();

    const report = computed(() => {
      switch (currentPage.value) {
        case "dashboardca": return dashboardCA.value;
        case "dashboardreportz": return dashboardReportZ.value;
        case "dashboarditemlevels": return dashboardItemLevels.value;
        case "dashboardcaglobalusers": return dashboardCAGlobalUsers.value;
        case "dashboardpointglobalusers": return dashboardPointGlobalUsers.value;
        case "dashboardsalesdetails": return dashboardSalesDetails.value;
        case "dashboardlisting": return dashboardListing.value;
        case "dashboarddatawarehouse": return dashboardDatawarehouse.value;
        default: return dashboardCA.value;
      }
    });

    const dates = reactive({
      from: startOfToday.toDate(),
      to: startOfTomorrow.toDate(),
      previousPeriodStart: null,
    });
    const periodChanged = (period) => {
      globalState.dispatch('setPeriod', period);
      dates.from = period.start;
      dates.to = period.end;
      dates.previousPeriodStart = period.previousPeriodStart;
    };

    const currentPage = computed(() => {
      return router.currentRoute.value.name;
    });

    const fetchAll = async () => {
      if ((new RegExp(/\/[a-z]{2}\/dashboard\//)).test(router.currentRoute.value.fullPath)) {
        if (report?.value?.refresh) await report.value.refresh(dates);
      }
    }

    //const fetchAllDebounced = useDebounceFn(fetchAll, 500)

    const watchValues = computed(() => [globalState.state.selected, router.currentRoute.value, globalState.state.period]);

    watch(
        () => watchValues,
        () => fetchAll(),
        {deep: true, immediate: true}
    );

    return {
      t, report, globalState, VATDisplayType,
      currentPage,
      dates, periodChanged, fetchAll,
      dashboardCA, dashboardReportZ, dashboardItemLevels, dashboardCAGlobalUsers, dashboardPointGlobalUsers,
      dashboardSalesDetails, dashboardListing,
      dashboardDatawarehouse,
    }
  }
}
</script>