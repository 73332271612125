export default class PdfHelper {
    public static openPdfInNewTab = (base64Data: string) => {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i++)
            byteArrays.push(byteCharacters.charCodeAt(i));

        const byteArray = new Uint8Array(byteArrays);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const pdfUrl = URL.createObjectURL(blob);
        const newTab = window.open(pdfUrl, '_blank');
        if (newTab) newTab.focus();
    };
}