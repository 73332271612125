<template>
  <PanelMenu
      multiple
      :model="items"
      v-model:expandedKeys='expandedKeys'
      v-show="item.visible">
    <template #item="{ item }">
      <SidebarCollapsableItem :item='item' />
    </template>
  </PanelMenu>
</template>
<script lang="ts">
import SidebarCollapsableItem from '@/components/Layout/Sidebar/SidebarCollapsableItem/SidebarCollapsableItem.vue'
import RouteHelper from '@/helpers/routehelper'
import { SubMenuItem } from '@/menu/menu'
import type { MenuItem } from 'primevue/menuitem'
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    SidebarCollapsableItem
  },
  props: {
    item: SubMenuItem
  },
  setup(props) {
    const { currentRoute } = useRouter()
    const expandedKeys = ref({})
    const getLink = (path: string) => {
      if (!props.item?.preserveQueryParams) {
        return RouteHelper.getLink(path)
      }

      return RouteHelper.getLink(path, currentRoute.value.query)
    }
    const subMenuItemToMenuItem = (item: SubMenuItem): MenuItem => ({
      key: item.name,
      label: item.name,
      route: item?.link,
      icon: item?.icon,
      iconPath: item?.iconPath,
      iconColor: item.color,
      iconFamily: item.iconFamily,
      iconSharp: item.iconSharp,
      items: remapSubMenuItems(item?.items ?? [])
    })
    const remapSubMenuItems = (items: SubMenuItem[]): MenuItem[] => items.map(subMenuItemToMenuItem)
    const items = ref<MenuItem[]>([])

    const currentRouteExistsInItems = (node: MenuItem): boolean => {
      if (node.route && currentRoute.value.fullPath.includes(node.route)) {
        return true
      }
      if (node.items) {
        for (const child of node.items) {
          if (currentRouteExistsInItems(child as MenuItem)) {
            return true
          }
        }
      }
      return false
    }
    const expandAll = () => {
      for (const node of items.value) {
        expandNode(node)
      }

      expandedKeys.value = {
        ...expandedKeys.value
      }
    }

    const collapseAll = () => {
      expandedKeys.value = {}
    }

    const expandNode = (node: any) => {
      if (node.items && node.items.length) {
        (expandedKeys.value as any)[node.key] = true

        for (const child of node.items) {
          expandNode(child)
        }
      }
    }

    watch(() => props.item, (newVal) => {
      if (!newVal) {
        return
      }

      items.value = remapSubMenuItems([ newVal ])
    }, { immediate: true })

    onMounted(() => {
      if (items.value.some(currentRouteExistsInItems)) {
        expandAll()
      } else {
        collapseAll()
      }
    })

    return {
      expandedKeys,
      items,
      getLink
    }
  }
})
</script>
<style lang='scss'>
.p-panelmenu {
  .p-panelmenu-panel {
    .p-panelmenu-header {
      .p-panelmenu-header-content {
        a, li {
          padding: 0 !important;
        }
      }
    }

    .p-toggleable-content {
      .p-panelmenu-content {
        border: 0 !important;

        ul.p-submenu-list {
          padding: 0 0 0 .5rem;

          div.p-menuitem-content {
            a, li {
              padding: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
