<template>
  <div v-bind="$attrs" class="max-height-card">
    <Card class="card-greenforest overflow-y-hidden pt-3">
      <template #header>
        <div class="grid text-center">
          <div class="col-5 text-left">
            <h1>
              <i class="fas fa-shopping-bag color-greenforest"></i>&nbsp;&nbsp;
              <span>{{ t('dashboard_salesdetails_title').toUpperCase() }}</span>
            </h1>
          </div>
          <div class="col-2 text-center">
            <!-- <Button :disabled="syncing24h || loading"
                    class="col p-button p-button-rounded p-button-sm background-gradient-sunrise"
                    :icon="'fas fa-rotate' + (syncing24h ? ' fa-spin' : '')" :label="$filters.capitalizeFirstLetter(t('sync_24h'))"
                    @click="sync24h()"/> -->
          </div>
          <div class="col-5 text-right">
            <ExcelExport :data="Data"
                         :columns="columnNames.map(c => ({
                            source: c,
                            label: $filters.cflt(c),
                            type: ['item_name'].includes(c) ? 'string' :
                                  ['unit_price', 'total', 'total_discount'].includes(c) ? 'currency' :
                                  'number'
                         }))"
                         file-name="Export dashboard" file-type="xlsx" sheet-name="Data">
              <Button :class="'p-button p-button-rounded p-button-sm background-gradient-sunrise'"
                      icon="fas fa-file-download" :label="$filters.capitalizeFirstLetter(t('download_xlsx'))" />
            </ExcelExport>
          </div>
        </div>
      </template>
      <template #content>
        <!-- <SkeletonGrid v-if="loading" :length="5" :columns="columnNames.map(c => t(c))" /> -->
        <Progress class="flex justify-content-center" v-if="loading" />
        <div v-else>
          <div v-if="errorMessage" class="text-center my-4">
            {{ $filters.cflt('report_error') }}
          </div>
          <div v-if="(Data?.length ?? 0) == 0" class="text-center my-4"
            v-html="$filters.cflt('report_empty')" />
          <div class="grid">
            <div class="col-12 p-fluid">
              <DataTable
                ref="Report"
                :value="Data"
                v-model:filters="Filters"
                class="p-datatable-sm caglobalusers-datatable background-white"
                responsiveLayout="scroll"
                filterDisplay="row"
                selectionMode="single">
                <Column v-for="column in ((Data?.length ?? 0) > 0 ? columnNames : [])"
                        :key="column" :field="column" :filterField="column"
                        :style="column == 'Store' ? 'min-width: 12rem' : ''">
                  <template #header>
                    <div class="w-full"
                      :style="{ 'text-align': (typeof Data[0][column] === 'number') ? 'center' : 'left' }">
                      {{ $filters.cflt(column) }}
                    </div>
                  </template>
                  <template #body="slotProps">
                    <div :style="{ 'text-align': (typeof slotProps.data[column] === 'number') ? 'center' : 'left' }"
                         translate="no"
                         v-html="(typeof slotProps.data[column] === 'number') && (!['quantity', 'nb_discount'].includes(column)) ?
                                    $filters.formatDecimal(slotProps.data[column], '€') :
                                    slotProps.data[column]" />
                  </template>
                  <template v-if="Object.keys(Filters).includes(column)" #filter="{ filterCallback }">
                    <InputText autocomplete="off" spellcheck="false"
                               type="text" v-model="Filters[column].value" @keydown.enter="filterCallback()"
                               class="p-column-filter" />
                  </template>
                </Column>
                <ColumnGroup type="footer" v-if="(Data?.length ?? 0) > 0">
                  <Row>
                    <Column>
                      <template #footer>
                        <div class="my-3 mr-4 flex align-items-center justify-content-end">
                          {{ $filters.capitalizeFirstLetter(t('totals')) }}:
                        </div>
                      </template>
                    </Column>
                    <Column v-for="column in ((Data?.length ?? 0) > 0 ? Object.keys(Data[0]) : []).slice(1)"
                      :key="column">
                      <template #footer>
                        <div class="w-full" translate="no"
                          :style="{ 'text-align': (typeof Data[0][column] === 'number') ? 'center' : 'left' }"
                          v-html="total(column)">
                        </div>
                      </template>
                    </Column>
                  </Row>
                </ColumnGroup>
              </DataTable>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<style lang="scss">
@import "@/scss/_variables.scss";

.caglobalusers-datatable td:not(:nth-child(2), :nth-child(1)) {
  @extend %cell-format;
}
</style>
<script lang="ts">
import { computed, defineComponent, inject, reactive, ref } from 'vue'
 import { globalState } from '@/states/global';
import { useI18n } from "vue-i18n";
import moment from "moment";
import ObjectsHelper from "@/helpers/objectshelper";
import DashProvider from "@/providers/dashprovider";
import ExcelExport from "@/components/ExcelExport.vue";
import ReportHelper from "@/helpers/reporthelper";
import { FilterMatchMode } from "primevue/api";
import { deepCopy } from "@/helpers/deepCopy";
import { CASummaryModel } from "@/models/dashboard/casummarymodel";
import SkeletonGrid from "@/components/SkeletonGrid.vue";
import Progress from "@/components/Progress.vue";
import ExportExportWithColumnSelection from "@/components/ExportExportWithColumnSelection.vue";
import useProvider, { UseProvider } from '@/hooks/provider'
import ApplicationSymbols from '@/keys'

export default defineComponent({
  components: {
    Progress,
    ExcelExport
  },
  setup(props) {
    const { t } = useI18n();
    const errorMessage = ref();
    const Data = ref();
    const Report = ref();
    const Filters = ref({
      item_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      store: { value: null, matchMode: FilterMatchMode.CONTAINS },
      item_level_name: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const provider = inject<UseProvider>(ApplicationSymbols.useProvider)!;

    const loading = ref(true);

    const columnNames = ref([
      'item_name',
      'quantity',
      'price',
      'nb_discount',
      'total_discount',
      'total',
      'item_level_name',
      'store_name',
    ]);

    // js => ts quick fix
    const fetchData = async (dates: any) => {
      try {
        loading.value = true;
        const tmp = (globalState.state.selected.storeIds?.length ?? 0) == 0 ? [] :
          await provider.dash.getSalesDetails(globalState.state.selected.storeIds, dates.from, dates.to);

        // tmp = ObjectsHelper.removeZeroSumColumns(tmp);

        errorMessage.value = null;

        Data.value = tmp;
        loading.value = false;
      } catch (r) {
        errorMessage.value = r;
        Data.value = null;
        loading.value = false;
      }
    };

    // js => ts quick fiw
    const refresh = async (dates: any) => {
      await fetchData(dates);
    };

    // js => ts quick fix
    const total = (field: any) => ReportHelper.total(Report, field, 'currency', ['quantity', 'nb_discount']);

    const syncing24h = ref(false);
    const sync24h = async () => {
      syncing24h.value = true;
      await provider.materialize.sync24h('salesDetails');
      syncing24h.value = false;
      await refresh({ from: globalState.state.period!.start, to: globalState.state.period!.end });
    }

    return {
      t, loading,
      errorMessage,
      Data,
      Report, Filters, refresh, total,
      columnNames,
      syncing24h, sync24h
    }
  }
})
</script>