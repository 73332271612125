<template>
  <div class="not-found-container">
    <img
      class="not-found-image"
      src="@/assets/404-page-not-found.svg"
    />
    <div class="text-center mt-4">
    <h1 >{{ t('404_title')}}</h1>
    <h2 class="m-3 font-normal">{{ t('404_subtitle') }}</h2>
    <router-link
      class="color-white p-button p-button-rounded background-gradient-sunrise"
      to="/fr"
    >
      {{ t('back_to_home') }}&nbsp;
      <span class="fa fa-arrow-right"></span>
    </router-link>
    </div>
  </div>
</template>
<style lang="scss">
.not-found-container {
  width: 100%;
  padding: 50px;
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 24px;
  }
}
.not-found-image {
  width: 100%;
  max-height: 60vh;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const t = useI18n().t;
    return { t };
  },
});
</script>
