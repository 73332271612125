import filters from '@/helpers/filters';
import {Ref} from 'vue';

export default class ReportHelper {
    public static showFooter = (report: Ref): boolean => (report.value?.processedData?.length ?? 0) > 0;

    public static total = (report: Ref, field: string,
                           def: 'currency' | 'number' = 'currency',
                           exceptions: string[] = []
    ): string => {
        let t: number = 0;

        if ((report.value?.processedData?.length ?? 0) > 0) {
            t = report.value.processedData
                .map((l: any) => typeof (l[field]) === 'number' ? l[field] : NaN)
                .reduce((sum: number, current: number) => sum + current);
        }

        return isNaN(t) ? '' :
               def == 'currency' ? (exceptions.includes(field) ? t.toString() : filters.formatDecimal(t,'€')) :
                                   (exceptions.includes(field) ? filters.formatDecimal(t,'€') : t.toString());
    }
}