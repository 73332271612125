import { SaleHeader, SaleLine, SalePayment } from '@/models/document/saledocument'
import {
  CommercialHeaderRepository,
  CommercialItemPrice,
  CommercialLineRepository,
  CommercialPaymentRepository,
  CommercialRepository,
  ICommercialPaymentRepository,
  ICommercialRepositoryParameters
} from '@/models/repository/commercialrepository'
import { v4 as uuidv4 } from 'uuid'
import Customer from '@/models/customer'
import CustomerProvider from '@/providers/customerprovider'
import useProvider from '@/hooks/provider'
import Item from '../item'
import { IAddLineParams, ItemDocumentLineItem, ItemPrice } from './itemdocumentrepository'
import useLocalization from '@/hooks/localization'
import { RegimeType } from '../enums/regimetype.enum'

export class AddressLine {
    uid?: string;
    name?: string;
    addressLine?: string;
    addressLine2?: string;
    phoneNumber?: string;
    zipCode?: string;
    city?: string;
    countryId?: number;

    constructor(init?: Partial<AddressLine>) {
        Object.assign(this, init);
    }
}

export class SaleHeaderRepository extends CommercialHeaderRepository<SaleHeader> {

    clear() {
        super.clear();

        this._customer = undefined;
    }

    private _customer?: Customer;

    public async fetchCustomer(): Promise<Customer | undefined> {
        if (this.customerUid && (!this._customer || this._customer?.uid != this.customerUid)) {
            this._customer = (await new CustomerProvider().getCustomerFromUid(this.customerUid));
        }

        return this._customer;
    }

    //#region customerUid

    get customerUid(): string | undefined {
        return this.model.customer_uid;
    }

    set customerUid(value: string | undefined) {
        this.model.customer_uid = value;
        this._customer = undefined;

        this.edited = true;
    }

    //#endregion

    constructor(init?: Partial<SaleHeader>) {
        super(SaleHeader, init);
    }

    setDeliveryAddress(address: AddressLine) {
        this.model.customer_address_line = address.addressLine;
        this.model.customer_address_line2 = address.addressLine2;
        this.model.customer_city = address.city;
        this.model.customer_zip_code = address.zipCode;
        this.model.customer_country_id = address.countryId;
        this.model.customer_phone_number = address.phoneNumber;

        this.edited = true;
    }

    setInvoiceAddress(address: AddressLine) {
        this.model.invoice_address_line = address.addressLine;
        this.model.invoice_address_line2 = address.addressLine2;
        this.model.invoice_city = address.city;
        this.model.invoice_zip_code = address.zipCode;
        this.model.invoice_country_id = address.countryId;
        this.model.invoice_phone_number = address.phoneNumber;

        this.edited = true;
    }
}

export class SaleLineRepository extends CommercialLineRepository<SaleLine> {

    //#region discountReasonUid

    get discountReasonUid(): string | undefined {
        return this.model.discount_reason_uid;
    }

    set discountReasonUid(value: string | undefined) {
        this.setDiscountReasonUid(value);
    }

    setDiscountReasonUid(value: string | undefined) {
        this.model.discount_reason_uid = value;

        this.edited = true;
    }

    //#endregion

    constructor(init?: Partial<SaleLine>) {
        super(SaleLine, init);
    }
}

export class SalePaymentRepository extends CommercialPaymentRepository<SalePayment> {

    constructor(init?: Partial<SalePayment>) {
        super(SalePayment, init);
    }
}

interface ISaleRepositoryParameters extends ICommercialRepositoryParameters<SaleHeaderRepository, SaleLineRepository, SalePaymentRepository> {

}

export class SaleRepository extends CommercialRepository<SaleHeaderRepository, SaleLineRepository, ICommercialPaymentRepository> {
    constructor(params?: ISaleRepositoryParameters) {
        super(SaleHeaderRepository, SaleLineRepository, SalePaymentRepository, params?.header, params?.lines, params?.payments);
    }

    //#region customerUid

    get customerUid(): string | undefined {
        return this.header.customerUid;
    }

    set customerUid(value: string | undefined) {
        this.setCustomerUid(value);
    }

    private setCustomerUid(value: string | undefined) {
        this.header.customerUid = value;

        const provider = useProvider();

        this.header.fetchCustomer().then((c: Customer | undefined) => {
            if (c) {
                this.regimeType = Number(c.regime_type);
                this.header.dutyActive = c.duty_active;

                if (c.payment_term_id) {
                    this.setPaymentTermId(c.payment_term_id);
                } else {
                    this.setPaymentTermId(undefined);
                }

                if (c.global_user_id) {
                    provider.globalUser.getGlobalUserFromId(c.global_user_id).then(globalUser => {
                        if (globalUser.uid) {
                            this.globalUserUid = globalUser.uid;
                        }
                    });
                }

                this.header.setDeliveryAddress(new AddressLine());
                this.header.setInvoiceAddress(new AddressLine());

                if ((c.administrative_address_line ?? '').trim() != '') {
                    this.header.setDeliveryAddress(new AddressLine({
                        addressLine: c.administrative_address_line,
                        addressLine2: c.administrative_address_line2,
                        zipCode: c.administrative_zip_code,
                        city: c.administrative_city,
                        countryId: c.administrative_country_id,
                    }));
                }

                if ((c.invoice_address_line ?? '').trim() != '') {
                    this.header.setInvoiceAddress(new AddressLine({
                        addressLine: c.invoice_address_line,
                        addressLine2: c.invoice_address_line2,
                        zipCode: c.invoice_zip_code,
                        city: c.invoice_city,
                        countryId: c.invoice_country_id,
                    }));
                }

                if (c.price_list_id) {
                    // TODO : retrieve CUSTOMER.price_list_uid instead of fetching all price lists
                    this.fetchEntity().then((entity) => {
                        provider.priceList.fetchPriceLists({ entityIds: entity.id ? [entity.id] : undefined }).then(priceLists => {
                            const priceList = priceLists.find(p => Number(p.id) == Number(c.price_list_id));

                            if (priceList) {
                                this.priceListUid = priceList.uid;
                            }
                        });
                    });
                } else {
                    this.priceListUid = undefined;
                }
            }
        });
    }

    //#endregion

    protected setRegimeType(value: RegimeType): void {
        this.header.regimeType = value;

        this.lines.forEach(async l => {
            const item = await l.fetchItem();

            if (item) {
                this.setLineVATRate(l, this.getVatRateForItem(item));
            }
        });
    }

    private getVatRateForItem(item: ItemDocumentLineItem): number | undefined {
        switch (this.regimeType) {
            case RegimeType.EEC:
            case RegimeType.Exempted:
            case RegimeType.Cocontractant:
            case RegimeType.ImportExport:
                return 0;

            case RegimeType.Normal:
            case RegimeType.VATIncluded:
                return item.vat_rate;
        }
    }

    getItemPriceFromItem(item: Item): ItemPrice {
        return new CommercialItemPrice({
            price: this.getPriceFromPrices(item.price_vat_included, item.price_vat_excluded),
            discountRate: 0,
        });
    }

    getParentLine(childLine: SaleLineRepository): SaleLineRepository | undefined {
        return this.lines.find(l => l.model.uid == childLine.model.parent_sale_line_uid);
    }

    setParentLine(childLine: SaleLineRepository, parentLine: SaleLineRepository): void {
        childLine.model.parent_sale_line_uid = parentLine.model.uid;
    }

    getChildLines(line: SaleLineRepository): SaleLineRepository[] {
        return this.lines.filter(l => l.model.parent_sale_line_uid == line.model.uid);
    }

    isChildLine(line: SaleLineRepository): boolean {
        return line.model.parent_sale_line_uid != undefined;
    }

    async newLineFromItem(item: ItemDocumentLineItem, params: IAddLineParams): Promise<SaleLineRepository> {
        const localization = useLocalization();

        const repositoryLine = await super.newLineFromItem(item, params);

        const customer = await this.header.fetchCustomer();

        repositoryLine.description = localization.localize(item, 'name', customer?.language) ?? item.name_fr;
        repositoryLine.vatRate = this.getVatRateForItem(item);

        return repositoryLine;
    }

    addPaymentLine(
        paymentTypeId: number,
        date: Date,
        amount: number,
        description?: string
    ): SalePaymentRepository {

        const payment = new SalePaymentRepository({
            uid: uuidv4(),
            payment_type_id: paymentTypeId,
            date: date,
            amount: amount,
            description: description,
        })

        super.addPayment(payment);

        return payment;
    }
}