<template>
    <div class="w-full flex justify-content-center">
      <ProgressSpinner :class="{ 'progress-spinner-circle-white': color == Color.White }"
                       :style="{ width: `${width}px`, height: `${height}px`, }"
                       :strokeWidth="strokeWidth" animationDuration=".8s"
                       aria-label="Custom ProgressSpinner" />
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

export enum Color {
    White,
    Colored,
}

export default defineComponent({
    props: {
        height: {
            type: Number,
            default: 50
        },
        width: {
            type: Number,
            default: 50
        },
        strokeWidth: {
            type: String,
            default: '6'
        },
        color: {
            type: Number as PropType<Color>,
            default: Color.Colored,
        }
    },
    setup() {
        return {
            Color,
        }
    },
});
</script>

<style>
@keyframes p-progress-spinner-color-white {

    100%,
    0% {
        stroke: rgba(255, 255, 255, 1);
    }

    50% {
        stroke: rgba(255, 255, 255, .5);
    }
}

.progress-spinner-circle-white .p-progress-spinner-circle {
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
        p-progress-spinner-color-white 2s ease-in-out infinite;
}
</style>