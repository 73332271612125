import BaseProvider from "./baseprovider"
import VAT from '@/models/vat';

export default class VATProvider extends BaseProvider {
    public async fetchVAT(
        entityIds?: number[],
        storeIds?: number[],
        countryIds?: number[]): Promise<VAT[]> {
        let p: string = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("storeIds", storeIds, true);
        p = p.addParams("countryIds", countryIds, true);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/VAT${p}`);

        return response.data.map((d: any) => this.getVAT(d));
    }

    private getVAT(data: any): VAT {
        return new VAT(data);
    }
}