<template>
    <DataTable class="commercial-lines-datatable" :value="products">
        <Column v-for="column in columns" :key="column" :header="column">
            <template #body>
                <div class="p-2">
                    <Skeleton></Skeleton>
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        columns: Array<string>,
        length: {
            type: Number,
            default: 8,
        }
    },
    setup(props,) {
        const products = ref(new Array(props.length));

        return {
            products,
        }
    },
});
</script>