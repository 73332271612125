import { createRouter, createWebHistory } from 'vue-router'
import { loadLocaleMessages, setI18nLanguage, SUPPORT_LOCALES } from './i18n'
import { authState } from '@/states/auth'
import GlobalUser from './models/globaluser'
import Organization from './models/organization'
import Login from '@/views/Login.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import Dashboard from '@/views/Dashboard.vue'

enum PermissionModule {
  Suppliers = 'module_suppliers',
  Purchases = 'module_purchases',
  Transfers = 'module_transfers',
  Inventories = 'module_inventories',
}

export function setupRouter(i18n: any) {
  const locale =
    i18n.mode === 'legacy' ? i18n.global.locale : i18n.global.locale.value

  // setup routes
  const routes = [
    {
      path: '',
      name: 'root',
      component: Login,
      meta: {
        isPublic: true,
      }
    },
    {
      path: '/:locale/',
      name: 'home',
      component: Login,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/:locale/login',
      name: 'login',
      component: Login,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/:locale/reset-password',
      name: 'resetpassword',
      component: () => import('./views/ResetPassword.vue'),
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/:locale/change-password',
      name: 'changepassword',
      component: () => import('./views/ChangePasswordWithToken.vue'),
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/:locale/dashboard',
      name: 'dashboard',
      component: Dashboard,
      children: [
        {
          path: 'ca',
          name: 'dashboardca',
          component: Dashboard,
        },
        {
          path: 'reportz',
          name: 'dashboardreportz',
          component: Dashboard,
        },
        {
          path: 'itemlevels',
          name: 'dashboarditemlevels',
          component: Dashboard,
        },
        {
          path: 'caglobalusers',
          name: 'dashboardcaglobalusers',
          component: Dashboard,
        },
        {
          path: 'pointglobalusers',
          name: 'dashboardpointglobalusers',
          component: Dashboard,
        },
        {
          path: 'salesdetails',
          name: 'dashboardsalesdetails',
          component: Dashboard,
        },
        {
          path: 'listing/:listingId',
          name: 'dashboardlisting',
          component: Dashboard,
        },
        {
          path: 'datawarehouse/:datawarehouseId',
          name: 'dashboarddatawarehouse',
          component: Dashboard,
        },
      ]
    },
    {
      path: '/:locale/sale-quotes',
      name: 'sale-quotes',
      component: () => import('./views/Sale/Quotes.vue'),
      children: [
        {
          path: 'view/:uid',
          name: 'sale-quotes-view',
          component: () => import('./views/Sale/Quotes.vue')
        }
      ]
    },
    {
      path: '/:locale/sale-orders',
      name: 'sale-orders',
      component: () => import('./views/Sale/Orders.vue'),
      children: [
        {
          path: 'view/:uid',
          name: 'sale-orders-view',
          component: () => import('./views/Sale/Orders.vue')
        }
      ]
    },
    {
      path: '/:locale/sale-invoices',
      name: 'sale-invoices',
      component: () => import('./views/Sale/Invoices.vue'),
      children: [
        {
          path: 'view/:uid',
          name: 'sale-invoices-view',
          component: () => import('./views/Sale/Invoices.vue')
        }
      ]
    },
    {
      path: '/:locale/sale-deliveries',
      name: 'sale-deliveries',
      component: () => import('./views/Sale/Deliveries.vue'),
      children: [
        {
          path: 'view/:uid',
          name: 'sale-deliveries-view',
          component: () => import('./views/Sale/Deliveries.vue')
        }
      ]
    },
    {
      path: '/:locale/purchase-quotes',
      name: 'purchase-quotes',
      component: () => import('./views/Purchase/Quotes.vue'),
      meta: {
        permission: PermissionModule.Purchases,
      },
      children: [
        {
          path: 'view/:uid',
          name: 'purchase-quotes-view',
          component: () => import('./views/Purchase/Quotes.vue'),
          meta: {
            permission: PermissionModule.Purchases,
          },
        }
      ]
    },
    {
      path: '/:locale/purchase-orders',
      name: 'purchase-orders',
      component: () => import('./views/Purchase/Orders.vue'),
      meta: {
        permission: PermissionModule.Purchases,
      },
      children: [
        {
          path: 'view/:uid',
          name: 'purchase-orders-view',
          component: () => import('./views/Purchase/Orders.vue'),
          meta: {
            permission: PermissionModule.Purchases,
          },
        }
      ]
    },
    {
      path: '/:locale/purchase-deliveries',
      name: 'purchase-deliveries',
      component: () => import('./views/Purchase/Deliveries.vue'),
      meta: {
        permission: PermissionModule.Purchases,
      },
      children: [
        {
          path: 'view/:uid',
          name: 'purchase-deliveries-view',
          component: () => import('./views/Purchase/Deliveries.vue'),
          meta: {
            permission: PermissionModule.Purchases,
          },
        }
      ]
    },
    {
      path: '/:locale/purchase-invoices',
      name: 'purchase-invoices',
      component: () => import('./views/Purchase/Invoices.vue'),
      meta: {
        permission: PermissionModule.Purchases,
      },
      children: [
        {
          path: 'view/:uid',
          name: 'purchase-invoices-view',
          component: () => import('./views/Purchase/Invoices.vue'),
          meta: {
            permission: PermissionModule.Purchases,
          },
        }
      ]
    },
    {
      path: '/:locale/sales/:uid/:view',
      name: 'sale-edition',
      component: () => import('./views/SaleEdition.vue'),
    },
    {
      path: '/:locale/sales/:journalUid/new',
      name: 'sale-creation',
      component: () => import('./views/SaleEdition.vue'),
    },
    {
      path: '/:locale/sales/:journalUid/newfrom',
      name: 'sale-creation-from',
      component: () => import('./views/SaleEdition.vue'),
    },
    {
      path: '/:locale/purchases/:uid/:view',
      name: 'purchase-edition',
      component: () => import('./views/PurchaseEdition.vue'),
      meta: {
        permission: PermissionModule.Purchases,
      },
    },
    {
      path: '/:locale/purchases/:journalUid/new',
      name: 'purchase-creation',
      component: () => import('./views/PurchaseEdition.vue'),
      meta: {
        permission: PermissionModule.Purchases,
      },
    },
    {
      path: '/:locale/storetransfersflow/:uid/:view',
      name: 'storetransfer-flow-edition',
      component: () => import('./views/StoreTransferEdition.vue'),
      meta: {
        permission: PermissionModule.Transfers,
      },
    },
    {
      path: '/:locale/storetransfersflow/:journalUid/new',
      name: 'storetransfer-flow-creation',
      component: () => import('./views/StoreTransferEdition.vue'),
      meta: {
        permission: PermissionModule.Transfers,
      },
    },
    {
      path: '/:locale/storetransfersdirect/:uid/:view',
      name: 'storetransfer-direct-edition',
      component: () => import('./views/StoreTransferEdition.vue'),
      meta: {
        permission: PermissionModule.Transfers,
      },
    },
    {
      path: '/:locale/storetransfersdirect/:journalUid/new',
      name: 'storetransfer-direct-creation',
      component: () => import('./views/StoreTransferEdition.vue'),
      meta: {
        permission: PermissionModule.Transfers,
      },
    },
    {
      path: '/:locale/storetransfer-direct',
      name: 'storetransfer-direct',
      component: () => import('./views/StoreTransfer/Direct.vue'),
      meta: {
        permission: PermissionModule.Transfers,
      },
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-direct-view',
          component: () => import('./views/StoreTransfer/Direct.vue'),
          meta: {
            permission: PermissionModule.Transfers,
          },
        }
      ]
    },
    {
      path: '/:locale/storetransfer-flow-validating',
      name: 'storetransfer-flow-validating',
      component: () => import('./views/StoreTransfer/FlowValidating.vue'),
      meta: {
        permission: PermissionModule.Transfers,
      },
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-flow-validating-view',
          component: () => import('./views/StoreTransfer/FlowValidating.vue'),
          meta: {
            permission: PermissionModule.Transfers,
          },
        }
      ]
    },
    {
      path: '/:locale/storetransfer-flow-preparing',
      name: 'storetransfer-flow-preparing',
      component: () => import('./views/StoreTransfer/FlowPreparing.vue'),
      meta: {
        permission: PermissionModule.Transfers,
      },
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-flow-preparing-view',
          component: () => import('./views/StoreTransfer/FlowPreparing.vue'),
          meta: {
            permission: PermissionModule.Transfers,
          },
        }
      ]
    },
    {
      path: '/:locale/storetransfer-flow-refused',
      name: 'storetransfer-flow-refused',
      component: () => import('./views/StoreTransfer/FlowRefused.vue'),
      meta: {
        permission: PermissionModule.Transfers,
      },
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-flow-refused-view',
          component: () => import('./views/StoreTransfer/FlowRefused.vue'),
          meta: {
            permission: PermissionModule.Transfers,
          },
        }
      ]
    },
    {
      path: '/:locale/storetransfer-flow-expedited',
      name: 'storetransfer-flow-expedited',
      component: () => import('./views/StoreTransfer/FlowExpedited.vue'),
      meta: {
        permission: PermissionModule.Transfers,
      },
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-flow-expedited-view',
          component: () => import('./views/StoreTransfer/FlowExpedited.vue'),
          meta: {
            permission: PermissionModule.Transfers,
          },
        }
      ]
    },
    {
      path: '/:locale/storetransfer-flow-delivered',
      name: 'storetransfer-flow-delivered',
      component: () => import('./views/StoreTransfer/FlowDelivered.vue'),
      meta: {
        permission: PermissionModule.Transfers,
      },
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-flow-delivered-view',
          component: () => import('./views/StoreTransfer/FlowDelivered.vue'),
          meta: {
            permission: PermissionModule.Transfers,
          },
        }
      ]
    },
    {
      path: '/:locale/storetransfer-stock-correction',
      name: 'storetransfer-stock-correction',
      component: () => import('./views/StoreTransfer/StockCorrection.vue'),
      meta: {
        permission: PermissionModule.Inventories,
      },
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-stock-correction-view',
          component: () => import('./views/StoreTransfer/StockCorrection.vue'),
          meta: {
            permission: PermissionModule.Inventories,
          },
        }
      ]
    },
    {
      path: '/:locale/storetransfer-stock-global-reset',
      name: 'storetransfer-stock-global-reset',
      component: () => import('./views/StoreTransfer/StockGlobalReset.vue'),
      meta: {
        permission: PermissionModule.Inventories,
      },
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-stock-global-reset-view',
          component: () => import('./views/StoreTransfer/StockGlobalReset.vue'),
          meta: {
            permission: PermissionModule.Inventories,
          },
        }
      ]
    },
    {
      path: '/:locale/storetransfer-stock-global-transcription',
      name: 'storetransfer-stock-global-transcription',
      component: () => import('./views/StoreTransfer/StockGlobalTranscription.vue'),
      meta: {
        permission: PermissionModule.Inventories,
      },
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-stock-global-transcription-view',
          component: () => import('./views/StoreTransfer/StockGlobalTranscription.vue'),
          meta: {
            permission: PermissionModule.Inventories,
          },
        }
      ]
    },
    {
      path: '/:locale/items',
      name: 'items',
      component: () => import('./views/Item/Items.vue'),
      children: [
        {
          path: ':uid',
          name: 'item-edition',
          component: () => import('./views/Item/Items.vue')
        },
        {
          path: ':uid/:doc',
          name: 'item-edition-view',
          component: () => import('./views/Item/Items.vue')
        }
      ],
    },
    {
      path: '/:locale/stocks',
      name: 'stocks',
      component: () => import('./views/Item/Stocks.vue'),
      children: [
        {
          path: ':uid',
          name: 'stock-item-edition',
          component: () => import('./views/Item/Items.vue')
        },
      ],
    },
    {
      path: '/:locale/item-levels',
      name: 'item-levels',
      component: () => import('./views/Item/ItemLevels.vue'),
      children: [
        {
          path: ':uid',
          name: 'item-level-edition',
          component: () => import('./views/Item/ItemLevels.vue')
        },
      ],
    },
    {
      path: '/:locale/item-categories',
      name: 'item-categories',
      component: () => import('./views/Item/ItemCategories.vue'),
      children: [
        {
          path: ':uid',
          name: 'item-category-edition',
          component: () => import('./views/Item/ItemCategories.vue')
        },
      ],
    },
    {
      path: '/:locale/propositions',
      name: 'propositions',
      component: () => import('./views/Item/Propositions.vue'),
      children: [
        {
          path: ':uid',
          name: 'proposition-edition',
          component: () => import('./views/Item/Propositions.vue')
        },
      ],
    },
    {
      path: '/:locale/item-history',
      name: 'item-history',
      component: () => import('./views/Item/History.vue'),
      children: [
        {
          path: ':uid',
          name: 'item-history-list',
          component: () => import('./views/Item/History.vue')
        },
        {
          path: ':doc/:uid',
          name: 'item-history-view',
          component: () => import('./views/Item/History.vue')
        }
      ]
    },
    {
      path: '/:locale/item-ranks',
      name: 'item-ranks',
      component: () => import('./views/Item/Ranks.vue'),
      children: [
        {
          path: ':uid',
          name: 'item-rank-edition',
          component: () => import('./views/Item/Ranks.vue')
        }
      ]
    },
    {
      path: '/:locale/accounting/sales',
      name: 'accounting-sales',
      component: () => import('./views/Accounting/Sales.vue'),
      props: (r: any) => ({ entityId: +r.query.entityId })
    },
    {
      path: '/:locale/accounting/purchases',
      name: 'accounting-purchases',
      component: () => import('./views/Accounting/Purchases.vue'),
      props: (r: any) => ({ entityId: +r.query.entityId })
    },
    {
      path: '/:locale/pos',
      name: 'pos',
      component: () => import('./views/Setting/POS.vue'),
      children: [
        {
          path: ':uid',
          name: 'pos-edition',
          component: () => import('./views/Setting/POS.vue')
        },
        {
          path: ':uid/matrices',
          name: 'pos-matrices-edition',
          component: () => import('./views/Setting/POS.vue')
        }
      ],
    },
    {
      path: '/:locale/matrix-templates',
      name: 'matrix-templates',
      component: () => import('./views/Matrix/MatrixTemplates.vue'),
      children: [
        {
          path: ':uid',
          name: 'matrix-template-edition',
          component: () => import('./views/Matrix/MatrixTemplates.vue')
        },
        {
          path: ':uid/matrices',
          name: 'matrix-template-matrices-edition',
          component: () => import('./views/Matrix/MatrixTemplates.vue')
        },
      ]
    },
    {
      path: '/:locale/printers',
      name: 'printers',
      component: () => import('./views/Setting/Printers.vue'),
      children: [
        {
          path: ':uid',
          name: 'printer-edition',
          component: () => import('./views/Setting/Printers.vue')
        }
      ],
    },
    {
      path: '/:locale/customers',
      name: 'customers',
      component: () => import('./views/Customer/Customers.vue'),
      children: [
        {
          path: ':uid',
          name: 'customer-edition',
          component: () => import('./views/Customer/Customers.vue')
        },
        {
          path: ':uid/history',
          name: 'customer-history',
          component: () => import('./views/Customer/Customers.vue')
        },
        {
          path: ':uid/account',
          name: 'customer-account',
          component: () => import('./views/Customer/Customers.vue')
        },
      ],
    },
    {
      path: '/:locale/customer-categories',
      name: 'customer-categories',
      component: () => import('./views/Customer/CustomerCategories.vue'),
      children: [
        {
          path: ':uid',
          name: 'customer-category-edition',
          component: () => import('./views/Customer/CustomerCategories.vue')
        },
      ],
    },
    {
      path: '/:locale/customer-addresses',
      name: 'customer-addresses',
      component: () => import('./views/Customer/CustomerAddresses.vue'),
      children: [
        {
          path: ':uid',
          name: 'customer-address-edition',
          component: () => import('./views/Customer/CustomerAddresses.vue')
        },
      ],
    },
    {
      path: '/:locale/suppliers',
      name: 'suppliers',
      component: () => import('./views/Supplier/Suppliers.vue'),
      meta: {
        permission: PermissionModule.Suppliers,
      },
      children: [
        {
          path: ':uid',
          name: 'supplier-edition',
          component: () => import('./views/Supplier/Suppliers.vue'),
          meta: {
            permission: PermissionModule.Suppliers,
          },
        },
        {
          path: ':uid/history',
          name: 'supplier-history',
          component: () => import('./views/Supplier/Suppliers.vue'),
          meta: {
            permission: PermissionModule.Suppliers,
          },
        },
        {
          path: ':uid/account',
          name: 'supplier-account',
          component: () => import('./views/Supplier/Suppliers.vue'),
          meta: {
            permission: PermissionModule.Suppliers,
          },
        },
      ],
    },
    {
      path: '/:locale/global-users',
      name: 'global-users',
      component: () => import('./views/Setting/GlobalUsers.vue'),
      children: [
        {
          path: ':uid',
          name: 'global-user-edition',
          component: () => import('./views/Setting/GlobalUsers.vue')
        },
      ],
    },
    {
      path: '/:locale/live-update',
      name: 'global:live-update',
      component: () => import('./ClickAndCollect/views/LiveUpdate/List.vue')
    },
    {
      path: '/:locale/my-organization',
      name: 'my-organization',
      component: () => import('./views/Setting/MyOrganization.vue')
    },
    {
      path: '/:locale/glory-user-types',
      name: 'glory-user-types',
      component: () => import('./views/Setting/GloryUserTypes.vue'),
      children: [
        {
          path: ':uid',
          name: 'glory-user-type-edition',
          component: () => import('./views/Setting/GloryUserTypes.vue')
        },
      ],
    },
    {
      path: '/:locale/entities',
      name: 'entities',
      component: () => import('./views/Setting/Entities.vue'),
      children: [
        {
          path: ':uid',
          name: 'entity-edition',
          component: () => import('./views/Setting/Entities.vue')
        },
      ],
    },
    {
      path: '/:locale/stores',
      name: 'stores',
      component: () => import('./views/Setting/Stores.vue'),
      children: [
        {
          path: ':uid',
          name: 'store-edition',
          component: () => import('./views/Setting/Stores.vue')
        },
        {
          path: ':uid/matrices',
          name: 'store-matrices-edition',
          component: () => import('./views/Setting/Stores.vue')
        },
        {
          path: ':uid/printers',
          name: 'store-printers-edition',
          component: () => import('./views/Setting/Stores.vue')
        }
      ],
    },
    {
      path: '/:locale/settings',
      name: 'settings',
      component: () => import('./views/Settings.vue')
    },
    {
      path: '/:locale/price-lists',
      name: 'price-lists',
      component: () => import('./views/Setting/PriceLists.vue'),
      children: [
        {
          path: ':uid',
          name: 'price-list-edition',
          component: () => import('./views/Setting/PriceLists.vue')
        },
      ],
    },
    {
      path: '/:locale/mail-templates',
      name: 'mail-templates',
      component: () => import('./views/Setting/MailTemplates.vue'),
      children: [
        {
          path: ':uid',
          name: 'mail-template-edition',
          component: () => import('./views/Setting/MailTemplates.vue')
        },
      ],
    },
    {
      path: '/:locale/webhooks',
      name: 'webhooks',
      component: () => import('./views/Setting/Webhooks.vue'),
      children: [
        {
          path: ':uid',
          name: 'webhook-edition',
          component: () => import('./views/Setting/Webhooks.vue')
        }
      ],
    },
    {
      path: '/:locale/account/dashboardlicenses',
      name: 'dashboardlicenses',
      component: () => import('./views/Account/Licenses/Dashboard.vue')
    },
    {
      path: '/:locale/account/activelicenses',
      name: 'activelicenses',
      component: () => import('./views/Account/Licenses/ActiveLicenses.vue')
    },
    {
      path: '/:locale/account/activecontracts',
      name: 'activecontracts',
      component: () => import('./views/Account/Licenses/ActiveContracts.vue')
    },
    {
      path: '/:locale/account/demolicenses',
      name: 'demolicenses',
      component: () => import('./views/Account/Licenses/DemoLicenses.vue'),
      children: [
        {
          path: 'pos/:uid',
          name: 'demolicenses-pos-edition',
          component: () => import('./views/Account/Licenses/DemoLicenses.vue')
        },
        {
          path: 'entity/:uid',
          name: 'demolicenses-entity-edition',
          component: () => import('./views/Account/Licenses/DemoLicenses.vue')
        }
      ],
    },
    {
      path: '/:locale/account/blockedlicenses',
      name: 'blockedlicenses',
      component: () => import('./views/Account/Licenses/BlockedLicenses.vue')
    },
    {
      path: '/:locale/account/inactiveslicenses',
      name: 'inactiveslicenses',
      component: () => import('./views/Account/Licenses/InactivesLicenses.vue')
    },
    {
      path: '/:locale/account/unbindlicenses',
      name: 'unbindlicenses',
      component: () => import('./views/Account/Licenses/UnbindLicenses.vue')
    },
    {
      path: '/:locale/account/emailhistory',
      name: 'emailhistory',
      component: () => import('./views/Account/History/EmailHistory.vue')
    },
    {
      path: '/:locale/account/smshistory',
      name: 'smshistory',
      component: () => import('./views/Account/History/SMSHistory.vue')
    },
    {
      path: '/:locale/account/organisationlogs',
      name: 'organisationlogs',
      component: () => import('./views/Account/Other/OrganisationLogs.vue')
    },
    {
      path: '/:locale/account/entitiesprodunready',
      name: 'entitiesprodunready',
      component: () => import('./views/Account/Other/EntitiesProdUnready.vue'),
      children: [
        {
          path: ':uid',
          name: 'entitiesprodunready-entity-edition',
          component: () => import('./views/Account/Other/EntitiesProdUnready.vue')
        }
      ],
    },
    {
      path: '/:locale/account/entitiesprodready',
      name: 'entitiesprodready',
      component: () => import('./views/Account/Other/EntitiesProdReady.vue'),
      children: [
        {
          path: ':uid',
          name: 'entitiesprodready-entity-edition',
          component: () => import('./views/Account/Other/EntitiesProdReady.vue')
        }
      ],
    },
    {
      path: '/:locale/signalr',
      name: 'signalr',
      component: () => import('./views/Setting/SignalR.vue')
    },
    {
      path: '/:locale/api',
      name: 'api',
      component: () => import('./views/Setting/API.vue')
    },
    {
      path: '/:locale/tools',
      name: 'tools',
      component: () => import('./views/Setting/Tools.vue')
    },
    {
      path: '/:locale/tools/item-printer-links',
      name: 'item-printer-links',
      component: () => import('./views/Setting/Tools/ItemPrinterLinks.vue')
    },
    {
      path: '/:locale/tools/item-proposition-links',
      name: 'item-proposition-links',
      component: () => import('./views/Setting/Tools/ItemPropositionLinks.vue')
    },
    {
      path: '/:locale/tools/item-rank-links',
      name: 'item-rank-links',
      component: () => import('./views/Setting/Tools/ItemRankLinks.vue')
    },
    {
      path: '/:locale/tools/batch-assign/:name?',
      name: 'batch-assign',
      component: () => import('./views/Setting/Tools/BatchAssign.vue')
    },
    {
      path: '/:locale/tools/pos-matrix-template-link',
      name: 'pos-matrix-template-link',
      component: () => import('./views/Setting/Tools/POSMatrixTemplateLink.vue')
    },
    {
      path: '/:locale/tools/lightspeed',
      name: 'lightspeed',
      component: () => import('./views/Setting/Tools/LightSpeed.vue')
    },
    {
      path: '/:locale/account/credentials',
      name: 'credentials',
      component: () => import('./views/Account/Credentials.vue')

    },
    {
      path: '/:locale/changehistory',
      name: 'changehistory',
      component: () => import('./views/Setting/ChangeHistory.vue')
    },
    {
      path: '/:locale/timeslot',
      name: 'timeslot',
      component: () => import('./views/Setting/Timeslot.vue')
    },
    {
      path: '/:locale/booking',
      name: 'booking',
      component: () => import('./Booking/views/Layout.vue'),
      redirect: {
        name: 'booking-settings',
      },
      children: [
        {
          path: 'reservations',
          name: 'booking:reservations',
          component: () => import('./Booking/views/Reservations.vue')
        },
        {
          path: 'timeline',
          name: 'booking:timeline',
          component: () => import('./Booking/views/Timeline.vue')
        },
        {
          path: 'calendar',
          name: 'booking:calendar',
          component: () => import('./Booking/views/Calendar.vue')
        },
        {
          path: ':uid?',
          name: 'booking:settings:form',
          component: () => import('./Booking/views/Settings/Form.vue')
        },
      ]
    },
    {
      path: '/:locale/click-and-collect',
      name: 'click-and-collect',
      redirect: {
        name: 'click-and-collect:storefronts'
      },
      children: [
        {
          path: 'storefronts',
          name: 'click-and-collect:storefronts',
          component: () => import('./ClickAndCollect/views/Storefronts/Layout.vue'),
          redirect: {
            name: 'click-and-collect:storefronts:list',
          },
          children: [
            {
              path: '',
              name: 'click-and-collect:storefronts:list',
              component: () => import('./ClickAndCollect/views/Storefronts/List.vue')
            },
            {
              path: ':uid',
              name: 'click-and-collect:storefronts:show',
              component: () => import('./ClickAndCollect/views/Storefronts/Show.vue')
            }
          ]
        },
        {
          path: 'branding',
          name: 'click-and-collect:branding',
          component: () => import('./ClickAndCollect/views/Branding/Layout.vue'),
          redirect: {
            name: 'click-and-collect:branding:list'
          },
          children: [
            {
              path: '',
              name: 'click-and-collect:branding:list',
              component: () => import('./ClickAndCollect/views/Branding/List.vue')
            },
            {
              path: ':uid',
              name: 'click-and-collect:branding:update',
              component: () => import('./ClickAndCollect/views/Branding/Update.vue')
            },
            {
              path: 'create',
              name: 'click-and-collect:branding:create',
              component: () => import('./ClickAndCollect/views/Branding/Create.vue')
            }
          ]
        }
      ]
    },
    {
      path: '/:locale/page-not-found',
      name: 'pagenotfound',
      component: PageNotFound,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: () => `/${(locale || 'fr')}/page-not-found`
    }
  ]

  // create router instance
  const router = createRouter({
    history: createWebHistory(),
    routes
  })

  // navigation guards
  router.beforeEach(async (to, from, next) => {
    const paramsLocale = to.params.locale as string;

    // use locale if paramsLocale is not in SUPPORT_LOCALES
    if (!SUPPORT_LOCALES.includes(paramsLocale)) {
      return next(`/${locale}`)
    }

    // load locale messages
    if (!i18n.global.availableLocales.includes(paramsLocale)) {
      await loadLocaleMessages(i18n, paramsLocale)
    }

    // set i18n language
    setI18nLanguage(i18n, paramsLocale)

    if (to.meta.isPublic) {
      return next();
    } else {
      await authState.dispatch('getGlobalUserAsync')
        .then((user: GlobalUser | null) => {
          if (!user)
            return next({ name: 'home', params: { locale: paramsLocale } });

          if (to.meta.permission) {
            // validate organization
            const organization: Organization | null = authState.getters.getOrganization;

            if (!organization) return next({ name: 'dashboardca', params: { locale: paramsLocale } });

            const permission: string = to.meta.permission as string;

            // validate organization permission property key
            if (!(permission as keyof Organization)) return next({ name: 'dashboardca', params: { locale: paramsLocale } });

            // validate permission of organization
            const validOrganizationPermission = organization[permission as keyof Organization];

            if (!validOrganizationPermission) return next({ name: 'dashboardca', params: { locale: paramsLocale } });

            // validate permission of user
            const userPermission = `plugandpos_${permission}`;

            // validate user permission
            const validUserPermission = user.permissions[userPermission];

            if (!validUserPermission) return next({ name: 'dashboardca', params: { locale: paramsLocale } });
          }

          return next();
        })
        .catch(() => { return next({ name: 'login', params: { locale: paramsLocale } }); });
    }
  })

  return router
}
