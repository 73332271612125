<template>
  <div :class="type">
    <!-- ATTENTION: DO NOT REMOVE THIS DIV. VUE FAILS TO COMPILE PROPERLY THE v-show ATTRIBUTE OTHERWISE -->
    <div class="flex flex-column justify-content-center">
      <!-------------------------------------------- START DASHBOARD CARDS -------------------------------------------->
      <div class="flex flex-wrap justify-content-center mb-4">
        <div :style="itemStyle" class="align-items-center">
          <Card class="background-gradient-sunrise h-full w-full"></Card>
          <div class="bo-dashboard-item-overlay bo-dashboard-item-overlay-title">
            <i class="fas fa-chart-line" />
            <h6 class="ml-2 p-responsive" v-html="filters.cflt('sales_count')" />
          </div>
          <div class="bo-dashboard-item-overlay bo-dashboard-sales-count align-items-center pt-4">
            <Progress :color="Color.White" v-if="!CASummarySales" />
            <div v-else>{{ salesCount }}</div>
          </div>
        </div>
        <div :style="itemStyle" class="align-items-center">
          <Card class="background-gradient-gold h-full w-full"></Card>
          <div class="bo-dashboard-item-overlay bo-dashboard-item-overlay-title">
            <i class="fas fa-shopping-bag" />
            <h6 class="ml-2 p-responsive" v-html="filters.cflt('items_count')"></h6>
          </div>
          <div class="bo-dashboard-item-overlay bo-dashboard-items-count align-items-center pt-4">
            <Progress :color="Color.White" v-if="!CASummarySales" />
            <div v-else>{{ itemsCount }}</div>
          </div>
        </div>
        <div :style="itemStyle" class="align-items-center">
          <Card class="background-gradient-green h-full w-full"></Card>
          <div class="bo-dashboard-item-overlay bo-dashboard-item-overlay-title">
            <i class="fas fa-shopping-basket" />
            <h6 class="ml-2 p-responsive" v-html="filters.cflt('average_sale')" />
          </div>
          <div class="bo-dashboard-item-overlay bo-dashboard-average-sale flex-column align-items-center pt-4"
            :class="{ 'bothVAT': globalState.getters.vatDisplay == VATDisplayType.BOTH }">
            <Progress :color="Color.White" v-if="!CASummarySales" />
            <div v-else>
              <div v-if="[VATDisplayType.HTVA, VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)"
                :class="[VATDisplayType.BOTH].includes(globalState.getters.vatDisplay) ? 'border-bottom-light' : ''"
                class="p-2 p-text-nowrap">
                <span v-html="filters.formatDecimal(salesAverageHTVA, '€')" />
                <span v-if="globalState.getters.vatDisplay == VATDisplayType.BOTH" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_excl') }}</span>
              </div>
              <div v-if="[VATDisplayType.TVAC, VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)"
                class="p-2 p-text-nowrap">
                <span v-html="filters.formatDecimal(salesAverageTVAC, '€')"></span>
                <span v-if="globalState.getters.vatDisplay == VATDisplayType.BOTH" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_incl') }}</span>
              </div>
            </div>
          </div>
          <div class="bo-dashboard-item-overlay justify-content-end align-items-end pt-4">
            <span v-if="globalState.getters.vatDisplay == VATDisplayType.TVAC" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_incl') }}</span>
            <span v-if="globalState.getters.vatDisplay == VATDisplayType.HTVA" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_excl') }}</span>
          </div>
        </div>
        <div :style="itemStyle" class="align-items-center">
          <Card class="background-gradient-blue h-full w-full"></Card>
          <div class="bo-dashboard-item-overlay bo-dashboard-item-overlay-title">
            <i class="fas fa-dollar" />
            <h6 class="ml-2 p-responsive" v-html="filters.cflt('period_turnover')" />
          </div>
          <div class="bo-dashboard-item-overlay bo-dashboard-period-turnover align-items-center pt-4"
            :class="{ 'bothVAT': globalState.getters.vatDisplay == VATDisplayType.BOTH }">
            <Progress :color="Color.White" v-if="!CASummarySales" />
            <div v-else>
              <div v-if="[VATDisplayType.HTVA, VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)" :class="{
    'border-bottom-light': [VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)
  }" class="p-2 p-text-nowrap">
                <span v-html="filters.formatDecimal(salesSumHTVA, '€')"></span>
                <span v-if="globalState.getters.vatDisplay == VATDisplayType.BOTH" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_excl') }}</span>
              </div>
              <div v-if="[VATDisplayType.TVAC, VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)"
                class="p-2 p-text-nowrap">
                <span v-html="filters.formatDecimal(salesSumTVAC, '€')"></span>
                <span v-if="globalState.getters.vatDisplay == VATDisplayType.BOTH" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_incl') }}</span>
              </div>
            </div>
          </div>
          <div class="bo-dashboard-item-overlay justify-content-end align-items-end pt-4">
            <span v-if="globalState.getters.vatDisplay == VATDisplayType.TVAC" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_incl') }}</span>
            <span v-if="globalState.getters.vatDisplay == VATDisplayType.HTVA" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_excl') }}</span>
          </div>
        </div>
        <div :style="itemStyle" class="align-items-center">
          <Card class="background-gradient-purple h-full w-full"></Card>
          <div class="bo-dashboard-item-overlay bo-dashboard-item-overlay-title">
            <i class="fas fa-bolt" />
            <h6 class="ml-2 p-responsive" v-html="filters.cflt('current_vat')"></h6>
          </div>
          <div class="bo-dashboard-item-overlay bo-dashboard-current-vat align-items-center pt-4"
            :class="{ 'bothVAT': globalState.getters.vatDisplay == VATDisplayType.BOTH }">
            <Progress :color="Color.White" v-if="!Current" />
            <div v-else>
              <div v-if="[VATDisplayType.HTVA, VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)" :class="{
    'border-bottom-light': [VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)
  }" class="p-2 p-text-nowrap">
                <span v-html="filters.formatDecimal(salesCurrentHTVA, '€')"></span>
                <span v-if="globalState.getters.vatDisplay == VATDisplayType.BOTH" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_excl') }}</span>
              </div>
              <div v-if="[VATDisplayType.TVAC, VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)"
                class="p-2 p-text-nowrap">
                <span v-html="filters.formatDecimal(salesCurrentTVAC, '€')"></span>
                <span v-if="globalState.getters.vatDisplay == VATDisplayType.BOTH" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_incl') }}</span>
              </div>
            </div>
          </div>
          <div class="bo-dashboard-item-overlay bo-dashboard-footer align-items-end px-4 pb-5">
            <div class="flex flex-column justify-content-center">
              <div class="text-center mx-2">{{ filters.cflt('current_orders') }}: {{ salesCurrentOrders }}</div>
              <div class="text-center mx-2">{{ filters.cflt('current_receipts') }}: {{ salesCurrentReceipts }}</div>
            </div>
          </div>
          <div class="bo-dashboard-item-overlay justify-content-end align-items-end pt-4">
            <span v-if="globalState.getters.vatDisplay == VATDisplayType.TVAC" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_incl') }}</span>
            <span v-if="globalState.getters.vatDisplay == VATDisplayType.HTVA" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_excl') }}</span>
          </div>
        </div>
        <div :style="itemStyle" class="align-items-center" v-if="showGuestCount">
          <Card class="background-gradient-violet h-full w-full"></Card>
          <div class="bo-dashboard-item-overlay bo-dashboard-item-overlay-title">
            <i class="fas fa-chair" />
            <h6 class="ml-2 p-responsive" v-html="filters.cflt('guest_count_guests')"></h6>
          </div>
          <div class="bo-dashboard-item-overlay bo-dashboard-guest-count align-items-center pt-4"
            :class="{ 'bothVAT': globalState.getters.vatDisplay == VATDisplayType.BOTH }">
            <Progress :color="Color.White" v-if="!GuestCount" />
            <table class="my-2" v-else>
              <tr class="border-bottom-light">
                <td></td>
                <td v-html="filters.cflt('count')" />
                <td v-if="[VATDisplayType.HTVA, VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)"
                  class="text-right"
                  v-html="filters.cflt(globalState.getters.vatDisplay == VATDisplayType.BOTH ? 'guest_count_avg_htva' : 'dashboard_cardguestcount_average')" />
                <td v-if="[VATDisplayType.TVAC, VATDisplayType.BOTH].includes(globalState.state.vatDisplay)"
                  class="text-right"
                  v-html="filters.cflt(globalState.getters.vatDisplay == VATDisplayType.BOTH ? 'guest_count_avg_tvac' : 'dashboard_cardguestcount_average')" />
              </tr>
              <tr>
                <td v-html="filters.cflt('guest_count_in_progress')" />
                <td class="text-right" v-html="guestCountInProgress" />
                <td v-if="[VATDisplayType.HTVA, VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)"
                  v-html="filters.formatDecimal(guestAvgInProgressHTVA, '€')" />
                <td v-if="[VATDisplayType.TVAC, VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)"
                  v-html="filters.formatDecimal(guestAvgInProgressTVAC, '€')" />
              </tr>
              <tr>
                <td v-html="filters.cflt('guest_count_closed')" />
                <td v-html="guestCountClosed" />
                <td v-if="[VATDisplayType.HTVA, VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)"
                  v-html="filters.formatDecimal(guestAvgClosedHTVA, '€')" />
                <td v-if="[VATDisplayType.TVAC, VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)"
                  v-html="filters.formatDecimal(guestAvgClosedTVAC, '€')" />
              </tr>
              <tr>
                <td class="text-right" v-html="filters.cflt('guest_count_total')" />
                <td v-html="guestCountInProgress + guestCountClosed" />
                <td v-if="[VATDisplayType.HTVA, VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)"
                  v-html="filters.formatDecimal(guestAvgInProgressHTVA + guestAvgClosedHTVA, '€')" />
                <td v-if="[VATDisplayType.TVAC, VATDisplayType.BOTH].includes(globalState.getters.vatDisplay)"
                  v-html="filters.formatDecimal(guestAvgInProgressTVAC + guestAvgClosedTVAC, '€')" />
              </tr>
            </table>
          </div>
          <div class="bo-dashboard-item-overlay justify-content-end align-items-end pt-4">
            <span v-if="globalState.getters.vatDisplay == VATDisplayType.TVAC" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_incl') }}</span>
            <span v-if="globalState.getters.vatDisplay == VATDisplayType.HTVA" class="bo-dashboard-item-tva">{{
    filters.cflt('vat_excl') }}</span>
          </div>
        </div>
      </div>
      <!--------------------------------------------- END DASHBOARD CARDS --------------------------------------------->
      <Card class="card-redcarmin bo-dashboard-card">
        <template #header>
          <div class="flex flex-row justify-content-between pt-3">
            <h2>
              <i class="fas fa-chart-line color-redcarmin mr-3"></i>
              <span>{{ filters.cflt("turnover") }}</span>
            </h2>
          </div>
        </template>
        <template #content>
          <Progress v-if="!CASummarySales" />
          <!-- <Progress v-if="!CASummarySales || (showGuestCount && !GuestCount)" /> -->
          <Chart v-else :options="{
    //  aspectRatio: graphicRatio,
    responsive: true,
    maintainAspectRatio: false,
  }" style="position: relative; height: 40vh" ref="chart" type="bar" :data="chartData" />
        </template>
      </Card>
      <Card class="card-bluesky bo-dashboard-card mt-5 pt-3">
        <template #header>
          <div class="flex flex-row justify-content-between pt-3">
            <h2>
              <i class="fas fa-th color-steel mr-3"></i>
              <span>{{ filters.cflt('turnover_stats') }}</span>
            </h2>
            <ExcelExport v-if="(listingData?.length ?? 0) > 0"
                         :data="listingData"
                         :columns="listingColumns"
              file-name="Export dashboard" file-type="xlsx" sheet-name="Data">
              <Button :class="'p-button p-button-rounded p-button-sm background-gradient-sunrise'"
                icon="fas fa-file-download" :label="filters.cflt('download_xlsx')" />
            </ExcelExport>
          </div>
        </template>
        <template #content>
          <div class="col-12 p-fluid overflow-x-auto">
            <!-- <div v-if="!CASummarySales || !CASummaryPayments || (showGuestCount && !GuestCount)" -->
            <div v-if="!CASummarySales || !CASummaryPayments" class="flex justify-content-center">
              <SkeletonGrid :length="4" :columns="listingSummaryPesistentColumns.map(label => filters.cflt(label))" />
            </div>
            <DataTable v-else ref="listing" :value="listingData" v-model:filters="listingFilters" filterDisplay="row"
              class="p-datatable-sm background-white" responsiveLayout="scroll" selectionMode="single">
              <Column v-for="column in listingColumns" :key="column.source" :field="column.source"
                :filterField="column.source">
                <template #header>
                  <div class="w-full" :style="{
    'text-align': listingSummaryStringColumns.includes(column.source) ||
      listingSummaryDateColumns.includes(column.source) ? 'left' : 'center',
    'min-width': column.source === 'store_name' ? '12rem' : 'unset'
  }" v-html="column.label ? filters.cflt(column.label) : ''" />
                </template>
                <template #body="slotProps">
                  <div class="w-full" translate="no" :style="{
    'text-align': listingSummaryStringColumns.includes(column.source) ||
      listingSummaryDateColumns.includes(column.source) ? 'left' : 'center'
  }" v-html="listingSummaryDateColumns.includes(column.source) ?
    moment(slotProps.data[column.source]).format('DD/MM/YYYY') :
    listingSummaryStringColumns.includes(column.source) || listingSummaryIntegerColumns.includes(column.source) ?
      slotProps.data[column.source] :
      filters.formatDecimal(slotProps.data[column.source], '€')" />
                </template>
              </Column>
              <ColumnGroup type="footer" v-if="Object.keys(listingColumns).length > 0">
                <Row>
                  <Column :footer="filters.cflt('totals') + ':'" :colspan="2" footerStyle="text-align:right" />
                  <Column v-for="column in listingColumns.slice(2)" :key="column.source">
                    <template #footer>
                      <div class="w-full" :style="{
    'text-align': (listingData?.length ?? 0) > 0 &&
      (typeof listingData[0][column.source] === 'number') ?
      'center' : 'left'
  }" v-html="total(column.source)" />
                    </template>
                  </Column>
                </Row>
              </ColumnGroup>
            </DataTable>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/scss/_variables.scss";

.xs,
.sm {
  & .bo-dashboard-item-overlay {
    &.bo-dashboard-item-overlay-title * {
      font-size: calc(0.7rem + 1vw);
    }

    &.bo-dashboard-sales-count,
    &.bo-dashboard-items-count {
      font-size: calc(3rem + 1vw);
    }

    &.bo-dashboard-average-sale.bothVAT,
    &.bo-dashboard-period-turnover.bothVAT,
    &.bo-dashboard-current-vat.bothVAT {
      font-size: calc(1.1rem + 1.7vw);
    }

    &.bo-dashboard-average-sale:not(.bothVAT),
    &.bo-dashboard-period-turnover:not(.bothVAT),
    &.bo-dashboard-current-vat:not(.bothVAT) {
      font-size: calc(0rem + 7vw);
    }

    &.bo-dashboard-guest-count.bothVAT * {
      font-size: calc(0.4em + 1.2vw);
    }

    &.bo-dashboard-guest-count:not(.bothVAT) * {
      font-size: calc(0.85em + 0.3vw);
    }

    &.bo-dashboard-footer * {
      font-size: calc(0.85em + 0.3vw);
    }
  }
}

.md,
.lg {
  & .bo-dashboard-item-overlay {
    &.bo-dashboard-item-overlay-title * {
      font-size: calc(0.5rem + 1.2vw);
    }

    &.bo-dashboard-sales-count,
    &.bo-dashboard-items-count {
      font-size: calc(1rem + 2.5vw);
    }

    &.bo-dashboard-average-sale.bothVAT,
    &.bo-dashboard-period-turnover.bothVAT,
    &.bo-dashboard-current-vat.bothVAT {
      font-size: calc(-0.8rem + 3.9vw);
    }

    &.bo-dashboard-average-sale:not(.bothVAT),
    &.bo-dashboard-period-turnover:not(.bothVAT),
    &.bo-dashboard-current-vat:not(.bothVAT) {
      font-size: calc(-0.5rem + 3.6vw);
    }

    &.bo-dashboard-guest-count.bothVAT * {
      font-size: calc(0em + 1.2vw);
    }

    &.bo-dashboard-guest-count:not(.bothVAT) * {
      font-size: calc(-0.45em + 2vw);
    }

    &.bo-dashboard-footer * {
      font-size: calc(0.60em + 0.32vw);
    }
  }
}

.xl {
  & .bo-dashboard-item-overlay {
    &.bo-dashboard-item-overlay-title * {
      font-size: calc(0.5rem + 0.8vw);
    }

    &.bo-dashboard-sales-count,
    &.bo-dashboard-items-count {
      font-size: calc(0.4rem + 2.5vw);
    }

    &.bo-dashboard-average-sale.bothVAT,
    &.bo-dashboard-period-turnover.bothVAT,
    &.bo-dashboard-current-vat.bothVAT {
      font-size: calc(-0.1em + 1.5vw);
      //& .bo-dashboard-item-tva { font-size: 1em; opacity: 0.8 }
    }

    &.bo-dashboard-average-sale:not(.bothVAT),
    &.bo-dashboard-period-turnover:not(.bothVAT),
    &.bo-dashboard-current-vat:not(.bothVAT) {
      font-size: calc(-0.6rem + 2.5vw);
      //& .bo-dashboard-item-tva { font-size: 1em; opacity: 0.8; color: red }
    }

    &.bo-dashboard-guest-count.bothVAT * {
      font-size: calc(-0.5em + 1vw);
    }

    &.bo-dashboard-guest-count:not(.bothVAT) * {
      font-size: calc(-0.2em + 1vw);
    }

    &.bo-dashboard-footer * {
      font-size: calc(-0.17rem + 1vw);
    }
  }
}

.bo-dashboard-item-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;

  &,
  & * {
    color: #ffffff
  }

  &.bo-dashboard-item-overlay-title * {
    opacity: 0.8;
    padding-top: 10px;
    text-align: center;
  }

  &.bo-dashboard-guest-count * {
    &>td {
      padding: 3% calc(1% + 0.2vw);
      text-align: right;
    }
  }

  & .bo-dashboard-item-tva {
    text-transform: uppercase;
    font-size: 0.6em;
    opacity: 0.8;
  }

  & .border-bottom-light {
    border-bottom: 1px solid rgba(255, 255, 255, .2);
  }
}
</style>
<script lang="ts">

import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import useBreakpoints from '@/compositions/useBreakpoints'
import { globalState } from '@/states/global'
import Store from '@/models/store'
import DashProvider from '@/providers/dashprovider'
import filters from '@/helpers/filters'
import Progress, { Color } from '@/components/Progress.vue'
import moment from 'moment/moment'
import { ConsumptionType } from '@/models/enums/consumptiontype.enum'
import CASummarySalesModel from '@/models/dashboard/casummarysalesmodel'
import { VATDisplayType } from '@/models/enums/vatdisplay.enum'
import GuestCountModel from '@/models/dashboard/guestcountmodel'
import SkeletonGrid from '@/components/SkeletonGrid.vue'
import CurrentModel from '@/models/dashboard/currentmodel'
import CASummaryPaymentsModel from '@/models/dashboard/casummarypaymentsmodel'
import { FilterMatchMode } from 'primevue/api'
import useLocalization from '@/hooks/localization'
import ReportHelper from '@/helpers/reporthelper'
import ExcelExport from '@/components/ExcelExport.vue'
import {ExcelColumn} from "@/helpers/excelhelper";

export default defineComponent({
  components: {
    Progress,
    SkeletonGrid,
    ExcelExport
  },
  setup(props) {
    const { t } = useI18n();
    const { width, type } = useBreakpoints();
    const localization = useLocalization();

    const dashProvider = new DashProvider();

    const CASummarySales = ref<CASummarySalesModel[]>();
    const CASummaryPayments = ref<CASummaryPaymentsModel[]>();
    const GuestCount = ref<GuestCountModel[]>();
    const Current = ref<CurrentModel[]>();

    const showGuestCount = computed(() => globalState.getters.selectedStores.some((s: Store) => (s.resupply) && (s.consumption_type != ConsumptionType.RETAIL)));

    const itemStyle = computed(() => {
      const w = ['xs', 'sm'].includes(type.value) ? 50 :
        ['md', 'lg'].includes(type.value) ? 33 :
          showGuestCount.value ? 16.7 :
            20;

      return {
        'width': `calc(${w}% - 18px)`,
        'min-width': `calc(${w}% - 18px)`,
        'max-width': `calc(${w}% - 18px)`, 'margin': '8px',
        height: 'calc(5vw + 160px)',
        position: 'relative',
        'justify-items': 'center'
      }
    })

    const salesCount = computed(() => CASummarySales.value?.reduce((acc: number, sale: CASummarySalesModel) => acc + (sale.tickets ?? 0), 0) ?? 0);
    const itemsCount = computed(() => Math.round(CASummarySales.value?.reduce((acc: number, sale: CASummarySalesModel) => acc + (sale.quantity ?? 0), 0) ?? 0));
    const salesSumHTVA = computed(() => CASummarySales.value?.reduce((acc: number, sale: CASummarySalesModel) => acc + (sale.turnover_vat_excluded ?? 0), 0) ?? 0);
    const salesSumTVAC = computed(() => CASummarySales.value?.reduce((acc: number, sale: CASummarySalesModel) => acc + (sale.turnover_vat_included ?? 0), 0) ?? 0);
    const salesAverageHTVA = computed(() => salesCount.value > 0 ? salesSumHTVA.value / salesCount.value : 0);
    const salesAverageTVAC = computed(() => salesCount.value > 0 ? salesSumTVAC.value / salesCount.value : 0);
    const salesCurrentHTVA = computed(() => (Current.value?.length ?? 0) == 1 ? Current.value![0].total_vat_excluded ?? 0 : 0);
    const salesCurrentTVAC = computed(() => (Current.value?.length ?? 0) == 1 ? Current.value![0].total_vat_included ?? 0 : 0);
    const salesCurrentOrders = computed(() => (Current.value?.length ?? 0) == 1 ? Current.value![0].count_orders ?? 0 : 0);
    const salesCurrentReceipts = computed(() => (Current.value?.length ?? 0) == 1 ? Current.value![0].count_receipts ?? 0 : 0);

    const guestCountInProgress = computed(() => GuestCount.value?.reduce((acc: number, guestCount: GuestCountModel) => acc + (guestCount.guest_count_in_progress ?? 0), 0) ?? 0);
    const guestCountClosed = computed(() => GuestCount.value?.reduce((acc: number, guestCount: GuestCountModel) => acc + (guestCount.guest_count_closed ?? 0), 0) ?? 0);
    const guestTotalInProgressHTVA = computed(() => GuestCount.value?.reduce((acc: number, guestCount: GuestCountModel) => acc + (guestCount.total_vat_excluded_in_progress ?? 0), 0) ?? 0);
    const guestTotalInProgressTVAC = computed(() => GuestCount.value?.reduce((acc: number, guestCount: GuestCountModel) => acc + (guestCount.total_vat_included_in_progress ?? 0), 0) ?? 0);
    const guestAvgInProgressHTVA = computed(() => guestCountInProgress.value > 0 ? guestTotalInProgressHTVA.value / guestCountInProgress.value : 0);
    const guestAvgInProgressTVAC = computed(() => guestCountInProgress.value > 0 ? guestTotalInProgressTVAC.value / guestCountInProgress.value : 0);
    const guestTotalClosedHTVA = computed(() => GuestCount.value?.reduce((acc: number, guestCount: GuestCountModel) => acc + (guestCount.total_vat_excluded_closed ?? 0), 0) ?? 0);
    const guestTotalClosedTVAC = computed(() => GuestCount.value?.reduce((acc: number, guestCount: GuestCountModel) => acc + (guestCount.total_vat_included_closed ?? 0), 0) ?? 0);
    const guestAvgClosedHTVA = computed(() => guestCountClosed.value > 0 ? guestTotalClosedHTVA.value / guestCountClosed.value : 0);
    const guestAvgClosedTVAC = computed(() => guestCountClosed.value > 0 ? guestTotalClosedTVAC.value / guestCountClosed.value : 0);

    const chart = ref();
    const chartData = computed(() => {
      const labels: string[] = [];
      const datasets: any[] = [];

      if (!CASummarySales.value) return { labels, datasets };

      const reversedArray = CASummarySales.value
        .map((e: CASummarySalesModel, idx: number, array: CASummarySalesModel[]) => array[array.length - 1 - idx]);

      reversedArray.map((x: CASummarySalesModel) => labels.push(x.group_date?.toLocaleDateString() ?? 'N/A'));

      datasets.push({
        type: "line",
        label: filters.cflt('total'),
        borderColor: "#FF6449",
        backgroundColor: "#FF6449",
        borderWidth: 2,
        fill: false,
        data: reversedArray.map((x: CASummarySalesModel) => x.turnover_vat_included ?? 0)
      });

      datasets.push({
        type: "bar",
        label: filters.cflt('consumption_type_eatin'),
        backgroundColor: "#FF2F71",
        data: reversedArray.map((x: CASummarySalesModel) => x.turnover_vat_included_eatin ?? 0)
      });

      datasets.push({
        type: "bar",
        label: filters.cflt("consumption_type_takeaway"),
        backgroundColor: "#38B575",
        data: reversedArray.map((x: CASummarySalesModel) => x.turnover_vat_included_take_away ?? 0)
      });

      datasets.push({
        type: "bar",
        label: filters.cflt("consumption_type_retail"),
        backgroundColor: "#FFA700",
        data: reversedArray.map((x: CASummarySalesModel) => x.turnover_vat_included_retail ?? 0)
      });

      datasets.push({
        type: "bar",
        label: filters.cflt("consumption_type_delivery"),
        backgroundColor: "#6170BB",
        data: reversedArray.map((x: CASummarySalesModel) => x.turnover_vat_included_delivery ?? 0)
      });

      /* if (showGuestCount.value && GuestCount.value) {
        datasets.push({
          type: 'line',
          label: filters.cflt('guest_count_guests'),
          backgroundColor: '#332596',
          data: reversedArray.map((x: CASummarySalesModel) => x.group_date!)
                             .map((x: Date) => {
                               const guestCount = GuestCount.value?.find((y: GuestCountModel) => y.group_date?.getDate() === x.getDate());
                               return (guestCount?.guest_count_in_progress ?? 0) +
                                      (guestCount?.guest_count_closed ?? 0);
                            })
        });

        datasets.push({
          type: 'line',
          label: filters.cflt('guest_count_avg'),
          backgroundColor: '#5A8D42',
          data: reversedArray.map((x: CASummarySalesModel) => x.group_date!)
                             .map((x: Date) => {
                               const guestCount = GuestCount.value?.find((y: GuestCountModel) => y.group_date?.getDate() === x.getDate());
                               return (guestCount?.guest_count_in_progress ?? 0) + (guestCount?.guest_count_closed ?? 0) > 0 ?
                                        ((guestCount?.total_vat_included_in_progress ?? 0) + (guestCount?.total_vat_included_closed ?? 0)) /
                                        ((guestCount?.guest_count_in_progress ?? 0) + (guestCount?.guest_count_closed ?? 0)) :
                                      0
                             })
        });
      } */

      chart.value?.refresh();
      return { labels, datasets }
    })

    const listingSummaryPesistentColumns = [
      'group_date',
      'store_name',
      'turnover_vat_included',
      'turnover_vat_included_eatin',
      'turnover_vat_included_take_away',
      'turnover_vat_included_retail',
      'turnover_vat_included_delivery',
      'total_vat_included_by_vat_rate_percent_base',
      'turnover_vat_excluded',
      'total_vat_excluded_by_vat_rate_percent_base',
      'vat',
      'vat_rate_percent_base',
      'tickets'
    ];

    // What does not fit in thos column names are then currencies...
    const listingSummaryStringColumns = [
      'store_name'
    ];

    const listingSummaryIntegerColumns = [
      'tickets', 'guest_count_in_progress', 'guest_count_closed'
    ];

    const listingSummaryDateColumns = [
      'group_date'
    ];

    const listing = ref();
    const listingData = computed(() => {
      if (!CASummarySales.value || !CASummaryPayments.value) return []

      return CASummarySales.value.map((summaryModel: CASummarySalesModel) => {
        const result: { [key: string]: any; } = {};

        summaryModel.vat = Object.keys(summaryModel)
                                 .filter(key => key.startsWith("vat_rate_percent_"))
                                 .reduce((total, key) => total + (summaryModel[key] || 0), 0);

        // Add persistent column values from CASummarySales
        Object.keys(summaryModel).forEach((key) => {
          if (listingSummaryPesistentColumns.includes(key)) result[key] = summaryModel[key];
        });

        // handle dynamic vat
        Object.keys(summaryModel).filter(key =>
          key.startsWith('total_vat_included_by_vat_rate_percent_') ||
          key.startsWith('total_vat_excluded_by_vat_rate_percent_') ||
          key.startsWith('vat_rate_percent_')
        ).forEach((key) => {
          result[key] = summaryModel[key];
        });

        // Add payment_x column values from CASummaryPayments
        paymentColumns.value.forEach((l, idx) => {
          const p = CASummaryPayments.value?.find(
            y => (l === y[`payment_name_${localization.lang()}`]) &&
              (y.store_id == summaryModel.store_id) &&
              (y.group_date?.getFullYear() === summaryModel.group_date?.getFullYear()) &&
              (y.group_date?.getMonth() === summaryModel.group_date?.getMonth()) &&
              (y.group_date?.getDate() === summaryModel.group_date?.getDate())
          );
          result[`payment_${idx}`] = p?.amount ?? 0;
        });

        /* if (showGuestCount.value) {
          let p = GuestCount.value?.find(
              y => y.store_id == x.store_id &&
                   y.group_date?.getDate() == x.group_date?.getDate());
          r['guest_count_in_progress'] = p?.guest_count_in_progress ?? 0;
          r['guest_count_closed'] = p?.guest_count_closed ?? 0;
          r['total_vat_included_in_progress'] = p?.total_vat_included_in_progress ?? 0;
          r['total_vat_excluded_in_progress'] = p?.total_vat_excluded_in_progress ?? 0;
          r['total_vat_included_closed'] = p?.total_vat_included_closed ?? 0;
          r['total_vat_excluded_closed'] = p?.total_vat_excluded_closed ?? 0;
        } */

        return result;
      });
    });

    const listingFilters = ref({ 'Store': { value: null, matchMode: FilterMatchMode.CONTAINS } });

    const paymentColumns = computed(() => {
      if (!CASummaryPayments.value) return [];
      return [...new Set(
        CASummaryPayments.value.filter(x => x.amount != 0)
          .map(x => x[`payment_name_${localization.lang()}`])
          .sort()
      )];

    });

    const handleListingVATColumn = (startsWithKey: string, firstData: any,) => {
      const columns: any[] = [];

      Object.keys(firstData).filter(key => key.startsWith(startsWithKey)).forEach(key => {
        const number = key.replace(startsWithKey, '');
        const keyLabel = `${t(`${startsWithKey}base`)} ${number}%`;

        columns.push({
          label: keyLabel,
          source: key,
          type: 'currency'
        });
      });

      return columns;
    }

    const listingColumns = computed(() => {
      const columns: ExcelColumn[] = [];

      const firstData = listingData.value?.length > 0 ? listingData.value.at(0) : null;

      // Add persistent columns names from CASummarySales
      // Column total vat excluded does not exist but values are identical to total_vat_included_by_vat_rate_percent_0
      listingSummaryPesistentColumns.forEach((column) => {
        if (firstData && column == 'total_vat_included_by_vat_rate_percent_base') {
          columns.push(...handleListingVATColumn('total_vat_included_by_vat_rate_percent_', firstData));
        } else if (firstData && column == 'total_vat_excluded_by_vat_rate_percent_base') {
          columns.push(...handleListingVATColumn('total_vat_excluded_by_vat_rate_percent_', firstData));
        } else if (firstData && column == 'vat_rate_percent_base') {
          columns.push(...handleListingVATColumn('vat_rate_percent_', firstData));
        } else {
          columns.push({
            label: filters.cflt(column),
            source: column,
            type: ['group_date'].includes(column) ? 'date' :
                  ['store_name'].includes(column) ? 'text' :
                  ['tickets'].includes(column) ? 'number' :
                  'currency'
          });
        }
      });

      // Add payment_x columns names and labels from CASummaryPayments
      paymentColumns.value.map((l, idx) => columns.push({
        label: l,
        source: `payment_${idx}`
      }));

      /* if (showGuestCount.value) {
        r.push({ label: t('guest_count_in_progress'), source: 'guest_count_in_progress'});
        r.push({ label: t('guest_count_closed'), source: 'guest_count_closed'});
        r.push({ label: t('total_vat_included_in_progress'), source: 'total_vat_included_in_progress'});
        r.push({ label: t('total_vat_excluded_in_progress'), source: 'total_vat_excluded_in_progress'});
        r.push({ label: t('total_vat_included_closed'), source: 'total_vat_included_closed'});
        r.push({ label: t('total_vat_excluded_closed'), source: 'total_vat_excluded_closed'});
      } */

      return columns;
    });
    const loadCASummarySales = async (dates: any): Promise<void> => {
      if (globalState.state.selected.storeIds.length == 0) return undefined;
      return new Promise((resolve, reject) => {
        dashProvider.getCASummarySales(globalState.state.selected.storeIds, dates.from, dates.to)
          .then((r: any) => { CASummarySales.value = r; resolve(); })
          .catch((err: any) => { reject(err); })
      })
    }

    const loadCASummaryPayments = async (dates: any): Promise<void> => {
      if (globalState.state.selected.storeIds.length == 0) return undefined;
      return new Promise((resolve, reject) => {
        dashProvider.getCASummaryPayments(globalState.state.selected.storeIds, dates.from, dates.to)
          .then((r: any) => { CASummaryPayments.value = r; resolve(); })
          .catch((err: any) => { reject(err); })
      })
    }

    const loadGuestCount = async (dates: any): Promise<void> => {
      if (globalState.state.selected.storeIds.length == 0) return undefined;
      return new Promise((resolve, reject) => {
        dashProvider.getGuestCount(globalState.state.selected.storeIds, dates.from, dates.to)
          .then((r: any) => { GuestCount.value = r; resolve(); })
          .catch((err: any) => { reject(err); })
      })
    }

    const loadCurrent = async (dates: any): Promise<void> => {
      if (globalState.state.selected.storeIds.length == 0) return undefined;
      return new Promise((resolve, reject) => {
        dashProvider.getCurrent(globalState.state.selected.storeIds, dates.from, dates.to)
          .then((r: any) => { Current.value = r; resolve(); })
          .catch((err: any) => { reject(err); })
      })
    }

    const fetchCaSummary = (dates: any) => {
      CASummarySales.value = CASummaryPayments.value = GuestCount.value = Current.value = undefined;
      loadCASummarySales(dates);
      loadCASummaryPayments(dates);
      if (showGuestCount.value) loadGuestCount(dates);
      loadCurrent(dates);
    }
    const refresh = (dates: any) => fetchCaSummary(dates);

    const total = (field: string) =>
      ReportHelper.total(listing, field, 'currency',
        ['tickets', 'guest_count_in_progress', 'guest_count_closed']);

    return {
      filters, globalState, moment,
      VATDisplayType,
      type, itemStyle, Color,
      showGuestCount,
      refresh,
      CASummarySales, CASummarySalesModel,
      CASummaryPayments, GuestCount, Current,
      salesCount, itemsCount, salesSumHTVA, salesSumTVAC, salesAverageHTVA, salesAverageTVAC,
      salesCurrentHTVA, salesCurrentTVAC, salesCurrentOrders, salesCurrentReceipts,
      guestCountInProgress, guestCountClosed,
      guestTotalInProgressHTVA, guestTotalInProgressTVAC,
      guestAvgInProgressHTVA, guestAvgInProgressTVAC,
      guestTotalClosedHTVA, guestTotalClosedTVAC,
      guestAvgClosedHTVA, guestAvgClosedTVAC,
      chart, chartData,
      listing, listingData, listingFilters,
      listingColumns, listingSummaryPesistentColumns,
      listingSummaryStringColumns, listingSummaryIntegerColumns, listingSummaryDateColumns,
      total
    }
  }
})
</script>