export default class ObjectsHelper {
    public static sortColumns = (data: any, cols: string[] = []): any => {
        let sorted = cols.reduce((acc, key) => (key in data) ?  {...acc, [key]: data[key]} : acc, {});
        sorted = Object.keys(data).reduce((acc, key) => !cols.includes(key) ? { ...acc, [key]: data[key] } : acc, sorted);
        return sorted;
    }
    public static removeColumns = (data: any[], cols: string[] = []): any[] => {
        if ((data?.length ?? 0) == 0) return data;

        for (const row of data)
            for (const col of cols)
                if (col in row) delete row[col];

        return data;
    }

    public static copyColumn = (data: any[], source: string, dest: string): any[] => {
        if ((data?.length ?? 0) == 0) return data;

        for (const row of data)
             
            if (Object.hasOwnProperty(source))
                row[dest] = row[source];

        return data;
    }

    public static removeZeroSumColumns = (data: any[], persistent: string[] = []): any[] => {
        if ((data?.length ?? 0) == 0) return data;

        const keys = new Set(Object.keys(data[0]));
        for (const p of persistent) keys.delete(p);

        for (const key of keys) {
            let sum = 0;
            for (const object of data) {
                if (typeof object[key] !== 'number') {
                    sum = NaN;
                    break;
                }
                sum += object[key];
            }

            if (sum === 0)
                for (const object of data)
                    delete object[key];
        }

        return data;
    }
}