<template>
  <Button class="p-button p-button-rounded p-button-sm background-gradient-sunrise" :label="$filters.cflt('GenericWords.ExportToPDF')"
    @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" v-bind="$attrs" icon="fal fa-file-pdf"
    v-show="items.length > 0" />
  <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import Reporting from "@/models/reporting";
import ReportingProvider from "@/providers/reportingprovider";
import useLocalization from "@/hooks/localization";
import PdfHelper from "@/helpers/pdfHelper";
import { globalState } from "@/states/global";
import moment from "moment";

export default defineComponent({
  props: {
    reportings: {
      type: Array<Reporting>,
      required: true,
    },
  },
  setup(props) {
    const localization = useLocalization();
    const menu = ref();

    const items = computed(() => props.reportings.map(x => {
      return {
        label: localization.localize(x),
        command: () => {
          console.log("export to pdf ", x.id);
          exportReporting(x.id!);
        }
      }
    }));

    const exportReporting = async (reportingId: number) => {
      const fromDate = moment(globalState.state.period?.start).format("yyyy-MM-DD HH:mm:ss");
      const toDate = moment(globalState.state.period?.end).format("yyyy-MM-DD HH:mm:ss");
      const storeIds = globalState.state.selected.storeIds;

      PdfHelper.openPdfInNewTab(await new ReportingProvider().getRawData(reportingId, storeIds, fromDate, toDate));
    };

    const toggle = (event: any) => {
      if (props.reportings.length == 1) {
        exportReporting(props.reportings[0].id!);
      } else {
        menu.value.toggle(event);
      }
    };

    return {
      toggle,
      menu,
      items,
    };
  },
});
</script>