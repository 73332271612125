import PagedDiscountReasons from '@/models/paged/pageddiscountreasons'
import BaseProvider from './baseprovider'
import { PagedParams } from '@/models/paged/pagedparams'

export default class DiscountReasonProvider extends BaseProvider {
    public async fetchPagedDiscountReasons(entityIds: number[], pagedParams?: PagedParams
    ): Promise<PagedDiscountReasons> {
        let p: string = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/discountreasons${p}`);

        const pagedItems = new PagedDiscountReasons(response.data);

        return pagedItems;
    }
}