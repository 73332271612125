<template>
  <Button
      class="table-button"
      :class="{
        'toggle-down': !upModel,
        'toggle-up': upModel
      }"
      icon="fal fa-chevron-up"
      @click="toggle"
  />
</template>
<style scoped>
  .toggle-down {
    text-color: white;
    transform: rotate(0deg);
    transition-duration: 500ms;
    transition-property: transform;
  }
  .toggle-up {
    text-color: black;
    transform: rotate(180deg);
    transition-duration: 500ms;
    transition-property: transform;
  }
</style>
<script lang="ts">
import {defineComponent, ref} from 'vue';

export default defineComponent({
  props: {
    up: { type: Boolean, default: true }
  },
  emits: ["update:up"],
  setup(props, { emit }) {

    const upModel = ref<boolean>(props.up ?? true);

    const toggle = () => {
      upModel.value = !upModel.value;
      emit('update:up', upModel.value)
    }

    return { toggle, upModel };
  },
});
</script>
