import Rank from "@/models/rank";
import BaseProvider from "./baseprovider"
import { PagedParams } from '@/models/paged/pagedparams';
import PagedRanks from '@/models/paged/pagedranks';
import RankListViewInterface from '@/models/view/ranklistviewmodel';
import PagedItemRankLinks from "@/models/paged/pageditemranklinks";

export interface IItemRankLinks {
    item_uids: string[];
    ranks: IItemRankParams[];
}

export interface IItemRankParams {
    rank_id: number;
    main: boolean;
    order_number: number;
}

export default class RankProvider extends BaseProvider {
    public async fetchRanks(): Promise<RankListViewInterface[]> {
        const response = await (await this.getApiV2()).get('/Ranks');
        return response.data as RankListViewInterface[];
    }

    public async fetchRanksForEntities(entityIds: number[]): Promise<RankListViewInterface[]> {
        let p: string = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/Ranks${p}`);

        return response.data as RankListViewInterface[];
    }

    public async fetchPagedRanks(entityIds: number[], pagedParams?: PagedParams): Promise<PagedRanks> {
        let p: string = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc',
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/ranks${p}`);

        const pagedRanks = new PagedRanks(response.data);

        return pagedRanks;
    }

    public async getRankFromUid(rankUid: string): Promise<Rank> {
        const response = await (await this.getApiV2()).get(`/ranks/${rankUid}`);

        return new Rank(response.data);
    }

    public async saveRank(rank: Rank): Promise<Rank> {
        const response = await (await this.getApiV2()).post(`/ranks/`, rank);

        return new Rank(response.data);
    }


    public async fetchItemRankLinks(
        entityIds: number[],
        pagedParams: PagedParams
    ): Promise<PagedItemRankLinks> {
        let p: string = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            // pageNumber: 1,
            // pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`ranks/itemlinks${p}`);

        return new PagedItemRankLinks(response?.data);
    }

    public async saveItemRankLinks(params: IItemRankLinks) {
        const response = await (await this.getApiV2()).post(`/ranks/itemlinks/link`, params);

        return response.data;
    }

    public async unlinkItemRankLinks(params: IItemRankLinks) {
        const response = await (await this.getApiV2()).post(`/ranks/itemlinks/unlink`, params);

        return response.data;
    }

    public async unlinkAllItemRankLinks(params: IItemRankLinks) {
        const response = await (await this.getApiV2()).post(`/ranks/itemlinks/unlinkall`, params);

        return response.data;
    }
}