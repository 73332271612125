import { inject } from 'vue'
import { CONFIG_KEY } from '@/plugins/vue-ganttastic/provider/symbols'

export default function provideConfig() {
  const config = inject(CONFIG_KEY)
  if (!config) {
    throw Error("Failed to inject config!")
  }
  return config
}
