<template>
  <span class="columns-selection-wrapper" @click="exportExcel">
    <slot></slot>
  </span>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import ExcelHelper, {ExcelColumn} from "@/helpers/excelhelper";
import {i18n} from "@/i18n";

export default defineComponent({
  props: {
    columns: {
      type: Array as PropType<ExcelColumn[]>,
      default: () => [],
    },
    data: {
      type: Array as PropType<object []>,
      default: () => [],
    },
    fileName: {
      type: String,
      default: "excel",
    },
    sheetName: {
      type: String,
      default: "SheetName",
    },
    fileType: {
      type: String,
      default: "xlsx",
      validator: (val: any) => ["xlsx", "xls"].includes(val),
    },
    nested: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const exportExcel = () => ExcelHelper.exportExcel(props.columns, props.data, props.fileName, props.fileType, props.sheetName, props.nested, i18n.mode === 'legacy' ? i18n.global.locale : i18n.global.locale.value)

    return {
      exportExcel,
    };
  },
});
</script>