<template>
  <div v-bind="$attrs" class="max-height-card-footer">
    <Card class="card-bluesky">
      <template #header>
        <div class="grid">
          <div class="col">
            <h1>
              <img v-if="listing?.icon_azure_path" width="28" class="image" style="width: 28px"
                :src="listing?.icon_azure_path" />&nbsp;&nbsp; {{ localization.localize(listing) }}
            </h1>
          </div>
          <div class="col text-right">
            <ExcelExport v-if="listing" :data="processedData()"
              :columns="columnsXLS"
              file-name="Export listing"
              file-type="xlsx" sheet-name="Data" :nested="false">
              <Button :class="'p-button p-button-rounded p-button-sm background-gradient-sunrise'"
                icon="fas fa-file-download" :label="filters.cflt('download_xlsx')" />
            </ExcelExport>
            <ReportingPDFExportMenu class="ml-2" :reportings="listingViewModel?.reportings ?? []" />
          </div>
        </div>
      </template>
      <template #content>
        <Progress class="flex justify-content-center" v-if="loading" />
        <div v-else-if="listingResults?.data" class="grid">
          <div class="col-12 p-fluid">
            <DataTable :value="listingResults.data" class="p-datatable-sm listing-datatable background-white"
              responsiveLayout="scroll" :filterDisplay="Object.keys(listingResults.data).length > 0 ? `row` : undefined"
              v-model:filters="listingResults.headerFilters" dataKey="internalIndex" ref="dataTable" :paginator="usePaginator"
              :rows="100"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[100, 250, 500]" selectionMode="single">
              <!-- <Column field="index" v-show="false"> </Column> -->
              <Column v-for="column in listingResults.dataColumns" :key="column.columnName" :field="column.columnName"
                :header="filters.cflt(column.columnName)" :sortable="true" :showFilterMenu="true"
                :filterField="column.columnName" v-show="column.columnName == 'internalIndex'">
                <template #body="slotProps">
                  <div v-if="column.isNumeric" translate="no"
                    v-html="filters.formatDecimal(Number(slotProps.data[column.columnName]))" />
                  <div v-else-if="column.isDate" translate="no"
                    v-html="DateHelper.formatDate(slotProps.data[column.columnName])" />
                  <div v-else-if="column.isBoolean" translate="no" v-html="slotProps.data[column.columnName] ?
    `<i class='fas fa-check-square color-greenfield' />` :
    `<i class='fas fa-square color-redcarmin'></i>`
    " />
                  <div v-else translate="no" v-html="slotProps.data[column.columnName]" />
                </template>
                <template #filter="{ filterModel, filterCallback }">
                  <InputText autocomplete="off" spellcheck="false" type="text" v-if="typeof filterModel !== `undefined`"
                    v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" />
                </template>
              </Column>
              <ColumnGroup type="footer" v-if="(listingResults.raw?.length ?? 0) > 0">
                <Row>
                  <Column v-for="(column, index) in listingResults.dataColumns" :key="column.columnName">
                    <template #footer>
                      <div class="mb-3" :style="{ 'visibility': index != 0 ? 'hidden' : 'visible' }">
                        {{ t('totals') }}
                      </div>
                      <div :set="data = processedData()" translate="no" v-html="column.isNumeric && ((data?.length ?? 0) > 0) ?
    filters.formatDecimal(data.map((d) => d[column.columnName]).reduce((sum, current) => sum + current)) :
    ''" />
                    </template>
                  </Column>
                </Row>
              </ColumnGroup>
            </DataTable>
            <div :class="usePaginator ? '' : 'mt-5'" style="float: right">
              {{ listingResults.data?.length ?? 0 }} {{ t("results") }}
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<script lang="ts">
import { globalState } from '@/states/global';
import moment from "moment";
import { computed, defineComponent, inject, reactive, ref } from 'vue'
import { useI18n } from "vue-i18n";
import ExcelExport from "@/components/ExcelExport.vue";
import ReportingPDFExportMenu from "@/components/ReportingPDFExportMenu.vue";
import { useRoute } from "vue-router";
import useLocalization from "@/hooks/localization";
import DateHelper from "@/helpers/datehelper";

import { FilterMatchMode } from "primevue/api";
import filters from '@/helpers/filters';
import Progress, { Color } from '@/components/Progress.vue';
import useProvider, { UseProvider } from '@/hooks/provider'
import ListingViewModel from '@/models/view/listingviewmodel';
import ApplicationSymbols from '@/keys'
import {ExcelColumn} from "@/helpers/excelhelper";

export default defineComponent({
  components: {
    Progress,
    ExcelExport,
    ReportingPDFExportMenu,
  },
  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const localization = useLocalization();

    const provider = inject<UseProvider>(ApplicationSymbols.useProvider)!;
    const loading = ref(true);

    const listingViewModel = ref<ListingViewModel>();
    const listing = computed(() => listingViewModel.value?.listing);

    const dataTable = ref();

    const listingResults: any = reactive({
      raw: [],
      data: [],
      dataColumns: [],
      columns: computed(() => {
        if (
          !listingResults ||
          !listingResults.data ||
          listingResults.data.length == 0
        )
          return [];

        return Object.keys(listingResults.data[0]);
      }),
      headerFilters: {},
      generateHeaderFilters: () => {
        const json: any = {};

        listingResults.columns.forEach((c: string) => {
          json[c] = {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
          };
        });

        return json;
      },
    });

    const columnsXLS = computed(
        (): ExcelColumn[] =>
            Object.keys(listingResults.columns)
                  .map((k) => ({
                    label: filters.cflt(k), source: k,
                    type: k.toLowerCase().includes('date') ? 'datetime' : 'text'
                  }))
    );

    const fetchListingResults = async (dates: any) => {
      try {
        loading.value = true;
        const tmp: any =
          (globalState.state.selected.storeIds?.length ?? 0) == 0 ? { columns: [], rows: [] } :
            await provider.listing.getDashboardListingResults(
              Number(route.params.listingId),
              globalState.state.selected.storeIds,
              dates.from,
              dates.to
            );

        listingResults.raw = tmp.rows ? JSON.parse(JSON.stringify(tmp.rows)) : [];
        listingResults.data = tmp.rows ?? [];
        listingResults.dataColumns = tmp.columns ?? [];
        listingResults.headerFilters = listingResults.generateHeaderFilters();
        loading.value = false;
        console.log("headerFilters", listingResults?.headerFilters);
      } catch (r) {
        loading.value = false;
      }
    };

    const fetchListing = async () => {
      listingViewModel.value = await provider.listing.getDashboardListing(Number(route.params.listingId));
    };

    const refresh = async (dates: any) => {
      await fetchListingResults(dates);
      await fetchListing();
    };

    const processedData = () => { return dataTable.value?.processedData ?? []; }

    const usePaginator = computed(() => (listingResults.data?.length ?? 0) > 1000)

    return {
      filters,
      dataTable,
      loading,
      processedData,
      listingResults, columnsXLS,
      refresh,
      t,
      localization,
      listing,
      DateHelper,
      Color,
      usePaginator,
      listingViewModel,
    };
  },
});
</script>
