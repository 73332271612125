import { DocumentHeader, DocumentLine } from '@/models/document/document'
import Journal from '@/models/journal'
import Entity from '@/models/entity'
import useProvider from '@/hooks/provider'

export interface IDocumentObjectBuilder<T> {
    new(): T;
}

export type IDocumentLineRepository = DocumentLineRepository<DocumentLine>;
export type IDocumentHeaderRepository = DocumentHeaderRepository<DocumentHeader>;
export type IDocumentRepository = DocumentRepository<IDocumentHeaderRepository, IDocumentLineRepository>;

export abstract class DocumentHeaderRepository<H extends DocumentHeader> {
    model: H;

    clear() {
        this._journal = undefined;
        this._entity = undefined;

        this.edited = false;
    }

    //#region edited

    private _edited: boolean = false;

    public get edited(): boolean {
        return this._edited;
    }

    public set edited(value: boolean) {
        this.setEdited(value);
    }

    public setEdited(value: boolean) {
        this._edited = value;
    }

    //#endregion

    //#region globalUserUid

    get globalUserUid(): string | undefined {
        return this.model.global_user_uid;
    }

    set globalUserUid(value: string | undefined) {
        this.model.global_user_uid = value;

        this.edited = true;
    }

    //#endregion

    //#region date

    get date(): Date | undefined {
        return this.model.date;
    }

    set date(value: Date | undefined) {
        this.model.date = value;

        this.edited = true;
    }

    //#endregion

    private _journal?: Promise<Journal>;
    private _entity?: Promise<Entity>;

    protected provider = useProvider();

    public async fetchJournal(): Promise<Journal> {
        if (!this._journal) {
            this._journal = new Promise<Journal>((resolve, reject) => {
                try {
                    resolve(this.provider.journal.getJournalFromUid(this.model.journal_uid as string));
                } catch (error) {
                    reject(error);
                }
            });

            console.log("fetchJournal", this._journal);
        }

        return this._journal;
    }

    public async fetchEntity(): Promise<Entity> {
        if (!this._entity) {
            this._entity = new Promise<Entity>((resolve, reject) => {
                try {
                    this.fetchJournal().then(journal => {
                        resolve(this.provider.entity.getEntityFromId(Number(journal.entity_id)));
                    });
                } catch (error) {
                    reject(error);
                }
            });
            console.log("fetchEntity", this._entity)
        }

        return this._entity;
    }

    constructor(headerBuilder: IDocumentObjectBuilder<H>, init?: Partial<DocumentHeader>) {
        this.model = new headerBuilder();

        Object.assign(this.model, init);
    }

    fromJson(init: any): IDocumentHeaderRepository {
        console.log("DocumentHeaderRepository.fromJson", init);

        this.model.fromJson(init);

        return this;
    }

    toJson(): any {
        return {
            ...this.model.toJson(),
        }
    }
}

export abstract class DocumentLineRepository<L extends DocumentLine> {
    model: L;

    clear() {
        this.edited = false;
    }

    //#region edited

    private _edited: boolean = false;

    public get edited(): boolean {
        return this._edited;
    }

    protected set edited(value: boolean) {
        this._edited = value;
    }

    public setEdited(value: boolean) {
        this._edited = value;
    }

    //#endregion

    constructor(lineBuilder: IDocumentObjectBuilder<L>, init?: Partial<DocumentLine>) {
        this.model = new lineBuilder();

        Object.assign(this.model, init);
    }

    fromJson(init: any): IDocumentLineRepository {
        console.log("DocumentLineRepository.fromJson");

        this.model.fromJson(init);

        return this;
    }

    toJson(): any {
        return {
            ...this.model.toJson(),
        }
    }
}

export interface IDocumentRepositoryParameters<H extends IDocumentHeaderRepository, L extends IDocumentLineRepository> {
    header?: H;
    lines?: L[];
}

export abstract class DocumentRepository<H extends IDocumentHeaderRepository, L extends IDocumentLineRepository> {
    header: H;
    lines: L[];

    clear() {
        this.header.clear();
        this.lines.forEach(l => l.clear());
        this.lines = [];

        this.edited = false;
    }

    //#region globalUserUid

    get globalUserUid(): string | undefined {
        return this.header.globalUserUid;
    }

    set globalUserUid(value: string | undefined) {
        this.header.globalUserUid = value;
    }

    //#endregion

    protected edited: boolean = false;

    get isNew(): boolean {
        return (this.header?.model?.id ?? 0) == 0;
    }

    get isEdited(): boolean {
        return this.edited || this.header.edited || this.lines.filter(l => l.edited).length > 0;
    }

    protected lineBuilder: IDocumentObjectBuilder<L>;

    constructor(
        headerBuilder: IDocumentObjectBuilder<H>,
        lineBuilder: IDocumentObjectBuilder<L>,
        header?: H,
        lines?: L[],
    ) {
        this.lineBuilder = lineBuilder;

        this.lines = lines ?? [];
        this.header = header ?? new headerBuilder();
    }

    public async fetchJournal(): Promise<Journal> {
        return this.header.fetchJournal();
    }

    public async fetchEntity(): Promise<Entity> {
        return this.header.fetchEntity();
    }

    insertLine(line: L, index: number): number {
        this.lines.splice(index, 0, line);

        this.edited = true;

        return index;
    }

    addLine(line: L): number {
        const index = this.lines.push(line);

        this.edited = true;

        return index;
    }

    removeLine(line: L): L[] {
        const deleted = this.lines.splice(this.lines.indexOf(line), 1);

        this.edited = true;

        return deleted;
    }

    // TODO export this in helper or upgrade typescript to 5.1.5
    protected findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
        let l = array.length;
        while (l--) {
            if (predicate(array[l], l, array))
                return l;
        }
        return -1;
    }

    // TODO export this in helper or upgrade typescript to 5.1.5
    protected findLast<T>(
        list: Array<T>,
        predicate: (value: T, index: number, obj: T[]) => unknown
    ): T | undefined {
        for (let index = list.length - 1; index >= 0; index--) {
            const currentValue = list[index];
            const predicateResult = predicate(currentValue, index, list);
            if (predicateResult) {
                return currentValue;
            }
        }
        return undefined;
    }

    fromJson(init: any): DocumentRepository<H, L> {
        console.log("DocumentRepository.fromJson", init);

        try {
            if (init.header) {
                this.header.fromJson(init.header);
            }

            if (init['lines']) {
                init['lines'].forEach((l: any, i: number) => {
                    const n = new this.lineBuilder();
                    const value = n.fromJson(l);

                    this.lines.push(value as L);
                });
            }

        } catch (err) { console.warn(`DocumentRepository.fromJson conversion warning: ${'/'} is missing ${err}`) }

        return this;
    }

    toJson(): any {
        return {
            'header': this.header.toJson(),
            'lines': this.lines.map(l => l.toJson())
        };
    }
}