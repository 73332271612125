import Printer from "@/models/printer";
import BaseProvider from "./baseprovider"
import { PagedParams } from '@/models/paged/pagedparams';
import PagedPrinters from '@/models/paged/pagedprinters';
import PagedItemPrinterLinks from "@/models/paged/pageditemprinterlinks";

export interface IItemPrinterLinks {
    item_uids: string[];
    printers: IItemPrinterParams[];
}

export interface IItemPrinterParams {
    printer_id: number;
    main: boolean;
    generate_one_ticket: boolean;
}

export default class PrinterProvider extends BaseProvider {
    public async fetchPrinters(): Promise<Printer[]> {
        let p: string = '';
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`printers${p}`);

        return response.data as Printer[];
    }

    public async fetchPagedPrinters(
        pagedParams: PagedParams
    ): Promise<PagedPrinters> {
        let p: string = '';
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`printers${p}`);

        return new PagedPrinters(response?.data);
    }

    public async fetchItemPrinterLinks(
        entityIds: number[],
        pagedParams: PagedParams
    ): Promise<PagedItemPrinterLinks> {
        let p: string = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            // pageNumber: 1,
            // pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`printers/itemlinks${p}`);

        return new PagedItemPrinterLinks(response?.data);
    }

    public async saveItemPrinterLinks(params: IItemPrinterLinks) {
        const response = await (await this.getApiV2()).post(`/printers/itemlinks/link`, params);

        return response.data;
    }

    public async unlinkItemPrinterLinks(params: IItemPrinterLinks) {
        const response = await (await this.getApiV2()).post(`/printers/itemlinks/unlink`, params);

        return response.data;
    }

    public async unlinkAllItemPrinterLinks(params: IItemPrinterLinks) {
        const response = await (await this.getApiV2()).post(`/printers/itemlinks/unlinkall`, params);

        return response.data;
    }

    public async getPrinterFromUid(printerUid: string): Promise<Printer> {
        const response = await (await this.getApiV2()).get(`/printers/${printerUid}`);
        return this.getPrinter(response.data);
    }

    public async savePrinter(printer: Printer): Promise<Printer> {
        console.log ('PRINTER: ', printer);

        const response = await (await this.getApiV2()).post(`/printers/`, printer.toJson());
        const savedStore = this.getPrinter(response.data);
        return savedStore;
    }

    private getPrinter(data: any): Printer {
        const s = new Printer();
        s.fromJson(data);
        return s;
    }
}