<template>
  <span class="columns-selection-wrapper" @click="openDialog">
    <slot></slot>
  </span>
  <Dialog class="columns-selection-dialog"
          :closable="true"
          :showHeader="true"
          :draggable="false"
          :visible="displayDialog" @update:visible="closeDialog"
          :maximizable="false"
          :modal="true"
          :style="{ width: '40vw' }"
          @hide="closeDialog">
    <template v-slot:header>
      <h1>{{ t('please_select_columns_to_export') }}</h1>
    </template>
    <div class="flex">
      <Button
        class="p-button p-button-rounded p-button-sm background-gradient-sunrise"
        icon="fa-regular fa-square-check"
        :label="$filters.capitalizeFirstLetter(t('generic_select_all'))"
        :disabled="!canCheckAll"
        @click="checkAll"
        :style="{ margin: '10px 5px' }"
      />
  
      <Button
        class="p-button p-button-rounded p-button-sm background-gradient-sunrise"
        icon="fa-regular fa-square"
        :label="$filters.capitalizeFirstLetter(t('generic_unselect_all'))"
        :disabled="!canUncheckAll"
        @click="uncheckAll"
        :style="{ margin: '10px 5px' }"
      />
    </div>
    <div v-show="displayDialog" class="columns-selection-list" :style="{ padding: '20px 0' }">
      <div class="flex flex-column gap-3">
        <div v-for="(column, index) of columns" :key="column.label" class="flex align-items-center columns-selection-item">
          <Checkbox v-model="selectedColumns" :inputId="`checkbox_${index}`" name="category" :value="column" />
          <label :for="`checkbox_${index}`" :style="{ padding: '0 20px' }">{{ column.label }}</label>
        </div>
      </div>
    </div>
    <div class="flex justify-content-end">
      <Button
        :class="'p-button p-button-rounded p-button-sm background-gradient-sunrise pull-right'"
        icon="fas fa-file-download"
        :label="$filters.capitalizeFirstLetter(t('download_xlsx'))"
        :disabled="!canExport"
        @click="exportExcel"
      />
    </div>
  </Dialog>
</template>

<script lang="ts">
import { ref, defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import ExcelHelper from '@/helpers/excelhelper';
import useNotification from '@/hooks/notification';
import DateHelper from '@/helpers/datehelper';
import {ColumnType} from '@/models/paged/columndefinition';
import filters from '@/helpers/filters';

export type ExcelExportColumn = {
  type: ColumnType,
  label: string,
  source: string
}

export default defineComponent({
  props: {
    columns: {
      type: Array<ExcelExportColumn>,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    fileName: {
      type: String,
      default: "excel",
    },
    sheetName: {
      type: String,
      default: "SheetName",
    },
    fileType: {
      type: String,
      default: "xlsx",
      validator: (val: any) => ["xlsx", "xls"].includes(val),
    },
    nested: {
      type: Boolean,
      default: true,
    }
  },
  setup(props, { emit }) {
      const i18n = useI18n()
      const t = i18n.t
      const notification = useNotification();
      
      const displayDialog = ref(false)
      const selectedColumns = ref([...props.columns])

      const canExport = computed(() => selectedColumns.value.length > 0)
      const canCheckAll  = computed(() => selectedColumns.value.length < props.columns.length)
      const canUncheckAll = computed(() => selectedColumns.value.length > 0)

      const checkAll = () => {
        selectedColumns.value = [...props.columns]
      }

      const uncheckAll = () => {
        selectedColumns.value = []
      }

      const sortSelection = () => {
        const selection = [...selectedColumns.value]

        selectedColumns.value = []

        props.columns.forEach((column: any) => {
          if (selection.filter((s: any) => s.source == column.source).length >= 1)
            selectedColumns.value.push(column);
        })
      }

      const openDialog = () => {
        checkAll()
        displayDialog.value = true
      }

      const closeDialog = () => {
        if (displayDialog.value) {
          displayDialog.value = false
          emit('closed')
        }
      }

      const exportExcel = () => {
        if (!canExport.value) {
          return
        }

        sortSelection()

        const locale = i18n.locale.value
        const success = ExcelHelper.exportExcel(
            selectedColumns.value as [], props.data,
            props.fileName, props.fileType, props.sheetName, props.nested, locale
        )

        if (success) {
          notification.showSuccess(t('generic_export_excel_success'))

          displayDialog.value = false
        } else {
          notification.showError(t('generic_export_excel_error'))
        }
      }

      return {
        t,
        selectedColumns,
        checkAll,
        uncheckAll,
        canExport,
        canCheckAll,
        canUncheckAll,
        exportExcel,
        displayDialog,
        openDialog,
        closeDialog
      }
  }
})
</script>

<style lang="scss">
.columns-selection-wrapper {
  margin-left: 5px;
}

.columns-selection-dialog {
  .p-dialog-header {
    h1 {
      color: white;
    }
  }
}
</style>
