import BaseProvider from "./baseprovider"

export default class ReportingProvider extends BaseProvider {
    public async getRawData(reportingId: number, storeIds: number[], fromDate: string, toDate: string): Promise<string> {
        const axiosInstance = await this.getApiV2()
        const response = await axiosInstance.get<string>(`/reportings/${reportingId}/rawdata`, {
            params: {
                storeIds: storeIds.join(','),
                fromDate: fromDate,
                toDate: toDate
            }
        })

        return response.data;
    }
}