import {
  DocumentHeaderRepository,
  DocumentLineRepository,
  DocumentRepository,
  IDocumentObjectBuilder
} from '@/models/repository/documentrepository'
import { ItemItem, ItemPrice as ItemPriceList } from '../item'
import NumberHelper from '@/helpers/numberhelper'
import useProvider from '@/hooks/provider'
import useLocalization from '@/hooks/localization'
import { v4 as uuidv4 } from 'uuid'
import { ItemDocumentHeader, ItemDocumentLine } from '../document/itemdocument'
import Stock from '../stock'

export type IItemDocumentLineRepository = ItemDocumentLineRepository<ItemDocumentLine>;
export type IItemDocumentHeaderRepository = ItemDocumentHeaderRepository<ItemDocumentHeader>;
export type IItemDocumentRepository = ItemDocumentRepository<IItemDocumentHeaderRepository, IItemDocumentLineRepository>;

export interface IAddLineParams {
    quantity?: number;
    itemPrice?: ItemPrice;
}

export class ItemDocumentLineItem {
    public id?: number;
    public uid?: string;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public price_vat_included?: number;
    public price_vat_excluded?: number;
    public purchase_price?: number;
    public vat_rate?: number;
    public sale_quantity_decimal?: number;
    public purchase_quantity_decimal?: number;
    public items?: ItemItem[];
    public stocks?: Stock[];
    public pricelists?: ItemPriceList[];

    constructor(init?: Partial<ItemDocumentLineItem>) {
        this.sale_quantity_decimal = 2;
        this.purchase_quantity_decimal = 2;

        Object.assign(this, init);
    }
}

export class ItemPrice {
    price?: number;

    public get total(): number {
        return this.price ?? 0;
    }

    constructor(init?: Partial<ItemPrice>) {
        Object.assign(this, init);
    }
}

export abstract class ItemDocumentHeaderRepository<H extends ItemDocumentHeader> extends DocumentHeaderRepository<H> {

    clear() {
        super.clear();
    }

    constructor(headerBuilder: IDocumentObjectBuilder<H>, init?: Partial<ItemDocumentHeader>) {
        super(headerBuilder, init);
    }

    abstract compute(lines: IItemDocumentLineRepository[]): void;

    fromJson(init: any): IItemDocumentHeaderRepository {
        super.fromJson(init);

        console.log("ItemDocumentHeaderRepository.fromJson");

        return this;
    }
}

export abstract class ItemDocumentLineRepository<L extends ItemDocumentLine> extends DocumentLineRepository<L> {
    private _item?: Promise<ItemDocumentLineItem>;
    private _itemItem?: Promise<ItemItem | undefined>;

    clear() {
        super.clear();

        this._item = undefined;
        this._itemItem = undefined;
    }

    //#region quantity

    get quantity(): number | undefined {
        return this.model.quantity;
    }

    set quantity(value: number | undefined) {
        if (this.model.quantity == value) return;

        this.model.quantity = value;

        this.edited = true;
    }

    //#endregion

    //#region description

    get description(): string | undefined {
        return this.model.description;
    }

    set description(value: string | undefined) {
        this.model.description = value;

        this.edited = true;
    }

    //#endregion

    //#region unitPrice

    get unitPrice(): number | undefined {
        return this.model.unit_price;
    }

    set unitPrice(value: number | undefined) {
        if (this.model.unit_price == value) return;

        this.model.unit_price = value;

        this.edited = true;
    }

    //#endregion

    get total(): number {
        return this.model.total ?? 0;
    }

    constructor(lineBuilder: IDocumentObjectBuilder<L>, init?: Partial<ItemDocumentLine>) {
        super(lineBuilder, init);
    }

    public async fetchItem(): Promise<ItemDocumentLineItem | undefined> {
        if (!this._item && this.model.item_uid) {
            const provider = useProvider();

            this._item = new Promise<ItemDocumentLineItem>((resolve, reject) => {
                try {
                    resolve(provider.item.getDocumentLineItemFromUid(this.model.item_uid as string));
                } catch (error) {
                    reject(error);
                }
            });

            console.log("fetchItem", this._item)
        }

        return this._item;
    }

    async fromItem(item: ItemDocumentLineItem, params: IAddLineParams) {
        const localization = useLocalization();

        this.fromJson({
            uid: uuidv4(),
            item_uid: item.uid,
            description: localization.localize(item) ?? undefined,
            quantity: params.quantity,
            unit_price: params.itemPrice?.price,
        });

        // hack to avoid another fetchItem (better performance)
        this._item = Promise.resolve(item);
        // this._item.stocks = await provider.item.fetchItemStocks(item.uid);
    }

    fromJson(init: any): ItemDocumentLineRepository<L> {
        super.fromJson(init);

        console.log("ItemDocumentLineRepository.fromJson");

        return this;
    }
}

export abstract class ItemDocumentRepository<H extends IItemDocumentHeaderRepository, L extends IItemDocumentLineRepository> extends DocumentRepository<H, L> {

    abstract isChildLine(line: L): boolean;
    abstract getChildLines(line: L): L[];
    abstract setParentLine(childLine: L, parentLine: L): void;
    abstract getParentLine(childLine: L): L | undefined;
    abstract getItemPriceFromItem(item: ItemDocumentLineItem): ItemPrice;
    abstract getItemPrice(item: ItemDocumentLineItem): ItemPrice;
    abstract getPriceFromPrices(priceVATIncluded: number | undefined, priceVATExcluded: number | undefined): number | undefined;
    abstract searchItems(search: string): Promise<any[]>;
    abstract computeLine(line: L): void;

    clear() {
        super.clear();
    }

    get totalAmountLines(): number {
        return NumberHelper.round(this.lines.reduce((sum, current) => sum + current.total, 0), 2);
    }

    constructor(
        headerBuilder: IDocumentObjectBuilder<H>,
        lineBuilder: IDocumentObjectBuilder<L>,
        header?: H,
        lines?: L[],
    ) {
        super(headerBuilder, lineBuilder, header, lines);
    }

    public async fetchLineItemItem(line: L): Promise<ItemItem | undefined> {
        if (!line['_itemItem'] && line.model.item_uid) {
            const parentLine = this.getParentLine(line);

            line['_itemItem'] = new Promise<ItemItem | undefined>((resolve, reject) => {
                parentLine?.fetchItem().then(item => {
                    resolve(item?.items?.find(i => i.to_item_uid === line.model.item_uid));
                });
            });

            console.log("fetchLineItemItem", line['_itemItem'])
        }

        return line['_itemItem'];
    }

    setLineQuantity(line: L, quantity: number | undefined) {
        line.quantity = quantity;

        const childLines = this.getChildLines(line);

        childLines.forEach(async childLine => {
            const newValue = Number(line.quantity) * Number((await this.fetchLineItemItem(childLine))?.quantity);

            this.setLineQuantity(childLine, newValue);
        });

        this.computeLineAndHeader(line);
    }

    setLineUnitPrice(line: L, unitPrice: number | undefined) {
        line.unitPrice = unitPrice;

        this.computeLineAndHeader(line);
    }

    setLineItemPrice(line: L, itemPrice: ItemPrice) {
        this.setLineUnitPrice(line, Number(itemPrice.price ?? 0));
    }

    protected compute() {
        this.lines.forEach(line => {
            this.computeLine(line);
        });

        this.computeHeader();
    }

    protected computeHeader() {
        this.header.compute(this.lines);
    }

    protected computeLineAndHeader(line: L) {
        this.computeLine(line);

        this.computeHeader();
    }

    newItemPrice(): ItemPrice {
        return new ItemPrice({
            price: 0,
        });
    }

    async newLineFromItem(item: ItemDocumentLineItem, params: IAddLineParams): Promise<L> {
        if (!item.uid) throw Error("Invalid item");

        const repositoryLine = new this.lineBuilder();

        repositoryLine.fromItem(item, params);

        return repositoryLine;
    }

    insertCommentLine(description: string, index: number): L {
        const repositoryLine = new this.lineBuilder();

        repositoryLine.model.uid = uuidv4();
        repositoryLine.model.description = description;

        this.insertLine(repositoryLine, index);

        return repositoryLine;
    }

    addDescriptionLine(description: string): L {
        return this.insertCommentLine(description, this.lines.length);
    }

    async insertItemLine(itemUid: string, index: number, params?: IAddLineParams): Promise<L | null> {
        const provider = useProvider();

        const item = await provider.item.getDocumentLineItemFromUid(itemUid);

        if (item.uid) {
            console.log("fetch item", item)

            const useItemPrice = params?.itemPrice ?? this.getItemPrice(item);
            const useQuantity = params?.quantity ?? 1;

            const repositoryLine = await this.newLineFromItem(item, {
                quantity: useQuantity,
                itemPrice: useItemPrice,
            });

            this.setLineItemPrice(repositoryLine, useItemPrice);

            super.insertLine(repositoryLine, index);

            this.computeLineAndHeader(repositoryLine);

            // yield linked items
            const journal = await this.fetchJournal();

            const children = item.items?.filter(x => journal.type == 1 ? x.sale : x.purchase);

            await Promise.all(children!.map(async x => {
                const childLine = await this.addItemLine(x.to_item_uid, {
                    quantity: x.quantity * Number(repositoryLine.quantity),
                    itemPrice: x.retrieve_price ? undefined : this.newItemPrice(),
                });

                if (childLine) {
                    this.setParentLine(childLine, repositoryLine);

                    childLine['_itemItem'] = Promise.resolve(x); // hack to avoid future call
                }
            }));
            // children.forEach(async x => {
            //     const childLine = await this.addItemLine(x.to_item_uid, {
            //         quantity: x.quantity * Number(repositoryLine.quantity),
            //         itemPrice: x.retrieve_price ? undefined : this.newItemPrice(),
            //     });

            //     if (childLine) {
            //         this.setParentLine(childLine, repositoryLine);

            //         childLine['_itemItem'] = Promise.resolve(x); // hack to avoid future call
            //     }
            // });

            return repositoryLine;
        }

        return null;
    }

    async addItemLine(itemUid: string, params?: IAddLineParams): Promise<L | null> {
        return this.insertItemLine(itemUid, this.lines.length, params);
    }

    addLine(line: L): number {
        const index = super.addLine(line);

        this.computeLineAndHeader(line);

        return index;
    }

    getLastParentLine(): L | undefined {
        return this.findLast<L>(this.lines, (line) => !this.isChildLine(line));
    }

    removeLastParentLine(): L[] {
        const lastLine = this.getLastParentLine();

        if (!lastLine) return [];

        return this.removeLine(lastLine);
    }

    removeLine(line: L): L[] {
        const childLines = this.getChildLines(line);

        const deleted = super.removeLine(line);

        childLines.forEach(childLine => {
            deleted.concat(this.removeLine(childLine));
        });

        this.computeHeader();

        return deleted;
    }

    fromJson(init: any): ItemDocumentRepository<H, L> {
        super.fromJson(init);

        console.log("ItemDocumentRepository.fromJson", init);

        try {
            // nothing to do
        } catch (err) { console.warn(`ItemDocumentRepository.fromJson conversion warning: ${'/'} is missing ${err}`) }

        return this;
    }
}