import GlobalUser from '@/models/globaluser'
import PagedGlobalUsers from '@/models/paged/pagedglobalusers'
import { PagedParams } from '@/models/paged/pagedparams'
import BaseProvider from '@/providers/baseprovider'

export interface fetchGlobalUsersParams {
    entityIds?: number[];
    isAdmin?: boolean;
    system?: boolean;
    template?: boolean;
    name?: string;
}
export interface fetchPagedGlobalUsersParams {
    pagedParams: PagedParams;
    entityIds?: number[];
    isAdmin?: boolean;
    system?: boolean;
    template?: boolean;
}
export default class GlobalUserProvider extends BaseProvider {
    /**
     * fetchGlobalUser
     */
    public async fetchGlobalUser(): Promise<GlobalUser> {
        const response = await (await this.getApiV2()).get('/globalusers/me');

        return Promise.resolve(new GlobalUser(response.data));
    }

    public async fetchGlobalUsersFromOrganizationSystem(search: string): Promise<any[]> {
        const response = await (await this.getApiV2()).get(`/globalusers/fromorganizationsystem?search=${search}`);

        return response.data;
    }

    public async resetPassword(email: string): Promise<any> {
        const response = await (await this.getPublicApiV2()).post('/globalusers/resetpassword', { email: email, });

        return Promise.resolve(new GlobalUser(response.data));
    }

    public async changePasswordWithToken(token: string, password: string): Promise<any> {
        const response = await (await this.getPublicApiV2()).post('/globalusers/changepasswordwithtoken', {
            password: password,
            token: token,
        });

        return Promise.resolve(new GlobalUser(response.data));
    }

    public async validatePasswordToken(token: string): Promise<any> {
        const response = await (await this.getPublicApiV2()).post('/GlobalUsers/ValidateToken', { token: token });

        return Promise.resolve(response.data);
    }

    public async fetchGlobalUsers(params: fetchGlobalUsersParams): Promise<GlobalUser[]> {
        let p: string = '';

        p = p.addParams("entityIds", params.entityIds, true);
        p = p.addParams("isAdmin", [params.isAdmin]);
        p = p.addParams("system", [params.system]);
        p = p.addParams("template", [params.template]);
        p = p.addParams('name', [params.name]);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/globalusers${p}`);

        return Promise.resolve(response.data);
    }

    public async fetchPagedGlobalUsers(params: fetchPagedGlobalUsersParams): Promise<PagedGlobalUsers> {
        let p: string = '';
        p = p.addPagedParams(params.pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc',
        });

        // If more than 400 entities, surely super orga => do not use to filter.
        if ((params.entityIds?.length ?? 0) < 400)
            p = p.addParams("entityIds", params.entityIds!, true);

        p = p.addParams("isAdmin", [params.isAdmin]);
        p = p.addParams("system", [params.system]);
        p = p.addParams("template", [params.template]);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/globalusers${p}`);

        const pagedItems = new PagedGlobalUsers(response.data);

        return pagedItems;
    }

    public async getGlobalUserFromUid(globalUserUid: string): Promise<GlobalUser> {
        const response = await (await this.getApiV2()).get(`/globalusers/${globalUserUid}`);

        const globalUsers = this.getGlobalUser(response.data);

        return globalUsers;
    }

    public async getGlobalUserFromId(globalUserId: number): Promise<GlobalUser> {
        const response = await (await this.getApiV2()).get(`/globalusers/${globalUserId}`);

        const globalUsers = this.getGlobalUser(response.data);

        return globalUsers;
    }

    public async fetchGlobalUserWithDuplicateCode(posId: number, entityIds: number[]): Promise<any[]> {
        let params: string = ''
        params = params.addParams("entityIds", entityIds, true)
        params = params !== '' ? `?${params}` : ''

        const response = await (await this.getApiV2()).get(`GlobalUsers/POS/DuplicateCodes/${posId}${params}`)

        return response.data
    }

    public async saveGlobalUser(globalUser: GlobalUser): Promise<GlobalUser> {
        const response = await (await this.getApiV2()).post(`/globalusers/`, globalUser);

        const savedGlobalUser = this.getGlobalUser(response.data);

        return savedGlobalUser;
    }

    private getGlobalUser(data: any): GlobalUser {
        const r = new GlobalUser();
        r.fromJson(data);
        return r;
    }
}
