import moment from 'moment-timezone'

export default class DateHelper {

  public static formatDate = (date: Date | string, format = 'DD/MM/YYYY HH[h]mm'): string | null => {
    const valueDateTestFractions = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS', true)

    const valueDateTest2Fractions = moment(date, 'YYYY-MM-DDTHH:mm:ss.SS', true)

    const valueDateTest1Fraction = moment(date, 'YYYY-MM-DDTHH:mm:ss.S', true)

    const valueDateTest = moment(date, 'YYYY-MM-DDTHH:mm:ss', true)

    if (valueDateTestFractions.isValid()) {
      return valueDateTestFractions.format(format)
    } else if (valueDateTest2Fractions.isValid()) {
      return valueDateTest2Fractions.format(format)
    } else if (valueDateTest1Fraction.isValid()) {
      return valueDateTest1Fraction.format(format)
    } else if (valueDateTest.isValid()) {
      return valueDateTest.format(format)
    }

    return null
  }

  public static toCESTDate = (d: Date) => {
    const momentDate = moment(d)
    const cestDate = momentDate.tz('Europe/Berlin')
    return cestDate.format('YYYY-MM-DD HH:mm:ss')
  }

  public static fromCESTDate = (s: string) => {
    const momentDate = moment.tz(s, 'YYYY-MM-DD HH:mm:ss', 'Europe/Berlin')
    return momentDate.toDate()
  }

  public static fromTime = (time: string, date: Date = new Date()): Date => {
    const [ hours, minutes ] = time ? time.split(':') : [ '00', '00' ]

    return moment(date).hours(parseInt(hours)).minutes(parseInt(minutes)).toDate()
  }

  public static addTimeToDate = (date: Date, time: string): Date => {
    const [ hours, minutes ] = time ? time.split(':') : [ '00', '00' ]

    return moment(date).add(hours, 'hours').add(minutes, 'minutes').toDate()
  }

  public static formatDayOfWeekAsCSharp = (date: Date): number => {
    return (date.getDay() + 6) % 7 + 1
  }
}