import { TimeslotSchedulerEvent } from '@/components/Timeslot/Scheduler/TimeslotScheduler.vue'
import remapRuleToSchedulerEvent, { TimeslotDayRule } from '@/models/timeslot/TimeslotDayRule'
import { useTimeslotProvider } from '@/providers/TimeslotProvider'
import useTimeslotState from '@/states/timeslots/details'
import { TimeslotDetailFormValues } from '@/types/detail-form/TimeslotDetailFormValues'
import { defineStore } from 'pinia'
import { v4 } from 'uuid'

export type TimeslotFormState = {
  isDirty: boolean,
  isSaving: boolean,
  value: TimeslotDetailFormValues
}

export const timeslotDayRuleDefaultState = (): TimeslotDayRule => ({
  uid: v4(),
  active: false,
  maximum_units: null,
  maximum_orders: null,
  from_time: '08:00',
  to_time: '20:00',
  channels: {
    cnc: {
      delivery: true,
      eatin: true,
      takeaway: true,
    },
    kiosk: {
      eatin: true,
      takeaway: true,
    },
    pos: {
      delivery: true,
      eatin: true,
      takeaway: true,
    }
  }
})

export const timeslotFormDefaultState = (): TimeslotFormState => ({
  isSaving: false,
  isDirty: false,
  value: {
    uid: '',
    store_uid: '',
    duration: 0,
    maximum_orders: null,
    maximum_units: null,
    monday: {
      active: false,
      rules: [ timeslotDayRuleDefaultState() ]
    },
    tuesday: {
      active: false,
      rules: [ timeslotDayRuleDefaultState() ]
    },
    wednesday: {
      active: false,
      rules: [ timeslotDayRuleDefaultState() ]
    },
    thursday: {
      active: false,
      rules: [ timeslotDayRuleDefaultState() ]
    },
    friday: {
      active: false,
      rules: [ timeslotDayRuleDefaultState() ]
    },
    saturday: {
      active: false,
      rules: [ timeslotDayRuleDefaultState() ]
    },
    sunday: {
      active: false,
      rules: [ timeslotDayRuleDefaultState() ]
    },
    exceptions: {
      production: [],
      closing: []
    },
    holidays: []
  }
})

const useTimeslotFormState = defineStore('timeslotForm', {
  state: timeslotFormDefaultState,
  getters: {
    getTimeslotSchedulerEvents(state: TimeslotFormState): Array<TimeslotSchedulerEvent> {
      const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = state.value

      return [
        ...monday.rules
          .filter((rule: TimeslotDayRule) => rule.from_time !== rule.to_time)
          .map((rule: TimeslotDayRule) => remapRuleToSchedulerEvent(rule, 'monday')),
        ...tuesday.rules
          .filter((rule: TimeslotDayRule) => rule.from_time !== rule.to_time)
          .map((rule: TimeslotDayRule) => remapRuleToSchedulerEvent(rule, 'tuesday')),
        ...wednesday.rules
          .filter((rule: TimeslotDayRule) => rule.from_time !== rule.to_time)
          .map((rule: TimeslotDayRule) => remapRuleToSchedulerEvent(rule, 'wednesday')),
        ...thursday.rules
          .filter((rule: TimeslotDayRule) => rule.from_time !== rule.to_time)
          .map((rule: TimeslotDayRule) => remapRuleToSchedulerEvent(rule, 'thursday')),
        ...friday.rules
          .filter((rule: TimeslotDayRule) => rule.from_time !== rule.to_time)
          .map((rule: TimeslotDayRule) => remapRuleToSchedulerEvent(rule, 'friday')),
        ...saturday.rules
          .filter((rule: TimeslotDayRule) => rule.from_time !== rule.to_time)
          .map((rule: TimeslotDayRule) => remapRuleToSchedulerEvent(rule, 'saturday')),
        ...sunday.rules
          .filter((rule: TimeslotDayRule) => rule.from_time !== rule.to_time)
          .map((rule: TimeslotDayRule) => remapRuleToSchedulerEvent(rule, 'sunday'))
      ].filter((slot: TimeslotSchedulerEvent) => slot.startDate && slot.endDate)
    }
  },
  actions: {
    async update() {
      const timeslotState = useTimeslotState()
      const provider = useTimeslotProvider()

      this.isSaving = true

      try {
        await provider.update(this.value.uid, {
          ...this.value,
          store_uid: timeslotState.selectedStore!.uid
        })
      } finally {
        this.isSaving = false
      }
    },
    async save() {
      const timeslotState = useTimeslotState()
      const provider = useTimeslotProvider()

      this.isSaving = true

      try {
        await provider.create({
          ...this.value,
          store_uid: timeslotState.selectedStore!.uid
        })
      } finally {
        this.isSaving = false
      }
    },
    setFormValues(value: TimeslotDetailFormValues) {
      this.value = value
    }
  }
})

export default useTimeslotFormState
