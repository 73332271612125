<template>
  <div class="bo-selector bo--selector-store">
    <i class="fas fa-building" />
    <MultiSelect
        aria-autocomplete="none"
        class="bo-fullwidth" style="z-index: 10000"
        :options="globalState.getters.availableEntities"
        optionLabel="name"
        placeholder="Entity"
        optionValue="id"
        v-model="componentEntities"
        @hide="onHide"
    >
    </MultiSelect>
  </div>
</template>
<script>
import {defineComponent, ref, watch} from "vue";
import {globalState} from '@/states/global';
import {deepCompare} from "@/helpers/deepCopy";

export default defineComponent({
  setup() {
    const componentEntities = ref();

    watch(() => globalState.state.selected.entityIds, (v) => {
      componentEntities.value = v;
    })

    const onHide = () => {
      if (!deepCompare([componentEntities.value, globalState.state.selected.entityIds]))
        globalState.dispatch('setEntityIds', componentEntities.value);
    }

    return {
      globalState,
      componentEntities, onHide
    }
  }
})
</script>