<template>
  <div class="overlay flex align-items-center justify-content-center" v-if="globalState.state.pending">
    <ProgressSpinner />
  </div>
</template>
<style scoped>
.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 65535;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
<script lang="ts">
import {defineComponent} from 'vue';
import { globalState } from '@/states/global';

export default defineComponent({
  setup() {
    return {
      globalState
    }
  }
})
</script>