<template>
  <div v-bind="$attrs" class="max-height-card">
    <Card class="card-gold overflow-y-hidden pt-3">
      <template #header>
        <div class="grid text-center">
          <div class="col-5 text-left">
            <h1>
              <i class="fas fa-sitemap color-gold"></i>&nbsp;&nbsp;
              <span>{{ filters.cflt('dashboard_itemlevels_title').toUpperCase() }}</span>
            </h1>
          </div>
          <div class="col-2 text-center">
            <!-- <Button :disabled="syncing24h || loading"
                    class="p-button p-button-rounded p-button-sm background-gradient-sunrise"
                    :icon="'fas fa-rotate' + (syncing24h ? ' fa-spin' : '')" :label="$filters.capitalizeFirstLetter(t('sync_24h'))"
                    @click="sync24h()"/> -->
          </div>
          <div class="col-5 text-right">
            <!-- Sobs... Dates are provided as dd/mm/yyyy strings from backend, not date format... -->
            <ExcelExport v-if="!errorMessage && ((Data?.length ?? 0) > 0)"
                         :data="Data"
                         :columns="columnsXLS"
                         file-name="Export dashboard" file-type="xlsx" sheet-name="Data">
              <Button :class="'p-button p-button-rounded p-button-sm background-gradient-sunrise'"
                      icon="fas fa-file-download" :label="filters.cflt('download_xlsx')" />
            </ExcelExport>
          </div>
        </div>
      </template>
      <template #content>
        <Progress class="flex justify-content-center" v-if="loading" />
        <div v-else>
          <div v-if="errorMessage" class="text-center my-4">
            {{ filters.cflt('report_error') }}
          </div>
          <div v-if="(Data?.length ?? 0) == 0" class="text-center my-4"
            v-html="filters.cflt('report_empty')" />
          <div class="grid">
            <div class="col-12 p-fluid">
              <DataTable
                ref="Report"
                :value="Data"
                v-model:filters="Filters"
                class="p-datatable-sm itemlevels-datatable background-white"
                responsiveLayout="scroll"
                filterDisplay="row"
                selectionMode="single">
                <Column v-for="column in ((Data?.length ?? 0) > 0 ? Object.keys(Data[0]) : [])" :key="column"
                  :field="column" :filterField="column"
                  :style="column == 'Store' ? 'min-width: 12rem' : ''">
                  <template #header>
                    <div class="w-full"
                      :style="{ 'text-align': (typeof Data[0][column] === 'number') ? 'center' : 'left' }">
                      {{ filters.cflt(column.toLowerCase()) }}
                    </div>
                  </template>
                  <template #body="slotProps">
                    <div :style="{ 'text-align': ['number'].includes(typeof slotProps.data[column]) ? 'center' : 'left' }"
                         translate="no"
                         v-html="['number'].includes(typeof slotProps.data[column]) && ![].includes(column) ?
                            $filters.formatDecimal(slotProps.data[column], '€') :
                            slotProps.data[column].replaceAll('-', '/')" />
                  </template>
                  <template v-if="Object.keys(Filters).includes(column)" #filter="{ filterCallback }">
                    <InputText autocomplete="off" spellcheck="false"
                               type="text" v-model="Filters[column].value" @keydown.enter="filterCallback()"
                               class="p-column-filter" />
                  </template>
                </Column>
                <ColumnGroup type="footer">
                  <Row v-if="showFooter()">
                    <Column></Column>
                    <Column>
                      <template #footer>
                        <div class="my-3 mr-4 flex align-items-center justify-content-end">
                          {{ filters.cflt('totals') }}:
                        </div>
                      </template>
                    </Column>
                    <Column v-for="column in ((Data?.length ?? 0) > 0 ? Object.keys(Data[0]) : []).slice(2)"
                      :key="column">
                      <template #footer>
                        <div class="w-full" translate="no"
                          :style="{ 'text-align': (typeof Data[0][column] === 'number') ? 'center' : 'left' }"
                          v-html="total(column)">
                        </div>
                      </template>
                    </Column>
                  </Row>
                </ColumnGroup>
              </DataTable>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<script lang="ts">
import {computed, defineComponent, inject, ref} from 'vue'
import { globalState } from '@/states/global';
import ExcelExport from "@/components/ExcelExport.vue";
import ReportHelper from "@/helpers/reporthelper";
import { FilterMatchMode } from "primevue/api";
import Progress from "@/components/Progress.vue";
import useProvider, { UseProvider } from '@/hooks/provider'
import filters from '@/helpers/filters';
import ApplicationSymbols from '@/keys'
import {ExcelColumn} from "@/helpers/excelhelper";

export default defineComponent({
  components: {
    ExcelExport,
    Progress,
  },
  setup(props) {
    const errorMessage = ref();
    const Data = ref();
    const Report = ref();
    const Filters = ref({
      Store: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const provider = inject<UseProvider>(ApplicationSymbols.useProvider)!;

    const loading = ref(true);

    const fetchData = async (dates: any) => {
      try {
        loading.value = true;

        const tmp = (globalState.state.selected.storeIds?.length ?? 0) == 0 ? [] :
          await provider.dash.getCAItemLevels(globalState.state.selected.storeIds, dates.from, dates.to);

        // tmp = ObjectsHelper.removeZeroSumColumns(tmp);

        errorMessage.value = null;

        Data.value = tmp;
        loading.value = false;
      } catch (r) {
        errorMessage.value = r;
        Data.value = null;
        loading.value = false;
      }
    };

    const columnsXLS = computed(
        (): ExcelColumn[] =>
            Object.keys(Data.value[0])
                  .map((k) => ({ source: k, type: ['Date', 'Store'].includes(k) ? 'text' : 'currency'}))
    );

    const syncing24h = ref(false);
    const sync24h = async () => {
      syncing24h.value = true;
      await provider.materialize.sync24h('salesDetails');
      syncing24h.value = false;
      await refresh({ from: globalState.state.period!.start, to: globalState.state.period!.end });
    }

    const refresh = fetchData;
    const total = (column: string) => ReportHelper.total(Report, column);
    const showFooter = () => ReportHelper.showFooter(Report);

    return {
      filters,
      loading,
      errorMessage,
      Data, columnsXLS,
      Report,
      Filters,
      refresh,
      total, showFooter,
      sync24h, syncing24h
    }
  }
})
</script>