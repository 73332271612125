<template>
  <div v-bind="$attrs" class="max-height-card">
    <Card class="card-bluesky pt-3">
      <template #header>
        <div class="grid text-center">
          <div class="col-5 text-left">
            <h1>
              <i class="fas fa-users color-bluesky"></i>&nbsp;&nbsp;
              <span>{{ t('dashboard_caglobalusers_title').toUpperCase() }}</span>
            </h1>
          </div>
          <div class="col-2 text-center">
            <!-- <Button :disabled="syncing24h || loading"
                    class="p-button p-button-rounded p-button-sm background-gradient-sunrise"
                    :icon="'fas fa-rotate' + (syncing24h ? ' fa-spin' : '')" :label="$filters.capitalizeFirstLetter(t('sync_24h'))"
                    @click="sync24h()"/> -->
          </div>
          <div class="col-5 text-right">
            <ExcelExport v-if="!errorMessage && (dataXLS.length > 0)"
                         :data="dataXLS"
                         :columns="columnsXLS"
                         file-name="Export dashboard" file-type="xlsx" sheet-name="Data">
              <Button :class="'p-button p-button-rounded p-button-sm background-gradient-sunrise'"
                      icon="fas fa-file-download" :label="$filters.capitalizeFirstLetter(t('download_xlsx'))" />
            </ExcelExport>
          </div>
        </div>
      </template>
      <template #content>
        <Progress class="flex justify-content-center" v-if="loading" />
        <div v-else>
          <div v-if="errorMessage" class="text-center my-4" v-html="$filters.capitalizeFirstLetter(t('report_error'))" />
          <div v-else-if="dataReport.length == 0" class="text-center my-4"
            v-html="$filters.capitalizeFirstLetter(t('report_empty'))" />
          <div v-else class="grid">
            <div class="col-12 p-fluid">
              <DataTable
                ref="Report"
                :value="dataReport"
                v-model:filters="Filters"
                class="p-datatable-sm caglobalusers-datatable background-white"
                responsiveLayout="scroll"
                filterDisplay="row"
                selectionMode="single">
                <Column v-for="column in Object.keys(dataReport[0])"
                        :key="column" :field="column" :filterField="column"
                  :style="column == 'Store' ? 'min-width: 12rem' : ''">
                  <template #header>
                    <div class="w-full"
                         :class="{
                            'text-center': ['number'].includes(typeof dataReport[0][column]),
                            'text-left': ['string'].includes(typeof dataReport[0][column])
                         }"
                         v-html="$filters.cflt(column)" />
                  </template>
                  <template #body="slotProps">
                    <div translate="no"
                         class="w-full"
                         :class="{
                            'text-center': ['number'].includes(typeof slotProps.data[column]),
                            'text-left': ['string'].includes(typeof slotProps.data[column])
                         }">
                      <div v-html="(typeof slotProps.data[column]) === 'number' ? $filters.formatDecimal(slotProps.data[column], '€') :
                                   slotProps.data[column]" />
                    </div>
                  </template>
                  <template v-if="Object.keys(Filters).includes(column)" #filter="{ filterCallback }">
                    <InputText autocomplete="off" spellcheck="false"
                               type="text" v-model="Filters[column].value" @keydown.enter="filterCallback()"
                               class="p-column-filter" />
                  </template>
                </Column>
                <ColumnGroup type="footer">
                  <Row v-if="showFooter()">
                    <Column v-for="column in Object.keys(dataReport[0])" :key="column">
                      <template #footer>
                        <div class="w-full mt-3" translate="no"
                             :class="{
                                'text-center': ['number'].includes(typeof dataReport[0][column]),
                                'text-left': ['string'].includes(typeof dataReport[0][column])
                             }"
                             v-html="total(column)">
                        </div>
                      </template>
                    </Column>
                  </Row>
                </ColumnGroup>
              </DataTable>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<style lang="scss">
@import "@/scss/_variables.scss";

.caglobalusers-datatable td:not(:nth-child(2), :nth-child(1)) {
  @extend %cell-format;
}
</style>
<script lang="ts">
import { computed, defineComponent, inject, ref } from 'vue'
import { globalState } from '@/states/global'
import { useI18n } from 'vue-i18n'
import DashProvider from '@/providers/dashprovider'
import ExcelExport from '@/components/ExcelExport.vue'
import ReportHelper from '@/helpers/reporthelper'
import { FilterMatchMode } from 'primevue/api'
import Progress from '@/components/Progress.vue'
import useLocalization from '@/hooks/localization'
import DateHelper from '@/helpers/datehelper'
import { UseProvider } from '@/hooks/provider'
import ObjectsHelper from '@/helpers/objectshelper'
import ApplicationSymbols from '@/keys'
import {ExcelColumn} from "@/helpers/excelhelper";

export default defineComponent({
  components: {
    ExcelExport,
    Progress,
  },
  setup(props) {
    const i18n = useI18n();
    const t = i18n.t;
    const localization = useLocalization();

    const errorMessage = ref();
    const Data = ref();
    const Report = ref();
    const Filters = ref({
      [t('store_name')]: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
    });

    const dashProvider = new DashProvider();
    const provider = inject<UseProvider>(ApplicationSymbols.useProvider)!;

    const loading = ref(true);

    const dataReport = computed(() => {
      const r: any[] = []; // js => ts quick fix
      if ((Data.value?.length ?? 0) == 0) return r;

      // js => ts quick fix
      Data.value.map((row: any) => {
        ['global_user_id', 'store_id']
            .forEach(k => delete row[k]);
        row['group_date'] = DateHelper.formatDate(row['group_date'], 'DD/MM/YYYY');

        // Cannot convert now because footer total needs column as number
        //row['total'] = filters.formatDecimal(row['total'], '€');

        row = ObjectsHelper.sortColumns(row, ['group_date', 'store_name']);

        // Translate column names
        const translated = Object.keys(row).reduce((acc, key) => ({...acc, [t(key)]: row[key]}), {})

        r.push(translated);
      });

      return r;
    })

    const dataXLS = computed(() => {
      const r: object[] = []; // js => ts quick fix
      if ((Data.value?.length ?? 0) == 0) return r;
      Data.value.forEach((row: { [key: string]: any }) => { // Puke...
        ['global_user_id', 'store_id']
            .forEach(k => delete row[k]);

        // because ExcelExport requires translated property keys...
        const n = {};
        // @ts-ignore - js => ts quick fix
        Object.keys(row).forEach(k => n[t(k)] = row[k]);
        r.push(n);
      });
      return r;
    });

    const columnsXLS = computed(
        (): ExcelColumn[] =>
            Object.keys(dataXLS.value[0])
                  .map((k) => ({ source: k, type: ['Date', 'Store'].includes(k) ? 'text' : 'currency'}))
    );

    const fetchData = async (dates: any) => {
      try {
        loading.value = true;

        const tmp = (globalState.state.selected.storeIds?.length ?? 0) == 0 ? [] :
          await dashProvider.getCAGlobalUsers(globalState.state.selected.storeIds, dates.from, dates.to);

        // tmp = ObjectsHelper.removeZeroSumColumns(tmp);

        errorMessage.value = null;
        Data.value = tmp;
        loading.value = false;
      } catch (r) {
        errorMessage.value = r;
        Data.value = null;
        loading.value = false;
      }
    };
    const refresh = fetchData;

    const showFooter = () => ReportHelper.showFooter(Report);
    const total = (field: string) => ReportHelper.total(Report, field);

    const syncing24h = ref(false);
    const sync24h = async () => {
      syncing24h.value = true;
      await provider.materialize.sync24h('caGlobalUsers');
      syncing24h.value = false;
      await refresh({ from: globalState.state.period!.start, to: globalState.state.period!.end });
    }

    return {
      i18n, t, loading,
      errorMessage,
      Data, Report,
      dataReport, dataXLS, columnsXLS,
      Filters, refresh,
      ReportHelper,
      sync24h, syncing24h,
      total, showFooter
    }
  }
})
</script>