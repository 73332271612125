<template>
  <div class="flex flex-row">
    <div v-for="(a, index) in actions" :key="index">
      <ToggleTooltip ref="toggleTooltips"
                     :text="getTooltip(a)"
                     :force="a.highlight && (rowIndex == 0)"
                     :hidden="toggleTooltips?.some((t, i) => t.displayed && i != index)"
                     @click="click(a, index)"
                     :visible="isVisible(a)"
                     :disabled="isDisabled(a)">
        <Button class="ml-1 table-button"
                style="transition-duration: 0s !important;"
                :icon="a.icon"
                :class="a.class"
                :disabled="isDisabled(a)"
                :style="{ visibility: isVisible(a) ? 'visible' : 'hidden' }"
        />
      </ToggleTooltip>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { IAction } from '@/models/common/action';
import ToggleTooltip from '@/components/Tools/ToggleTooltip/ToggleTooltip.vue';

export default defineComponent({
  components: {
    ToggleTooltip
  },
  props: {
    actions: {
      type: Object as PropType<IAction[]>,
      default: () => new Array<IAction>(),
    },
    row: { type: Object },
    rowIndex: { type: Number }
  },
  emits: ["action"],
  setup(props, { emit }) {
    const toggleTooltips = ref();

    const isVisible = (action: IAction) => {
      if (typeof action.visible === 'boolean') return action.visible;
      if (typeof action.visible === 'function') return action.visible(props.row) as boolean;
      return true;
    };

    const isDisabled = (action: IAction) => {
      if (typeof action.disabled === 'boolean') return action.disabled;
      if (typeof action.disabled === 'function') return action.disabled(props.row) as boolean;
      return false;
    };

    const getTooltip = (action: IAction) => {
      if (typeof action.tooltip === 'string') return action.tooltip;
      if (typeof action.tooltip === 'function') return action.tooltip(props.row) as string;
      return undefined;
    }

    const click = (action: IAction, index: number) => {
      if (!isDisabled(action)) emit('action', index);
    }

    return {
      isVisible,
      isDisabled,
      getTooltip,
      click,
      toggleTooltips
    }
  },
})
</script>