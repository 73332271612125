import BaseProvider from "../baseprovider"
import {PagedParams} from '@/models/paged/pagedparams';
import PagedActiveLicenses from '@/models/paged/licenses/pagedactivelicenses';
import PagedDemoLicenses from '@/models/paged/licenses/pageddemolicences';
import PagedBlockedLicenses from '@/models/paged/licenses/pagedblockedlicenses';
import PagedInactivesLicenses from '@/models/paged/licenses/pagedinactiveslicenses';
import PagedUnbindLicenses from '@/models/paged/licenses/pagedunbindlicenses';
import PagedEmailHistory from "@/models/paged/licenses/pagedemailhistory";
import PagedSMSHistory from "@/models/paged/licenses/pagedsmshistory";
import PagedActiveContracts from "@/models/paged/licenses/pagedactivecontracts";

export type LicenseReport =
    'ActiveLicenses' | 'BlockedLicenses' | 'DemoLicenses' | 'InactivesLicenses' |
    'UnbindLicenses' | 'EmailHistory' | 'SMSHistory' | 'ActiveContracts' ;

export default class PartnersLicenseProvider extends BaseProvider {

    private async fetchPagedLicenses(report: LicenseReport, entityIds?: number[], pagedParams?: PagedParams){
        let params: string = '';
        params = params.addParams("entityIds", entityIds, true);
        params = params.addPagedParams(pagedParams);
        params = params !== '' ? `?${params}` : '';

        const response = await (await this.getApiV2()).get(`partners/licenses/${report}${params}`);

        return response.data;
    }

    public fetchDashboard = async () => {
        const response = await (await this.getApiV2()).get('partners/licenses/dashboard')

        return response.data
    }

    public fetchPagedActiveLicenses = (entityIds?: number[], pagedParams?: PagedParams) =>
        this.fetchPagedLicenses('ActiveLicenses', entityIds, pagedParams) as Promise<PagedActiveLicenses>;
    public fetchPagedBlockedLicenses = (entityIds?: number[], pagedParams?: PagedParams): Promise<PagedBlockedLicenses> =>
        this.fetchPagedLicenses('BlockedLicenses', entityIds, pagedParams) as Promise<PagedBlockedLicenses>;
    public fetchPagedDemoLicenses = (entityIds?: number[], pagedParams?: PagedParams): Promise<PagedDemoLicenses> =>
        this.fetchPagedLicenses('DemoLicenses', entityIds, pagedParams) as Promise<PagedDemoLicenses>;
    public fetchPagedEmailHistory =  (entityIds?: number[], pagedParams?: PagedParams): Promise<PagedEmailHistory> =>
        this.fetchPagedLicenses('EmailHistory', entityIds, pagedParams) as Promise<PagedEmailHistory>;
    public fetchPagedInactivesLicenses = (entityIds?: number[], pagedParams?: PagedParams): Promise<PagedInactivesLicenses> =>
        this.fetchPagedLicenses('InactivesLicenses', entityIds, pagedParams) as Promise<PagedInactivesLicenses>;
    public fetchPagedUnbindLicenses = (entityIds?: number[], pagedParams?: PagedParams): Promise<PagedUnbindLicenses> =>
        this.fetchPagedLicenses('UnbindLicenses', entityIds, pagedParams) as Promise<PagedUnbindLicenses>;
    public fetchPagedSMSHistory = (entityIds?: number[], pagedParams?: PagedParams): Promise<PagedSMSHistory> =>
        this.fetchPagedLicenses('SMSHistory', entityIds, pagedParams) as Promise<PagedSMSHistory>;
    public fetchPagedActiveContracts = (entityIds?: number[], pagedParams?: PagedParams): Promise<PagedActiveContracts> =>
        this.fetchPagedLicenses('ActiveContracts', entityIds, pagedParams) as Promise<PagedActiveContracts>;

    public async unbindLicense(params: { license_code: string, device_id: string }) {
        const response = await (await this.getApiV2()).post(`partners/licenses/unbind`, params);

        return response.data;
    }

    public async disableDemoMode(params: { license_code: string }) {
        const response = await (await this.getApiV2()).post(`partners/licenses/disabledemomode`, params);

        return response.data;
    }

    public async fetchPagedStatsStores(entityIds: number[], pagedParams?: PagedParams){
        let params: string = '';
        params = params.addParams("entityIds", entityIds, true);
        params = params.addPagedParams(pagedParams);
        params = params !== '' ? `?${params}` : '';

        const response = await (await this.getApiV2()).get(`partners/licenses/stats/stores${params}`);

        return response.data;
    }

    public async fetchStatsPos(entityIds: number[]){
        let params: string = '';
        params = params.addParams("entityIds", entityIds, true);
        params = params !== '' ? `?${params}` : '';

        const response = await (await this.getApiV2()).get(`partners/licenses/stats/pos${params}`);

        return response.data;
    }

    /**
     * Fetch a paged resultset of licenses linked to specific entities
     * @param entityIds The entities which the licenses must be linked to
     * @param pagedParams The pagination parameters
     * @returns A paged resultset of licenses

    public async fetchPagedLicenses(
            entityIds?: number[],
            pagedParams?: PagedParams,
            blocked?: boolean,
            contract?: boolean,
            bound?: boolean): Promise<PagedLicenses> {
        let params: String = '';
        if (typeof entityIds !== 'undefined') params = params.addParams("entityIds", entityIds, true);
        if (typeof blocked !== 'undefined') params = params.addParams("blocked", [blocked], false);
        if (typeof contract !== 'undefined') params = params.addParams("contract", [contract], false);
        if (typeof bound !== 'undefined') params = params.addParams("bound", [bound], false);
        params = params.addPagedParams(pagedParams);
        params = params !== '' ? `?${params}` : '';

        const response = await (await this.getApiV2()).get(`Licenses${params}`);

        return new PagedLicenses(response.data);
    }

    public async unbindLicense(params: { license_code: string, device_id: string }) {
        const response = await (await this.getApiV2()).post(`/licenses/unbind`, params);

        return response.data;
    }

    /* public async fetchPagedLicensesCustomers(entityIds: number[], pagedParams?: PagedParams): Promise<PagedLicensesCustomers> {
        let params: String = '';
        params = params.addParams("entityIds", entityIds, true);
        params = params.addPagedParams(pagedParams);
        params = params !== '' ? `?${params}` : '';

        const response = await (await this.getApiV2()).get(`Licenses/Customers${params}`);

        return new PagedLicenses(response.data);
    } */
}