import { authState } from '@/states/auth'
import axios, { AxiosInstance } from 'axios'
import qs from 'qs'
import Config from './config'
import oauth from 'axios-oauth-client'

interface IAuthWithPublicVersionOptions {
    language?: string
    baseUrl?: string
}

export default class Api {
    static async publicWithVersion(version: number): Promise<AxiosInstance> {
        return axios.create({
            baseURL: Config.baseAPI(version) + '/api/v' + version,
            headers: { "Content-type": "application/json" }
        });
    }

    static async getOwnerCredentials(login: string, password: string,): Promise<any> {
        return oauth.client(axios.create(), {
            url: Config.baseAPI() + '/connect/token',
            grant_type: 'password',
            client_id: 'web.api',
            client_secret: 'web.api.secret',
            username: login,
            password: password,
            scope: "web.api offline_access",
        })();
    }

    static async getLoginAsOwnerCredentials(accessToken: string, globalUserUid: string, organizationUid: string): Promise<any> {
        return axios
            .create()({
                baseURL: Config.baseAPI() + '/connect/token',
                method: "post",
                data: qs.stringify(
                    {
                        grant_type: 'loginas',
                        client_id: 'web.api',
                        client_secret: 'web.api.secret',
                        access_token: accessToken,
                        global_user_uid: globalUserUid,
                        organization_uid: organizationUid,
                    }
                ),
            });
    }

    static async refreshToken(refreshToken: string): Promise<any> {
        return axios
            .create()({
                baseURL: Config.baseAPI() + '/connect/token',
                method: "post",
                data: qs.stringify(
                    {
                        grant_type: 'refresh_token',
                        client_id: 'web.api',
                        client_secret: 'web.api.secret',
                        refresh_token: refreshToken,
                    }
                ),
            });
    }

    static async auth(accessToken: string): Promise<AxiosInstance> {
        return axios.create({
            baseURL: Config.baseAPI(),
            headers: accessToken ? { Authorization: 'Bearer ' + accessToken, "Content-type": "application/json" } : { "Content-type": "application/json" }
        });
    }

    static async authWithVersion(version: number, accessToken: string): Promise<AxiosInstance> {
        const headers = { "Content-type": "application/json" };
        const axiosInstance = axios.create({
            baseURL: Config.baseAPI(version) + '/api/v' + version,
            headers: accessToken ?
                { ...headers, Authorization: 'Bearer ' + accessToken } :
                { ...headers }
        });

        // Response interceptor for API calls
        axiosInstance.interceptors.response.use((response: any) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                await authState.dispatch('refreshToken');

                axios.defaults.headers.common['Authorization'] = 'Bearer ' + authState.state.accessToken;
                originalRequest.headers['Authorization'] = "Bearer " + authState.state.accessToken;

                return axiosInstance(originalRequest);
            }
            return Promise.reject(error);
        });

        return axiosInstance;
    }

    static async authWithPublicVersion(version: number, accessToken: string, options?: IAuthWithPublicVersionOptions): Promise<AxiosInstance> {

        const headers: any = { "Content-type": "application/json" };

        if (accessToken)
            headers["Authorization"] = 'Bearer ' + accessToken;

        if (options?.language)
            headers["Accept-Language"] = options.language;

        return axios.create({
            baseURL: `${options?.baseUrl ? options.baseUrl : Config.baseAPI(version)}/api/v${version}/public`,
            headers: headers,
        });
    }
}