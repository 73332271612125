import { PrecisionType } from '@/plugins/vue-ganttastic/types'

export abstract class VueGanttasticConstants {
  static COLUMN_WIDTH: number = 50
  static ROW_HEIGHT: number = 60
  static CURRENT_TIME_CONTAINER_WIDTH: number = 50
  static TOOLTIP_FORMATS: Record<PrecisionType, string> = {
    minute: 'mm',
    hour: "HH:mm",
    day: "DD. MMM HH:mm",
    date: "DD. MMMM YYYY",
    month: "DD. MMMM YYYY",
    week: "DD. MMMM YYYY (WW)"
  }
  static DEFAULT_DATE_FORMAT = "YYYY-MM-DD HH:mm"
  static DEFAULT_DOT_COLOR = 'cadetblue'
}

export default VueGanttasticConstants