import PriceList from "@/models/pricelist";
import BaseProvider from "./baseprovider"
import PagedPriceLists from "@/models/paged/pagedpricelists";
import { PagedParams } from "@/models/paged/pagedparams";

export default class PriceListProvider extends BaseProvider {
    /**
     * Fetch a resultset of price lists linked to specific entities
     * @param entityIds The entities which the price lists must be linked to
     * @returns A resultset of price lists
     */
    public async fetchPriceLists(payload?: {entityIds?: number[], storeIds?: number[]}): Promise<PriceList[]> {
        let params: string = '';

        params = params.addParams("entityIds", payload?.entityIds, true);
        params = params.addParams("storeIds", payload?.storeIds, true);

        params = params !== '' ? `?${params}` : '';

        const response = await (await this.getApiV2()).get(`/PriceLists${params}`);

        const priceLists = response.data.map((priceList: any) => new PriceList(priceList));
        
        return priceLists;
    }

    /**
     * Fetch a paged resultset of price lists linked to specific entities
     * @param entityIds The entities which the price lists must be linked to
     * @param pagedParams The pagination parameters
     * @returns A paged resultset of price lists
     */
    public async fetchPagedPriceLists(entityIds: number[], pagedParams?: PagedParams): Promise<PagedPriceLists> {
        let params: string = '';
        params = params.addParams("entityIds", entityIds, true);
        params = params.addPagedParams(pagedParams);
        params = params !== '' ? `?${params}` : '';

        const response = await (await this.getApiV2()).get(`PriceLists${params}`);

        return new PagedPriceLists(response.data);
    }

    /**
     * Fetch the Price List through API based on its uid
     * @param priceListUid The Price List uid
     * @returns The Price List corresponding the uid
     */
    public async getPriceListFromUid(priceListUid: string): Promise<PriceList> {
        const response = await (await this.getApiV2()).get(`/PriceLists/${priceListUid}`);

        return new PriceList(response.data);
    }

    /**
     * Save the Price List using a POST request through the API
     * @param priceList The Price List to save
     * @returns The saved Price List
     */
    public async savePriceList(priceList: PriceList): Promise<PriceList> {
        const response = await (await this.getApiV2()).post(`/PriceLists/`, priceList);
        const savedPriceList = new PriceList(response.data);

        return savedPriceList;
    }
}