import { StoreTransferHeader, StoreTransferLine } from '@/models/document/storetransferdocument'
import { IDocumentObjectBuilder, IDocumentRepositoryParameters } from '@/models/repository/documentrepository'
import Item from '../item'
import NumberHelper from '@/helpers/numberhelper'
import useProvider from '@/hooks/provider'
import {
  IItemDocumentLineRepository,
  ItemDocumentHeaderRepository,
  ItemDocumentLineRepository,
  ItemDocumentRepository,
  ItemPrice
} from './itemdocumentrepository'

export type IStoreTransferLineRepository = StoreTransferLineRepository<StoreTransferLine>;
export type IStoreTransferHeaderRepository = StoreTransferHeaderRepository<StoreTransferHeader>;
export type IStoreTransferRepository = StoreTransferRepository<IStoreTransferHeaderRepository, IStoreTransferLineRepository>;

export abstract class StoreTransferHeaderRepository<H extends StoreTransferHeader> extends ItemDocumentHeaderRepository<H> {

    clear() {
        super.clear();
    }

    //#region reference

    get reference(): string | undefined {
        return this.model.reference;
    }

    set reference(value: string | undefined) {
        this.model.reference = value;

        this.edited = true;
    }

    //#endregion

    //#region fromStoreId

    get fromStoreId(): number | undefined {
        return this.model.from_store_id;
    }

    set fromStoreId(value: number | undefined) {
        this.model.from_store_id = value;

        this.edited = true;
    }

    //#endregion

    //#region toStoreId

    get toStoreId(): number | undefined {
        return this.model.to_store_id;
    }

    set toStoreId(value: number | undefined) {
        this.model.to_store_id = value;

        this.edited = true;
    }

    //#endregion

    constructor(headerBuilder: IDocumentObjectBuilder<H>, init?: Partial<StoreTransferHeader>) {
        super(headerBuilder, init);
    }


    compute(lines: IItemDocumentLineRepository[]): void {
        let total: number = 0;
        const roundedDocument = 2;

        lines.forEach(line => {
            if (!line.model.item_uid) return;

            total += line.model.total ?? 0;
        });

        this.model.total = NumberHelper.round(total, roundedDocument);
    }
}

export abstract class StoreTransferLineRepository<L extends StoreTransferLine> extends ItemDocumentLineRepository<L> {
    get total(): number {
        return this.model.total ?? 0;
    }

    constructor(lineBuilder: IDocumentObjectBuilder<L>, init?: Partial<StoreTransferLine>) {
        super(lineBuilder, init);
    }

    compute() {
        if (!this.model.item_uid)
            return;

        const round: number = 2;

        this.model.total = NumberHelper.round((this.model.unit_price ?? 0) * (this.model.quantity ?? 0), round);
    }
}

export interface IStoreTransferRepositoryParameters<H extends IStoreTransferHeaderRepository, L extends IStoreTransferLineRepository> extends IDocumentRepositoryParameters<H, L> {

}

export abstract class StoreTransferRepository<H extends IStoreTransferHeaderRepository, L extends IStoreTransferLineRepository> extends ItemDocumentRepository<H, L> {

    constructor(
        headerBuilder: IDocumentObjectBuilder<H>,
        lineBuilder: IDocumentObjectBuilder<L>,
        header?: H,
        lines?: L[],
    ) {
        super(headerBuilder, lineBuilder, header, lines);
    }

    computeLine(line: L): void {
        line.compute();
    }

    getItemPriceFromItem(item: Item): ItemPrice {
        return new ItemPrice({ price: item.price_vat_excluded ?? 0 });
    }

    getParentLine(childLine: L): L | undefined {
        return this.lines.find(l => l.model.uid == childLine.model.parent_store_transfer_line_uid);
    }

    setParentLine(childLine: L, parentLine: L): void {
        childLine.model.parent_store_transfer_line_uid = parentLine.model.uid;
    }

    getChildLines(line: L): L[] {
        return this.lines.filter(l => l.model.parent_store_transfer_line_uid == line.model.uid);
    }

    isChildLine(line: L): boolean {
        return line.model.parent_store_transfer_line_uid != undefined;
    }

    public getItemPrice(item: Item): ItemPrice {
        return this.getItemPriceFromItem(item);
    }

    public getPriceFromPrices(priceVATIncluded: number | undefined, priceVATExcluded: number | undefined): number | undefined {
        return Number(priceVATExcluded);
    }

    async searchItems(search: string): Promise<any[]> {
        const entity = await this.fetchEntity();
        const provider = useProvider();

        return await provider.sale.searchItems(
            [entity.id ?? 0],
            search.trim(),
            this.header.fromStoreId ?? 0,
            ""
        );
    }
}