// Type definition for path array
type PathArray = (string | number)[];
type Predicate<T> = (value: T, indexOrKey: number | string, collection: T[] | Record<string, T>) => boolean;

abstract class LikeLodashHelper {
  static get<T, U>(
    object: T,
    path: string | PathArray,
    defaultValue?: U
  ): U | undefined {
    // Convert path to array if it's a string
    const pathArray: PathArray = Array.isArray(path)
      ? path
      : path
        .replace(/\[(\w+)\]/g, '.$1') // Handle array indices in path strings
        .replace(/^\./, '') // Remove leading dot
        .split('.')
        .filter(key => key.length)

    // Use reduce to traverse the object
    const result = pathArray.reduce<any>((acc, key) => {
      if (acc !== null && acc !== undefined && (typeof acc === 'object' || Array.isArray(acc))) {
        return acc[key]
      }
      return undefined
    }, object as any)

    // Return the result or defaultValue if result is undefined
    return result === undefined ? defaultValue : result
  }

  // Simplified version of Lodash's isEqual method
  static isEqual(value: any, other: any): boolean {
    // Check if they are the same object
    if (value === other) {
      return true;
    }

    // Check if either is null or not an "object" (objects and arrays are "objects" in JS)
    if (value == null || other == null || typeof value !== 'object' || typeof other !== 'object') {
      return false;
    }

    // Get keys of both values
    const valueKeys = Object.keys(value);
    const otherKeys = Object.keys(other);

    // Check if the number of keys is different
    if (valueKeys.length !== otherKeys.length) {
      return false;
    }

    // Recursively check each key
    for (const key of valueKeys) {
      // Check if the key exists in both objects and values are deeply equal
      if (!other.hasOwnProperty(key) || !LikeLodashHelper.isEqual(value[key], other[key])) {
        return false;
      }
    }

    // If all checks pass, the objects are equal
    return true;
  }

  static groupBy(array: Array<any>, iteratee: any) {
    if (!Array.isArray(array)) return {};  // Handle non-array input gracefully

    const result: any = {};

    for (const item of array) {
      let key;

      // Handle different types of iteratees
      if (typeof iteratee === 'function') {
        key = iteratee(item);
      } else if (typeof iteratee === 'string') {
        // Support deep property access
        key = iteratee.split('.').reduce((acc, part) => acc && acc[part], item);
      }

      // Convert undefined keys to a string
      key = key === undefined ? 'undefined' : String(key);

      // Group by the computed key
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
    }

    return result;
  }


  static some<T>(collection: T[] | Record<string, T> | null | undefined, predicate: Predicate<T>): boolean {
    // If the collection is null or undefined, return false
    if (collection == null) {
      return false;
    }

    // If the collection is an array, loop over the elements
    if (Array.isArray(collection)) {
      for (let i = 0; i < collection.length; i++) {
        if (predicate(collection[i], i, collection)) {
          return true;
        }
      }
    } else {
      // If the collection is an object, loop over the object's keys
      for (const key in collection) {
        if (Object.prototype.hasOwnProperty.call(collection, key)) {
          // Assert that collection[key] is of type T
          if (predicate(collection[key], key, collection)) {
            return true;
          }
        }
      }
    }

    // If no element satisfies the predicate, return false
    return false;
  }
}

export default LikeLodashHelper
