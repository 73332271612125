<template>
  <div class="field flex flex-row align-items-center">
    <div v-if="label">{{ label }}</div>
    <Dropdown
        :ref="dropdown"
        autocomplete="off"
        :id="id" :class="$props.class"
        @blur="$emit('blur', $event)"
        v-model="componentModel"
        :options="items"
        :optionLabel="optionLabel"
        :optionValue="optionValue"
        :filter="filter && !editable"
        @filter="(v) => $emit('filter', v)"
        :placeholder="placeholder"
        :showClear="showClear"
        :loading="loading"
        :disabled="disabled"
        :editable="editable"
        @input="$emit('input', $event)"
        v-bind="$attrs" @onchange="onchange">
      <!--<template #item="slotProps">
        <div class="item-template">
          <i v-if="optionIcon" :class="slotProps.option[optionIcon]" class="dropdown-icon"/>
          <span class="item-name">{{ slotProps.option.name }}</span>
        </div>
      </template> -->
      <template
          v-for="(_, slotName, index) in $slots"
          v-slot:[slotName]="scope"
          v-bind:key="index">
        <i
            v-if="scope.optionIcon && scope.option[slotName].optionIcon"
            :class="scope.option[slotName][scope.optionIcon]"
            class="dropdown-icon" />
        <slot :name="slotName" v-bind="scope"></slot>
      </template>
    </Dropdown>
  </div>
</template>
<style scoped>
.field {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
<script lang="ts">
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  emits: [ 'update:modelValue', 'input', 'filter', 'blur' ],
  props: {
    label: String,
    id: String,
    modelValue: [ Number, String ],
    optionLabel: [ String, Function ],
    optionValue: String,
    placeholder: { type: String, default: '' },
    showClear: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    filter: { type: Boolean, default: true },
    editable: { type: Boolean, default: false },
    items: Array,
    loading: { type: Boolean, default: false },
    class: String,
    onchange: Function,
    optionIcon: String
  },
  setup(props, { emit }) {
    const componentModel = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const dropdown = ref()
    const show = () => dropdown.value.show()

    return {
      componentModel,
      dropdown,
      show
    }
  }
})
</script>
