<template>
  <Dialog :showHeader="false" :modal="true" :visible="isVisible" @hide="cancel">
    <div class="mt-4 flex flex-column justify-content-center">
      <div class="flex align-items-center justify-content-between">
        <span>{{ currentMessage }}</span>
        <div class="flex justify-content-end">
          <Button class="p-button-sm p-button-text" icon="fas fa-times" @click="cancel" />
        </div>
      </div>

      <InputText v-model="componentValue" @keydown.enter="save" />

      <Button class="mt-2 flex justify-content-center" @click="save">
        {{ filters.cflt('save') }}
      </Button>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, ref, watch, defineExpose } from "vue";
import filters from '@/helpers/filters';

export interface IAskTextOptions {
  accepted: Function;
  rejected: Function;
}

export default defineComponent({
  components: {

  },
  props: {
  },
  emits: [],
  setup(props, { emit, expose }) {
    const isVisible = ref<boolean>(false);
    const currentOptions = ref<IAskTextOptions>({} as IAskTextOptions);
    const currentMessage = ref<string>();

    const componentValue = ref<string>("");

    const show = () => {
      isVisible.value = true;
    };

    const save = () => {

      currentOptions.value?.accepted(componentValue.value);

      isVisible.value = false;
    };

    const cancel = () => {
      currentOptions.value?.rejected();

      isVisible.value = false;
    };

    expose({
      show: (message: string, value: string,
        options?: IAskTextOptions
      ) => {
        currentMessage.value = message;

        Object.assign(currentOptions.value, options);

        componentValue.value = value;

        show();
      },
    });

    return {
      componentValue,
      filters,
      isVisible,
      show,
      save,
      cancel,
      currentMessage,
    };
  },
});
</script>
