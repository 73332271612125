import AuthProvider from '@/providers/authprovider'
import DashProvider from '@/providers/dashprovider'
import GlobalModuleProvider from '@/providers/globalmoduleprovider'
import GlobalUserProvider from '@/providers/globaluserprovider'
import ItemLevelProvider from '@/providers/itemlevelprovider'
import ItemProvider from '@/providers/itemprovider'
import MailTemplateProvider from '@/providers/mailtemplateprovider'
import MatrixProvider from '@/providers/matrixprovider'
import OrganizationProvider from '@/providers/organizationprovider'
import StoreProvider from '@/providers/storeprovider'
import SaleProvider from '@/providers/saleprovider'
import PropositionProvider from '@/providers/propositionprovider'
import CustomerProvider from '@/providers/customerprovider'
import EntityProvider from '@/providers/entityprovider'
import SupplierProvider from '@/providers/supplierprovider'
import PurchaseProvider from '@/providers/purchaseprovider'
import POSProvider from '@/providers/posprovider'
import PriceListProvider from '@/providers/pricelistprovider'
import PrinterProvider from '@/providers/printerprovider'
import CurrencyProvider from '@/providers/currencyprovider'
import DiscountReasonProvider from '@/providers/discountreasonprovider'
import StoreTransferFlowProvider from '@/providers/storetransferflowprovider'
import JournalProvider from '@/providers/journalprovider'
import CountryProvider from '@/providers/countryprovider'
import StoreTransferDirectProvider from '@/providers/storetransferdirectprovider'
import StorePrinterProvider from '@/providers/storeprinterprovider'
import ZoneProvider from '@/providers/zoneprovider'
import PrinterModelProvider from '@/providers/printermodelprovider'
import TaskTypeProvider from '@/providers/tasktypeprovider'
import ItemCategoryProvider from '@/providers/itemcategoryprovider'
import PermissionProvider from '@/providers/permissionprovider'
import SignalRProvider from '@/providers/signalrprovider'
import RankProvider from '@/providers/rankprovider'
import AccountingProvider from '@/providers/accountingprovider'
import MeasureUnitProvider from '@/providers/measureunitprovider'
import MaterializeProvider from '@/providers/materializeprovider'
import ExternalDataImportProvider from '@/providers/externaldataimportcontroller'
import MatrixTemplateProvider from '@/providers/matrixtemplateprovider'
import ErrorProvider from '@/providers/errorprovider'
import PartnersLicenseProvider from '@/providers/partners/licenseprovider'
import AddressProvider from '@/providers/addressprovider'
import DatawarehouseProvider from '@/providers/datawarehouseprovider'
import ListingProvider from '@/providers/listingprovider'
import AllergenProvider from '@/providers/allergenprovider'
import DocumentStatusProvider from '@/providers/documentstatusprovider'
import DutyProvider from '@/providers/dutyprovider'
import IntrastatProvider from '@/providers/intrastatprovider'
import PaymentTermProvider from '@/providers/paymenttermprovider'
import PermissionKeysProvider from '@/providers/permissionkeyprovider'
import TagProvider from '@/providers/tagprovider'
import TitleProvider from '@/providers/titleprovider'
import VATProvider from '@/providers/vatprovider'
import ZipCodeProvider from '@/providers/zipcodeprovider'
import ViesProvider from '@/providers/external/viesprovider'

export type UseProvider = {
  accounting: AccountingProvider;
  address: AddressProvider;
  allergen: AllergenProvider;
  auth: AuthProvider;
  country: CountryProvider;
  currency: CurrencyProvider;
  customer: CustomerProvider;
  dash: DashProvider;
  discountReason: DiscountReasonProvider;
  documentStatus: DocumentStatusProvider;
  duty: DutyProvider;
  entity: EntityProvider;
  error: ErrorProvider;
  externalDataImport: ExternalDataImportProvider;
  globalModule: GlobalModuleProvider;
  globalUser: GlobalUserProvider;
  intrastat: IntrastatProvider;
  item: ItemProvider;
  itemCategory: ItemCategoryProvider;
  itemLevel: ItemLevelProvider;
  journal: JournalProvider;
  license: PartnersLicenseProvider;
  materialize: MaterializeProvider;
  matrix: MatrixProvider;
  matrixTemplate: MatrixTemplateProvider;
  measureUnit: MeasureUnitProvider;
  organization: OrganizationProvider;
  paymentTerm: PaymentTermProvider;
  permission: PermissionProvider;
  permissionKey: PermissionKeysProvider;
  pos: POSProvider;
  priceList: PriceListProvider;
  printer: PrinterProvider;
  printerModel: PrinterModelProvider;
  proposition: PropositionProvider;
  purchase: PurchaseProvider;
  rank: RankProvider;
  sale: SaleProvider;
  signalr: SignalRProvider;
  store: StoreProvider;
  storePrinter: StorePrinterProvider;
  storeTransferFlow: StoreTransferFlowProvider;
  storeTransferDirect: StoreTransferDirectProvider;
  supplier: SupplierProvider;
  tag: TagProvider;
  taskType: TaskTypeProvider;
  title: TitleProvider;
  zone: ZoneProvider;
  datawarehouse: DatawarehouseProvider;
  mailTemplate: MailTemplateProvider;
  listing: ListingProvider;
  vat: VATProvider;
  vies: ViesProvider;
  zipCode: ZipCodeProvider;
}

let cache: UseProvider | null = null

export default function useProvider(): UseProvider {
  if (!cache) {
    cache = {
      accounting: new AccountingProvider(),
      address: new AddressProvider(),
      auth: new AuthProvider(),
      country: new CountryProvider(),
      currency: new CurrencyProvider(),
      customer: new CustomerProvider(),
      dash: new DashProvider(),
      discountReason: new DiscountReasonProvider(),
      entity: new EntityProvider(),
      error: new ErrorProvider(),
      externalDataImport: new ExternalDataImportProvider(),
      globalModule: new GlobalModuleProvider(),
      globalUser: new GlobalUserProvider(),
      item: new ItemProvider(),
      itemCategory: new ItemCategoryProvider(),
      itemLevel: new ItemLevelProvider(),
      journal: new JournalProvider(),
      license: new PartnersLicenseProvider(),
      materialize: new MaterializeProvider(),
      matrix: new MatrixProvider(),
      matrixTemplate: new MatrixTemplateProvider(),
      measureUnit: new MeasureUnitProvider(),
      organization: new OrganizationProvider(),
      permission: new PermissionProvider(),
      pos: new POSProvider(),
      priceList: new PriceListProvider(),
      printer: new PrinterProvider(),
      printerModel: new PrinterModelProvider(),
      proposition: new PropositionProvider(),
      purchase: new PurchaseProvider(),
      rank: new RankProvider(),
      sale: new SaleProvider(),
      signalr: new SignalRProvider(),
      store: new StoreProvider(),
      storePrinter: new StorePrinterProvider(),
      storeTransferFlow: new StoreTransferFlowProvider(),
      storeTransferDirect: new StoreTransferDirectProvider(),
      supplier: new SupplierProvider(),
      taskType: new TaskTypeProvider(),
      zone: new ZoneProvider(),
      datawarehouse: new DatawarehouseProvider(),
      mailTemplate: new MailTemplateProvider(),
      listing: new ListingProvider(),
      allergen: new AllergenProvider(),
      documentStatus: new DocumentStatusProvider(),
      duty: new DutyProvider(),
      intrastat: new IntrastatProvider(),
      paymentTerm: new PaymentTermProvider(),
      permissionKey: new PermissionKeysProvider(),
      tag: new TagProvider(),
      title: new TitleProvider(),
      vat: new VATProvider(),
      zipCode: new ZipCodeProvider(),
      vies: new ViesProvider()
    }
  }

  return cache
}