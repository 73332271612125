<template>
  <div class="g-grid-container"
       @mouseleave="handleGridLeave"
       :style="{
          '--gantt-grid-column-width': `${VueGanttasticConstants.COLUMN_WIDTH}px`,
          '--gantt-grid-column-height': `${VueGanttasticConstants.ROW_HEIGHT}px`,
          '--gantt-grid-columns': timeaxisUnits.lowerUnits.length,
          '--gantt-grid-rows': rows,
        }">
    <template v-for='(row, index) in rows' :key='row'>
      <div v-for="(lowerUnit, column) in timeaxisUnits.lowerUnits"
           :key="column"
           :class="[
               'g-grid-item',
               `row-${row}`,
               row === rows ? 'last-row' : 'not-last-row',
               timeaxisUnits.lowerUnits.length === column + 1 ? 'last-column' : 'not-last-column',
               column === 0 ? 'first-column' : 'not-first-column'
               ]"
           @mouseenter="handleCellHover(row, column)"
           @mouseleave="handleCellLeave"
           @click='() => handleOnCellClick(lowerUnit, columnLabelsRow[index])'>
        <!-- Fake event span that reveals with delay -->
        <div
            :class="['fake-event', { 'revealed': column === hoveredCell.column && row === hoveredCell.row }]">
          <slot name='new-event-template'/>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import provideConfig from '@/plugins/vue-ganttastic/provider/provideConfig'
import useTimeaxisUnits from '@/plugins/vue-ganttastic/composables/useTimeaxisUnits'
import VueGanttasticConstants from '@/plugins/vue-ganttastic/vue-ganttastic-constants'
import { GGanttTimeAxisUnit } from '@/plugins/vue-ganttastic/types'
import { BookingReservationEventRow } from '@/Booking/models/Reservations/BookingReservationEventRow'

defineProps<{
  columnLabelsRow: BookingReservationEventRow[],
  highlightedUnits?: number[],
  rows: number,
}>()

const emit = defineEmits<{
  (e: 'cell-clicked', value: any): void
}>()

const { colors } = provideConfig()
const { timeaxisUnits } = useTimeaxisUnits()

// Reactive state to track hovered cell and reveal state
const hoveredCell = ref<{ row: number | null, column: number | null }>({ row: null, column: null })

// Handle cell hover with delay to trigger the reveal effect
const handleCellHover = (row: number, column: number) => {
  hoveredCell.value = { row, column }
}

// Handle when mouse leaves the cell
const handleCellLeave = () => {
  hoveredCell.value = { row: null, column: null } // Clear hoveredCell when leaving the grid
}

// Handle when mouse leaves the entire grid container
const handleGridLeave = () => {
  hoveredCell.value = { row: null, column: null } // Clear hoveredCell when leaving the grid
}

// Handle cell click event
const handleOnCellClick = (lowerUnit: GGanttTimeAxisUnit, columnLabelRelated: BookingReservationEventRow) => {
  emit('cell-clicked', {
    timeAxisUnit: lowerUnit,
    data: columnLabelRelated
  })
}
</script>

<style>
.g-grid-container {
  --gantt-grid-column-width: 0;
  --gantt-grid-column-height: 0;
  --gantt-grid-columns: 0;
  --gantt-grid-rows: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(var(--gantt-grid-columns), var(--gantt-grid-column-width));
  grid-template-rows: repeat(var(--gantt-grid-rows), var(--gantt-grid-column-height));
  transition: all ease-in-out .250ms;

  .g-grid-item {
    position: relative;
    border-style: solid;
    border-color: #eaeaea;
    border-top-width: 1px;
    border-right-width: 1px;
    cursor: pointer;

    &:hover {
      background-color: #eaeaea;
    }

    &.last-row {
      border-bottom-width: 1px;
    }

    div.fake-event {
      position: absolute;
      top: calc(50% - 25px);
      left: 0;
      width: 0; /* Start with 0 width */
      height: 50px;
      z-index: 10;
      transition: width .250s ease;
      transition-delay: 0ms;
      overflow: hidden;

      &.revealed {
        width: var(--new-event-width);
        transition-delay: 500ms;
      }
    }
  }
}

</style>
