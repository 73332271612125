import { computed } from 'vue'
import provideConfig from '@/plugins/vue-ganttastic/provider/provideConfig'
import useDayjsHelper from '@/plugins/vue-ganttastic/composables/useDayjsHelper'
import VueGanttasticConstants from '@/plugins/vue-ganttastic/vue-ganttastic-constants'
import { Dayjs, ManipulateType } from 'dayjs'

type PrecisionType = 'minute' | 'hour' | 'date' | 'day' | 'week' | 'month' | 'year';

export default function useTimeaxisUnits() {
  const { precision } = provideConfig();
  const { chartStartDayjs, chartEndDayjs } = useDayjsHelper();

  const displayFormats: Record<PrecisionType, string> = {
    minute: 'HH:mm',
    hour: 'HH',
    date: 'DD.MMM',
    day: 'DD.MMM',
    week: 'WW',
    month: 'MMMM YYYY',
    year: 'YYYY',
  };

  const timeaxisUnits = computed(() => {
    const result = [];
    const start: Dayjs = chartStartDayjs.value;
    const end: Dayjs = chartEndDayjs.value;
    const granularity: PrecisionType = precision.value;
    let current = start;

    // Determine the increment amount based on precision
    const incrementAmount = granularity === 'minute' ? 15 : 1;

    // Loop until we reach or surpass the end date
    while (current.isSameOrBefore(end)) {
      result.push({
        label: current.format(displayFormats[granularity]),
        value: String(current.toISOString()),
        date: current.toDate(),
        width: `${VueGanttasticConstants.COLUMN_WIDTH}px`
      });

      // Increment based on precision and increment amount
      current = current.add(incrementAmount, granularity as ManipulateType);
    }

    console.log('lowerUnits', result);

    return { lowerUnits: result };
  });

  return {
    timeaxisUnits
  };
}
