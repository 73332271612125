import * as XLSX from 'xlsx';

export interface SpecialColumn {
  baseFieldName: string
  exportFieldName: string
  withLocale: boolean
}

export type ExcelColumn = {
  label?: string;
  source: string;
  destination?: string;
  dataFormat?: (value: any) => any;
  type?: 'currency' | 'number' | 'text' | 'date' | 'datetime';
}

export default class ExcelHelper {
  private static specialColumns: SpecialColumn[] = [
    { baseFieldName: 'names_html', exportFieldName: 'name_$LOCALE', withLocale: true },
    { baseFieldName: 'full_names_html', exportFieldName: 'full_name_$LOCALE', withLocale: true },
  ]

  /**
   * Export the given [data] by taking only [columns]
   */
  public static exportExcel = (columns: ExcelColumn[], data: object[], fileName: string = 'excel', fileType: string = 'xlsx', sheetName: string = 'SheetName', nested: boolean = true, locale: string = 'fr'): boolean => {

    if (columns.length === 0) {
      console.warn("There is no columns provided for the export!")
      return false
    }

    if (data.length === 0) {
      console.warn("There is no data provided for the export!")
      return false
    }

    if (!["xlsx", "xls"].includes(fileType)) {
      console.warn("The specified file type is not valid!")
      return false
    }

    try {
      const createXLSLFormatObj = []
      const newXlsHeader: any = []

      columns.map((column: any) => newXlsHeader.push(column.label ?? (typeof column === 'string' ? column : column.source ?? 'Unknown') ))

      createXLSLFormatObj.push(newXlsHeader)

      data.map((row: any) => {
        const innerRowData: any = []

        columns.map((column: any) => {
          let cellValue;
          let cellFormat;

          if (column.destination ?? column.source) {
            let fieldName = column.destination ?? column.source;

            ExcelHelper.specialColumns.forEach(specialColumn => {
              if (fieldName == specialColumn.baseFieldName) {
                fieldName = specialColumn.withLocale
                  ? specialColumn.exportFieldName.replace('$LOCALE', locale)
                  : specialColumn.exportFieldName

                return
              }
            })

            const fieldValue = (nested && fieldName.split(".").length > 1)
              ? ExcelHelper.getNestedValue(row, fieldName)
              : row[fieldName];

            cellValue = (column.dataFormat && typeof column.dataFormat === "function")
              ? column.dataFormat(fieldValue)
              : fieldValue;

            cellValue = cellValue?.toString().replace(/<[^>]*>?/gm, '').trim() // Remove html

            if (['currency', 'number', 'decimal'].includes(column.type) && !cellValue)
              cellValue = 0;

            cellFormat = column.type === 'currency' ? { t: 'n', z: '€#,##0.00' } :
                         column.type === 'number' ? { t: 'n', z: '#,##0' } :
                         column.type === 'decimal' ? { t: 'n', z: '#,##0.00' } :
                         column.type === 'date' ? { t: 'd', z: 'dd/mm/yyyy' } :
                         column.type === 'datetime' ? { t: 'd', z: 'dd/mm/yyyy hh:mm' } :
                         { t: 's' };
          } else {
            cellValue = row[column];
            cellFormat = { t: 's' };
          }

          innerRowData.push({ v: cellValue, ...cellFormat });
        })

        createXLSLFormatObj.push(innerRowData);
      })

      fileName = fileName + "." + fileType
      const ws_name = sheetName
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj)

      XLSX.utils.book_append_sheet(wb, ws, ws_name)

      XLSX.writeFile(wb, fileName)
    } catch (error) {
      console.error(error)
      return false
    }

    return true
  }

  public static getNestedValue = (object: any, value: any) => {
    value = value.replace(/\[(\w+)\]/g, ".$1")
    value = value.replace(/^\./, "")

    const parts = value.split(".")

    for (let i = 0, length = parts.length; i < length; ++i) {
      const part = parts[i]

      if (Object.keys(object).includes(part)) {
        object = object[part]
      } else {
        return
      }
    }

    return object
  }
} 