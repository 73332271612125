import BaseProvider from '@/providers/baseprovider'
import { TaskTypeEditModel } from '@/models/fieldrequest/edit/TaskType'
import { TaskTypeListViewModel } from '@/models/fieldrequest/listview/TaskType'
import PagedElements from '@/models/paged/pagedelements'
import { PagedParams } from '@/models/paged/pagedparams'
import { TaskTypeNameOnlyModel } from '@/models/fieldrequest/nameonly/TaskType'

export default class TaskTypeProvider extends BaseProvider {
    public async getTaskType(taskTypeId: number): Promise<TaskTypeEditModel | null> {
        let p: string = '';
        p = p.addParams('taskTypeIds', [taskTypeId], true);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`TaskTypes${p}`);
        return (response.data?.length || 0) == 1 ? response.data[0] as TaskTypeEditModel : null;
    }

    public async fetchPagedTaskTypes(pagedParams?: PagedParams): Promise<PagedElements<TaskTypeListViewModel>> {
        let p: string = '';
        p = p.addPagedParams(pagedParams);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`TaskTypes${p}`);
        return new PagedElements<TaskTypeListViewModel>(response.data);
    }

    public async fetchNameOnlyTaskTypes(taskTypeIds?: number[]): Promise<TaskTypeNameOnlyModel[]> {
        let p: string = '';
        p = p.addParams('name', [true]);
        p = p.addParams('taskTypeIds', taskTypeIds, true);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`TaskTypes${p}`);
        return response.data as TaskTypeNameOnlyModel[];
    }
}