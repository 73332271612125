<template>
  <div
      id="g-grid-current-time"
      class="g-grid-current-time"
      :style="{
        left: `${xDist}px`,
        height,
        width: `${VueGanttasticConstants.CURRENT_TIME_CONTAINER_WIDTH}px`
      }"
  >
    <!-- Rectangle above the marker showing the current time -->
    <div class="g-grid-current-time-box">
      {{ formattedTime }}
    </div>

    <!-- Vertical marker line -->
    <div
        class="g-grid-current-time-marker"
        :style="{ border: `1.5px solid ${colors.markerCurrentTime}` }"
    ></div>

    <span class="g-grid-current-time-text" :style="{ color: colors.markerCurrentTime }">
      <slot name="current-time-label">
        {{ currentTimeLabel }}
      </slot>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import dayjs from 'dayjs'
import useTimePositionMapping from '@/plugins/vue-ganttastic/composables/useTimePositionMapping'
import provideConfig from '@/plugins/vue-ganttastic/provider/provideConfig'
import VueGanttasticConstants from '@/plugins/vue-ganttastic/vue-ganttastic-constants'

const props = defineProps<{ scrollableHeight: string, loading: boolean }>()

// Setup variables and functions
const { mapCurrentTimeToPosition } = useTimePositionMapping()
const currentMoment = ref(dayjs())
const { colors, dateFormat, currentTimeLabel } = provideConfig()

// Calculate the position for the current time marker
const xDist = computed(() => mapCurrentTimeToPosition(currentMoment.value))

const height = computed<string>(() => {
  if (props.loading) {
    return '100%'
  }

  return props.scrollableHeight
})

// Format current time as HH:mm for display, depends on `currentMoment`
const formattedTime = computed(() => {
  return currentMoment.value.format('HH:mm') // Depend on `currentMoment` to trigger reactivity
})

let intervalId: number | undefined
let timeoutId: number | undefined

// Function to update current time and set the next update exactly at the next minute
const updateCurrentTime = () => {
  currentMoment.value = dayjs() // Update reactive `currentMoment`

  // Calculate the remaining milliseconds until the next minute
  const nextMinute = dayjs().add(1, 'minute').startOf('minute')
  const timeToNextMinute = nextMinute.diff(dayjs())

  // Clear any existing interval and set a timeout for the exact next minute
  if (timeoutId) clearTimeout(timeoutId)
  timeoutId = setTimeout(() => {
    // Update time at the start of the next minute, then set an interval for future updates
    updateCurrentTime()

    // Set a regular interval after the first exact update to update every minute
    intervalId = setInterval(updateCurrentTime, 60000)
  }, timeToNextMinute)
}

onMounted(() => {
  updateCurrentTime() // Initial call to set up the first exact update
})

onUnmounted(() => {
  if (intervalId) clearInterval(intervalId) // Cleanup interval
  if (timeoutId) clearTimeout(timeoutId) // Cleanup timeout
})
</script>

<style lang='scss'>
@import '@/scss/variables';

.g-grid-current-time {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  z-index: 4;
}

/* Rectangle for displaying current time above the marker */
.g-grid-current-time-box {
  border: 1px solid $color-redcarmin;
  background-color: $color-redcarmin;
  color: $color-ghostwhite;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;

  /* Sticky positioning */
  position: sticky;
  top: 0; /* This makes the box stick to the top as you scroll */
  z-index: 4; /* Ensure it's above other content */
}

/* Vertical marker line */
.g-grid-current-time-marker {
  width: 0px;
  height: calc(100% - 2px);
  border-left: 1.5px solid $color-redcarmin;
  position: relative;
}

/* Text for the current time label */
.g-grid-current-time-text {
  font-size: x-small;
  color: $color-redcarmin;
  margin-top: 4px;
}
</style>
