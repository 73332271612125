import {v4 as uuidv4} from 'uuid';

export default class Entity {
    [key: string]: any;
    id?: number;
    uid: string = uuidv4();
    organisation_id?: number
    name?: string;
    first_name?: string;
    last_name?: string;
    active?: boolean = true;
    email?: string;
    address?: string;
    address2?: string;
    zip_code?: string;
    city?: string;
    country_id?: number;
    phone_number?: string;
    vat_number?: string;
    creation_date?: Date;
    modification_date?: Date;
    easystore_auto_search_item: boolean = true;
    easystore_auto_search_customer: boolean = true;
    work_in_item_id?: number;
    work_out_item_id?: number;
    accounting_item_id?: number;
    loyalty_item_id?: number;
    rounded_active: boolean = true;
    force_vat_price_for_entity: boolean = false;
    picker_theme_color?: number;
    fidelity_point_type?: number;
    fidelity_type?: number;
    accounting_software_id?: number;
    chift_consumer_id?: string;
    chift_software_id?: number;
    default_payment_term_id?: number;
    iban?: string;
    bic?: string;
    can_edit_pos_invoices?: boolean = false
    name_company?: string

    constructor(init?: Partial<Entity>) {
        Object.assign(this, init);
    }

    fromJson(init: any): Entity {
        for (const key in init) {
            try {
                switch (key) {
                    case 'creation_date':
                    case 'modification_date':
                        this[key] = init[key] ? new Date(init[key]) : undefined;
                        break;
                    case'active':
                        this[key] = init[key] ?? false;
                        break;
                    default:
                        this[key] = init[key];
                }
            } catch(err) { console.warn(`Entity fromJson conversion warning: ${key} is missing`)}
        }
        return this;
    }
}
