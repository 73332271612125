import { PagedParams } from '@/models/paged/pagedparams';
import BaseProvider from '@/providers/baseprovider';
import PagedPermissionKeys from '@/models/paged/pagedpermissionkeys';
import PermissionKey from '@/models/permissionkey';

export interface fetchPagedPermissionKeysParams {
    pagedParams: PagedParams;
}
export default class PermissionKeysProvider extends BaseProvider {
    public async fetchPermissionKeys(): Promise<PermissionKey[]> {
        const response = await (await this.getApiV2()).get(`/permissionkeys`);

        return Promise.resolve(response.data);
    }

    public async fetchPagedPermissionKeys(params: fetchPagedPermissionKeysParams): Promise<PagedPermissionKeys> {
        let p: string = '';
        p = p.addPagedParams(params.pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc',
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/permissionkeys${p}`);

        const pagedPermissionKeys = new PagedPermissionKeys(response.data);

        return pagedPermissionKeys;
    }
}
