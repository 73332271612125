<template>
  <div v-bind="$attrs" class="max-height-card">
    <Card class="card-pumpkin overflow-y-hidden pt-3">
      <template #header>
        <div class="grid text-center">
          <div class="col-5 text-left">
            <h1>
              <i class="fas fa-shopping-bag color-pumpkin"></i>&nbsp;&nbsp;
              <span>{{ t('dashboard_reportz_title').toUpperCase() }}</span>
            </h1>
          </div>
          <div class="col-2 text-center">
            <!-- <Button :disabled="syncing24h || loading"
                    class="col p-button p-button-rounded p-button-sm background-gradient-sunrise"
                    :icon="'fas fa-rotate' + (syncing24h ? ' fa-spin' : '')" :label="$filters.capitalizeFirstLetter(t('sync_24h'))"
                    @click="sync24h()"/> -->
          </div>
          <div class="col-5 text-right">
            <ExportExportWithColumnSelection
                v-if="!errorMessage && (Data?.length ?? 0) > 0"
                :data="Data"
                :columns="excelColumns"
                :file-name="'Export dashboard'"
                file-type="xlsx"
                sheet-name="Data">
              <Button
                  :class="'p-button p-button-rounded p-button-sm background-gradient-sunrise'"
                  icon="fas fa-file-download"
                  :label="$filters.capitalizeFirstLetter(t('download_xlsx'))"
              />
            </ExportExportWithColumnSelection>
          </div>
        </div>
      </template>
      <template #content>
        <!-- <SkeletonGrid v-if="loading" :length="5" :columns="columnNames.map(c => t(c))" /> -->
        <Progress class="flex justify-content-center" v-if="loading" />
        <div v-else>
          <div v-if="errorMessage" class="text-center my-4"
               v-html="`
                    ${$filters.capitalizeFirstLetter(t('report_error'))}<br/>
                    ${errorMessage}
                  `"
          />
          <div v-else-if="(Data?.length ?? 0) == 0" class="text-center my-4"
               v-html="$filters.capitalizeFirstLetter(t('report_empty'))" />
          <div v-else class="grid">
            <div class="col-12 p-fluid">
              <DataTable ref="Report"
                :value="Data"
                v-model:filters="Filters"
                class="p-datatable-sm reportz-datatable background-white"
                responsiveLayout="scroll"
                filterDisplay="row"
                selectionMode="single">
                <Column>
                  <template #body="slotProps">
                    <Actions ref="buttons" class="h-full"
                             :actions="[{ icon: 'fal fa-eye', visible: (slotProps.data['document_pdf_url']?.length ?? 0) > 0 }]"
                             @action="openPdf(slotProps.data['document_pdf_url'])"
                             :rowIndex="slotProps.index" :row="slotProps.data" />
                  </template>
                </Column>
                <Column v-for="column in columns" :key="column"
                        :field="column" :filterField="column"
                        :style="'width: ' + decode(column, ['report_z'], ['170px'], 'auto')">
                  <template #header>
                    <div class="w-full"
                         :style="{ 'text-align': (typeof Data[0][column] === 'number') ? 'center' : 'left' }"
                         v-html="t(column)" />
                  </template>
                  <template #body="slotProps">
                    <div :style="{ 'text-align': integers.includes(column) || currencies.includes(column) ? 'center' : 'left' }"
                         translate="no"
                         v-html="datetimes.includes(column) ? DateHelper.formatDate(slotProps.data[column]) :
                                 integers.includes(column) ? slotProps.data[column] :
                                 currencies.includes(column) ? $filters.formatDecimal(slotProps.data[column], '€') :
                                 slotProps.data[column]" />
                  </template>
                  <template v-if="Object.keys(Filters).includes(column)" #filter="{ filterCallback }">
                    <InputText autocomplete="off" spellcheck="false"
                               type="text" v-model="Filters[column].value" @keydown.enter="filterCallback()"
                               class="p-column-filter" />
                  </template>
                </Column>
                <ColumnGroup type="footer">
                  <Row v-if="showFooter()">
                    <Column :footer="$filters.cflt('totals') + ':'" :colspan="6" footerStyle="text-align:right" />
                    <Column v-for="column in columns.slice(5)" :key="column">
                      <template #footer>
                        <div v-if="totals.includes(column)"
                             class="w-full" translate="no"
                             :style="{ 'text-align': integers.includes(column) || currencies.includes(column) ? 'center' : 'left' }"
                             v-html="total(column)">
                        </div>
                      </template>
                    </Column>
                  </Row>
                </ColumnGroup>
              </DataTable>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, inject, ref } from 'vue'
import { globalState } from '@/states/global'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import ObjectsHelper from '@/helpers/objectshelper'
import ReportHelper from '@/helpers/reporthelper'
import { FilterMatchMode } from 'primevue/api'
import { decode } from '@/helpers/deepCopy'
import Progress from '@/components/Progress.vue'
import useProvider, { UseProvider } from '@/hooks/provider'
import Actions from '@/components/Form/Actions/Actions.vue'
import DateHelper from '@/helpers/datehelper'
import ExportExportWithColumnSelection from '@/components/ExportExportWithColumnSelection.vue'
import ApplicationSymbols from '@/keys'

export default defineComponent({
  methods: { decode },
  components: {
    ExportExportWithColumnSelection,
    Actions,
    Progress
  },
  setup(props) {
    const i18n = useI18n();
    const t = i18n.t;

    const errorMessage = ref();
    const Data = ref();
    const Report = ref();
    const Filters = ref({
      report_z: { value: null, matchMode: FilterMatchMode.CONTAINS },
      store_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      journal_name: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const provider = inject<UseProvider>(ApplicationSymbols.useProvider)!;
    const loading = ref(true);

    const columns = ref([
      'report_z',
      'opening_date',
      'closing_date',
      'store_name',
      'journal_name',
      'total_vat_included',
      'total_vat_excluded',
      'total_vat_included_by_vat_rate_percent_21',
      'total_vat_included_by_vat_rate_percent_12',
      'total_vat_included_by_vat_rate_percent_6',
      'total_vat_included_by_vat_rate_percent_0',
      'total_vat_excluded_by_vat_rate_percent_21',
      'total_vat_excluded_by_vat_rate_percent_12',
      'total_vat_excluded_by_vat_rate_percent_6',
      'total_vat_excluded_by_vat_rate_percent_0',
      'vat_rate_percent_21',
      'vat_rate_percent_12',
      'vat_rate_percent_6',
      'tickets'
    ]);

    const currencies = ref ([
      'total_vat_included',
      'total_vat_excluded',
      'total_vat_included_by_vat_rate_percent_21',
      'total_vat_included_by_vat_rate_percent_12',
      'total_vat_included_by_vat_rate_percent_6',
      'total_vat_included_by_vat_rate_percent_0',
      'total_vat_excluded_by_vat_rate_percent_21',
      'total_vat_excluded_by_vat_rate_percent_12',
      'total_vat_excluded_by_vat_rate_percent_6',
      'total_vat_excluded_by_vat_rate_percent_0',
      'vat_rate_percent_21',
      'vat_rate_percent_12',
      'vat_rate_percent_6'
    ]);

    const integers = ref([
        'report_z',
        'tickets'
    ]);

    const datetimes = ref([
      'opening_date',
      'closing_date'
    ]);

    const totals = ref([...currencies.value, 'tickets'])

    const fetchData = async (dates: any) => {
      try {
        loading.value = true;

        let tmp: any = (globalState.state.selected.storeIds?.length ?? 0) == 0 ? {} :
          await provider.dash.getCAReportZ(globalState.state.selected.storeIds, dates.from, dates.to);

        tmp = ObjectsHelper.removeColumns(tmp, ['store_id', 'store_name_fr', 'store_name_nl', 'store_name_en', 'training' ]);
        tmp = ObjectsHelper.removeZeroSumColumns(tmp, [
          'total_vat_included',
          'total_vat_excluded',
          'total_vat_included_by_vat_rate_percent_21',
          'total_vat_included_by_vat_rate_percent_12',
          'total_vat_included_by_vat_rate_percent_6',
          'total_vat_included_by_vat_rate_percent_0',
          'total_vat_excluded_by_vat_rate_percent_21',
          'total_vat_excluded_by_vat_rate_percent_12',
          'total_vat_excluded_by_vat_rate_percent_6',
          'total_vat_excluded_by_vat_rate_percent_0',
          'vat_rate_percent_21',
          'vat_rate_percent_12',
          'vat_rate_percent_6'
        ]);

        errorMessage.value = null;
        Data.value = tmp;
        loading.value = false;
      } catch (r) {
        errorMessage.value = r;
        Data.value = null;
        loading.value = false;
      }
    };
    const refresh = async (dates: any) => { await fetchData(dates); };

    const syncing24h = ref(false);
    const sync24h = async () => {
      syncing24h.value = true;
      await provider.materialize.sync24h('caReportZ');
      syncing24h.value = false;
      await refresh({ from: globalState.state.period!.start, to: globalState.state.period!.end });
    }

    const showFooter = () => ReportHelper.showFooter(Report);
    const total = (field: string) => {
      if (currencies.value.includes(field)) return ReportHelper.total(Report, field, 'currency');
      else if (integers.value.includes(field)) return ReportHelper.total(Report, field, 'number');
      else return '';
    }

    const openPdf = (f: string) => window.open(f, '_blank')

    const excelColumns = computed(() => {
      const result: any[] = [];

      columns.value.map((column) => {
        result.push({
          label: t(column),
          source: column,
          type: datetimes.value.includes(column) ? 'datetime' :
                integers.value.includes(column) ? 'number' :
                currencies.value.includes(column) ? 'currency' :
                'text'
        });
      })

      return result;
    })

    return {
      t, loading,
      errorMessage,
      Data, DateHelper,
      columns, integers, currencies, datetimes, totals,
      Report, Filters, refresh, total, showFooter,
      openPdf, excelColumns,
      sync24h, syncing24h
    }
  }
})
</script>