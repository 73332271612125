import POS from "@/models/pos";
import PagedPOS from "@/models/paged/pagedpos";
import BaseProvider from "./baseprovider"
import { PagedParams } from '@/models/paged/pagedparams';
import PagedPOSMatrixTemplateLinks from "@/models/paged/pagedposmatrixtemplatelinks";

export interface IMatrixTemplateLinks {
    pos_uids: string[];
    matrix_template_uid?: string;
}

export default class POSProvider extends BaseProvider {
    public async fetchPagedPOS(entityIds: number[], storeIds: number[], pagedParams?: PagedParams): Promise<PagedPOS> {
        let p: string = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("storeIds", storeIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'license_code',
            sortOrder: 'asc',
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/pos${p}`);

        const pagedItems = new PagedPOS(response.data);

        return pagedItems;
    }

    public async fetchPOSForStore(storeId: number): Promise<POS[]> {
        const response = await (await this.getApiV2()).get(`/pos/store/${storeId}`);

        return response.data.map((x: any) => this.getPOS(x));
    }

    public async getPOSFromUid(posUid: string): Promise<POS> {
        const response = await (await this.getApiV2()).get(`/pos/${posUid}`);

        const poss = this.getPOS(response.data);

        return poss;
    }

    // Awaiting to fully validate v2
    public async getPOSFromUidv2(posUid: string): Promise<POS> {
        const response = await (await this.getApiV2()).get(`/pos/${posUid}`);

        const poss = this.getPOS(response.data);

        return poss;
    }

    public async getClickAndCollect(storeId: number) {
        const response = await (await this.getApiV2()).get(`/pos/clickandcollect/${storeId}`);
        return this.getPOS(response.data?.length > 0 ? response.data[0] : null);
    }

    public async savePOS(pos: POS): Promise<POS> {
        const response = await (await this.getApiV2()).post(`/pos/`, pos);

        const items = this.getPOS(response.data);

        return items;
    }

    // Awaiting to fully validate v2
    public async savePOSv2(pos: POS): Promise<POS> {
        const response = await (await this.getApiV2()).post(`/pos/`, pos);

        const items = this.getPOS(response.data);

        return items;
    }

    private getPOS(data: any): POS {
        const pos = new POS(data);
        if (pos.pos_kiosk) pos.pos_kiosk.languages = pos.pos_kiosk.languages ?? '';

        return pos;
    }

    public async fetchPOSMatrixTemplateLinks(
        entityIds: number[],
        pagedParams: PagedParams
    ): Promise<PagedPOSMatrixTemplateLinks> {
        let p: string = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            // pageNumber: 1,
            // pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`pos/matrixtemplatelinks${p}`);

        return new PagedPOSMatrixTemplateLinks(response?.data);
    }

    public async saveMatrixTemplateLinks(params: IMatrixTemplateLinks) {
        const response = await (await this.getApiV2()).post(`/pos/matrixtemplatelinks/link`, params);

        return response.data;
    }

    public async unlinkMatrixTemplateLinks(params: IMatrixTemplateLinks) {
        const response = await (await this.getApiV2()).post(`/pos/matrixtemplatelinks/unlink`, params);

        return response.data;
    }

    public async fetchLicenseInvoicings() {
        const response = await (await this.getApiV2()).get('/POS/LicenseInvoicings');

        return response.data;
    }
}
