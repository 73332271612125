export class Filters {
    private tx: any;
    titleCase = (input: string = ""): string => input.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());

    capitalizeFirstLetter = (input: string = ""): string => {
        const firstAlphaIndex = input.search(/[a-zA-ZàéèêëïîöôÀÉÈÊËÏÎÖÔ]/);
        if (firstAlphaIndex === -1) return input;

        const firstAlpha = input.charAt(firstAlphaIndex);
        const capitalizedFirstAlpha = firstAlpha.toUpperCase();
        return input.slice(0, firstAlphaIndex) + capitalizedFirstAlpha + input.slice(firstAlphaIndex + 1);
    }
    cfl = this.capitalizeFirstLetter;

    t = (input: string = "", ...values: any): string => {
        input = values?.length > 0 ? this.formatString(input, values) : input;
        return this.tx(input);
    }

    cflt = (input: string = "", ...values: any[]): string => {
        input = this.tx(input);
        input = this.formatString(input, values);
        return filters.capitalizeFirstLetter(input);
    }

    formatString = (template: string, ...values: any): string => {
        const r = template.replace(
            /\[\[(\d+)\]\]/g,
            (match, index) => index < (values?.length ?? 0) ? values[+match.substring(2, match.length - 2)] : ''
        );
        return r;
    }

    
    formatDecimal = (input: number = 0, symbol: string = "", separator: string = ".", thousands: boolean = true, sign: boolean = false, decimals: number = 2): string => {
        if (!input) return `0${symbol}`;
        let intPart = Math.abs(Math.trunc(input)) + "";

        const minus = input < 0; const plus = input > 0;
        const signResult = sign && plus ? '+' : minus ? '-' : '';

        intPart = thousands ?
            intPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;") :
            intPart.toString();

        const decimalPart = input.toFixed(decimals).toString().split(".")[1] ?? "0".repeat(decimals);

        const intHtml = `<span class='int-part'>${signResult}${intPart}</span>`;
        const decimalsHtml = decimals > 0 ? `<span class='decimal-part'>${separator}${decimalPart}${symbol}</span>` : '';

        return `
                <span class='price-format' data-original='${input}'>
                    ${intHtml}${decimalsHtml}
                </span>
            `;
    }

    formatInteger = (input: number = 0, symbol: String = '', thousands = '', sign: Boolean = false): string => {
        return filters.formatDecimal(input, symbol, '', false, sign, 0);
    }

    substring = (input: string = '', length: number, appendix: string = '...'): string => {
        if(input.length < length) return input;
        return input.substring(0, length)+appendix;
    }

    toPascalCase = (text: string): string => {
        return text
          .split('_')
          .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
          .join('');
    }

    init = (t: any) => { if(!this.tx) this.tx = t; }
    constructor(t?: any) { this.tx = t; }
}

const filters = new Filters();

export default filters;
