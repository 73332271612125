import ItemLevel from "@/models/itemlevel";
import Matrix from "@/models/matrix";
import MatrixButton from "@/models/matrixbutton";
import MatrixButtonViewModel from "@/models/view/matrixbuttonviewmodel";
import MatrixViewModel from "@/models/view/matrixviewmodel";
import BaseProvider from "./baseprovider"

export default class MatrixProvider extends BaseProvider {
    public async fetchAvailableMatrices(entityId: number, matrixTypeId: number, excludedIds: number[]): Promise<Matrix[]> {
        let p: string = '';
        p = p.addParams("entityId", [entityId]);
        p = p.addParams("matrixTypeId", [matrixTypeId]);
        p = p.addParams("excludedIds", excludedIds, true);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/Matrices/Availables${p}`);

        const matrices = response.data.map((d: any) => new Matrix(d));

        return matrices;
    }

    public async getMatrixFromUid(MatrixUid: string): Promise<Matrix> {
        const response = await (await this.getApiV2()).get(`/Matrices/${MatrixUid}`);

        const Matrices = this.getMatrix(response.data);

        return Matrices;
    }

    private getMatrix(data: any): Matrix {
        const matrix = new Matrix(data);

        return matrix;
    }

    public async linkStore(matrixUid: string, storeUid: string, orderNumber: number): Promise<void> {
        const response = await (await this.getApiV2()).post(`/Matrices/${matrixUid}/LinkStore/${storeUid}/OrderNumber/${orderNumber}`);

        return Promise.resolve();
    }

    public async linkPos(matrixUid: string, posUid: string, orderNumber: number): Promise<void> {
        const response = await (await this.getApiV2()).post(`/Matrices/${matrixUid}/LinkPOS/${posUid}/OrderNumber/${orderNumber}`);

        return Promise.resolve();
    }

    public async unlinkStore(matrixUid: string, storeUid: string): Promise<void> {
        const response = await (await this.getApiV2()).post(`/Matrices/${matrixUid}/UnlinkStore/${storeUid}`);

        return Promise.resolve();
    }

    public async unlinkPos(matrixUid: string, posUid: string): Promise<void> {
        const response = await (await this.getApiV2()).post(`/Matrices/${matrixUid}/UnlinkPOS/${posUid}`);

        return Promise.resolve();
    }

    public async saveStoreMatrix(matrix: Matrix, storeId: number): Promise<Matrix> {
        const response = await (await this.getApiV2()).post(`/Matrices?storeId=${storeId}`, matrix);

        const savedMatrix = this.getMatrix(response.data);

        return savedMatrix;
    }

    public async savePosMatrix(matrix: Matrix, posId: number): Promise<Matrix> {
        const response = await (await this.getApiV2()).post(`/Matrices?posId=${posId}`, matrix);

        const savedMatrix = this.getMatrix(response.data);

        return savedMatrix;
    }

    public async reorderStoreMatrixViewModels(matrices: MatrixViewModel[], storeId: number): Promise<void> {
        const response = await (await this.getApiV2()).post(`/Matrices/Reorder?storeId=${storeId}`, matrices.map(m => {
            return {
                uid: m.uid,
                order_number: m.order_number,
            };
        }));

        return Promise.resolve();
    }

    public async reorderPosMatrixViewModels(matrices: MatrixViewModel[], posId: number): Promise<void> {
        const response = await (await this.getApiV2()).post(`/Matrices/Reorder?posId=${posId}`, matrices.map(m => {
            return {
                uid: m.uid,
                order_number: m.order_number,
            };
        }));

        return Promise.resolve();
    }

    public async fetchStoreMatrixViewModels(storeId: number): Promise<MatrixViewModel[]> {
        const response = await (await this.getApiV2()).get(`/Matrices?storeId=${storeId}`);

        const matrices = response.data.map((d: any) => this.getMatrixViewModel(d));

        return matrices;
    }

    public async fetchPosMatrixViewModels(posId: number): Promise<MatrixViewModel[]> {
        const response = await (await this.getApiV2()).get(`/Matrices?posId=${posId}`);

        const matrices = response.data.map((d: any) => this.getMatrixViewModel(d));

        return matrices;
    }

    public async getMatrixButtonActionViewModels(entityId: number, matrixTypeId: number | undefined, filters: {}): Promise<MatrixButtonViewModel[]> {
        let p: string = '';
        p = p.addParams("entityId", [entityId]);
        p = p.addParams("actionTypes", ['PushMatrix']);
        
        if (matrixTypeId)
            p = p.addParams("matrixTypeId", [matrixTypeId]);

        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/Matrices/Buttons${p}`);

        const buttons = response.data.map((d: any) => this.getMatrixButtonViewModel(d));

        return buttons;
    }

    private getMatrixButtonViewModel(data: any): MatrixButtonViewModel {
        const button = new MatrixButtonViewModel({
            button: new MatrixButton(data),
            column_index: data.column_index,
            row_index: data.row_index,
            itemLevel: null,
            item_ask_price: data.item_ask_price,
            item_ask_weight: data.item_ask_weight,
            item_has_propositions: data.item_has_propositions,
            item_is_menu: data.item_is_menu,
            item_is_negative: data.item_is_negative,
            item_is_returnable_deposit: data.item_is_returnable_deposit,
        });

        if (button.itemId && data.item_level1_id) {
            button.itemLevel = new ItemLevel({
                id: data.item_level1_id,
                color: data.item_level1_color,
            })
        }

        return button;
    }

    private getMatrixViewModel(data: any): MatrixViewModel {
        const matrix = new MatrixViewModel({
            matrix: new Matrix(data),
            buttons: data.buttons?.map((b: any) => this.getMatrixButtonViewModel(b)),
            order_number: data.order_number,
        });

        return matrix;
    }

    public async saveStoreMatrixViewModel(matrix: MatrixViewModel, storeId: number): Promise<MatrixViewModel> {
        const response = await (await this.getApiV2()).post(`/Matrices?storeId=${storeId}`, {
            ...matrix.matrix,
            buttons: matrix.buttons?.map((button) => {
                return {
                    ...button.button,
                    row_index: button.row_index,
                    column_index: button.column_index,
                };
            }),
        });

        const savedMatrix = this.getMatrixViewModel(response.data);

        return savedMatrix;
    }

    public async savePosMatrixViewModel(matrix: MatrixViewModel, posId: number): Promise<MatrixViewModel> {
        const response = await (await this.getApiV2()).post(`/Matrices?posId=${posId}`, {
            ...matrix.matrix,
            buttons: matrix.buttons?.map((button) => {
                return {
                    ...button.button,
                    row_index: button.row_index,
                    column_index: button.column_index,
                };
            }),
        });

        const savedMatrix = this.getMatrixViewModel(response.data);

        return savedMatrix;
    }

    public async saveMatrixButton(button: MatrixButton): Promise<MatrixButton> {
        const response = await (await this.getApiV2()).post(`/Matrices/Button`,
            button
        );

        const savedButton = new MatrixButton(response.data);

        return savedButton;
    }
}