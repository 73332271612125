import Store from "@/models/store";
import BaseProvider from "./baseprovider";
import PagedStores from '@/models/paged/pagedstores';
import { PagedParams } from '@/models/paged/pagedparams';

export default class StoreProvider extends BaseProvider {
    public async fetchStores(): Promise<Store[]> {
        const response = await (await this.getApiV2()).get('/stores');
        return response.data.map((d: any) => new Store(d));
    }

    public async fetchPagedStores(
        entityIds: number[],
        storeIds: number[],
        pagedParams?: PagedParams
    ): Promise<PagedStores> {
        let p: string = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("storeIds", storeIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`stores${p}`);

        return new PagedStores(response?.data);
    }

    public async getStoreFromUid(storeUid: string): Promise<Store> {
        const response = await (await this.getApiV2()).get(`/stores/${storeUid}`);
        return this.getStore(response.data);
    }

    private getStore(data: any): Store {
        const s = new Store();
        s.fromJson(data);
        return s;
    }

    public async saveStore(store: Store): Promise<Store> {
        const response = await (await this.getApiV2()).post(`/stores/`, store.toJson());
        const savedStore = this.getStore(response.data);
        return savedStore;
    }

    public async getStoreFromIdV2(storeId: number): Promise<Store> {
        const response = await (await this.getApiV2()).get(`/stores/${storeId}`);

        return this.getStore(response.data);
    }
}

export function useStoreProvider() {
    return new StoreProvider()
}
