/**
 * Deep copy function for TypeScript.
 * @param T Generic type of target/copied value.
 * @param target Target value to be copied.
 * @see Source project, ts-deepcopy https://github.com/ykdr2017/ts-deepcopy
 * @see Code pen https://codepen.io/erikvullings/pen/ejyBYg
 */
export const deepCopy = <T>(target: T): T => {
    if (target === null) return target; // Handle null
    if (target instanceof Date) return new Date(target.getTime()) as T; // Handle Date
    if (Array.isArray(target)) { // Handle Array
        return target.map((item) => deepCopy(item)) as unknown as T;
    }
    if (typeof target === 'object') { // Handle Object
        const cp: { [key: string]: unknown } = {};
        for (const key in target) {
            if (Object.prototype.hasOwnProperty.call(target, key)) {
                cp[key] = deepCopy((target as { [key: string]: unknown })[key]);
            }
        }
        return cp as T;
    }
    return target; // Return primitive types as is
};


export const deepCompare = (args: any[]): boolean => {
    let i, l, leftChain: any, rightChain: any;

    function compare2Objects(x: any, y: any) {
        let p;

        // remember that NaN === NaN returns false
        // and isNaN(undefined) returns true
        if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') { return true; }

        // Compare primitives and functions.
        // Check if both arguments link to the same object.
        // Especially useful on the step where we compare prototypes
        if (x === y) { return true; }

        // Works in case when functions are created in constructor.
        // Comparing dates is a common scenario. Another built-ins?
        // We can even handle functions passed across iframes
        if ((typeof x === 'function' && typeof y === 'function') ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)) {
            return x.toString() === y.toString();
        }

        // At last checking prototypes as good as we can
        if (!(x instanceof Object && y instanceof Object)) { return false;}
         
        if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) { return false; }
        if (x.constructor !== y.constructor) { return false; }
        if (x.prototype !== y.prototype) { return false; }

        // Check for infinitive linking loops
        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) { return false; }

        // Quick checking of one object being a subset of another.
        // todo: cache the structure of arguments[0] for performance
        for (p in y) {
             
            if ((y.hasOwnProperty(p) !== x.hasOwnProperty(p)) || (typeof y[p] !== typeof x[p])) { return false; }
        }

        for (p in x) {
             
            if ((y.hasOwnProperty(p) !== x.hasOwnProperty(p)) || (typeof y[p] !== typeof x[p])) { return false; }
            switch (typeof (x[p])) {
                case 'object':
                case 'function':
                    leftChain.push(x);
                    rightChain.push(y);
                    if (!compare2Objects(x[p], y[p])) { return false; }
                    leftChain.pop();
                    rightChain.pop();
                    break;
                default:
                    if (x[p] !== y[p]) { return false; }
                    break;
            }
        }
        return true;
    }
    if (args.length < 1) { return true; }
    for (i = 1, l = args.length; i < l; i++) {
        leftChain = [];
        rightChain = [];
        if (!compare2Objects(args[0], args[i])) { return false; }
    }
    return true;
}

export const deepDiff = (obj1: any, obj2: any): boolean => {
    if (!!obj1 != !!obj2) return true;
    if (!obj1 && !obj2) return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if both objects have the same number of keys
    if (keys1.length !== keys2.length) return true;

    return !keys1.every(key => {
         
        if (obj2.hasOwnProperty(key)) {
           if (typeof obj1[key] === 'object') return !deepDiff(obj1[key], obj2[key])
           else return obj1[key] === obj2[key];
        } else return false;
    });
}

export const decode = (value: any, compares: any[], returns: any[], def: any): any => {
    for (let idx = 0; idx < (compares?.length ?? 0); idx++) {
        if (deepCompare([value, compares[idx]]) && (returns?.length > idx))
            return returns[idx];
    }
    return def;
}