import BaseProvider from '@/providers/baseprovider';
import {PagedParams} from '@/models/paged/pagedparams';
import Zone from '@/models/zone';
import PagedZones from '@/models/paged/pagedzones';

export default class ZoneProvider extends BaseProvider {
    public async fetchZones(payload: {
        entityIds?: number[],
        storeIds?: number[],
        zoneIds?: number[],
        zoneUids?: string[]
    }): Promise<Zone[]> {
        let p: string = '';
        p = p.addParams("entityIds", payload.entityIds, true);
        p = p.addParams("storeIds", payload.storeIds, true);
        p = p.addParams("zoneIds", payload.zoneIds, true);
        p = p.addParams("zoneUids", payload.zoneUids, true);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`zones${p}`);

        return response.data as Zone[];
    }

    public async fetchPagedZones(
        entityIds: number[],
        storeIds: number[],
        pagedParams: PagedParams
    ): Promise<PagedZones> {
        let p: string = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("storeIds", storeIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`zones${p}`);

        return response.data as PagedZones;
    }

    public async saveZone(zone: Zone): Promise<Zone> {
        console.log ('S: ', typeof zone);
        const response = await (await this.getApiV2()).post(`/zones/`, zone.toJson());
        const savedZone = this.getZone(response.data);
        return savedZone;
    }

    private getZone(data: any): Zone {
        const s = new Zone();
        s.fromJson(data);
        return s;
    }
}