<template>
  <div class="toggle"
       :class="{
        'toggle-down': !$props.up,
        'toggle-up': $props.up
      }">
    <slot></slot>
  </div>
</template>
<style scoped>
.toggle {
  transition-duration: 500ms;
  transition-property: max-height;
  overflow: hidden;
}
.toggle-up { max-height: 0; }
.toggle-down { max-height: 2000px; }
</style>
<script lang="ts">
import {defineComponent, ref} from 'vue';

export default defineComponent({
  props: {
    up: { type: Boolean, default: true }
  },
  emits: ["update:up"],
  setup(props, { emit }) {

    return { };
  },
});
</script>
