<!-- Currently working only for action buttons. Positioning to the right of the component is pain in the ... -->
<template>
  <div v-if="visible"
       class="relative" style="overflow: visible" >
    <div class="p-toggle-tooltip"
         :class="{
            'p-toggle-tooltip-highlight': forced,
            'p-toggle-tooltip-active': displayed
         }">
      <div class="p-toggle-tooltip-right">
        <div class="p-toggle-tooltip-arrow"></div>
        <div class="p-toggle-tooltip-text">
          {{ text }}
        </div>
      </div>
    </div>
    <!-- Needs a container with higher z-index than button and tooltip to react to mouse hover -->
    <div class="absolute" style="z-index: 2; left: 4px; width: 30px; height: 30px;"
         :style="{ 'cursor': disabled ? 'not-allowed' : visible ? 'pointer' : 'default' }"
         @mouseenter="handleOnMouseEnter"
         @mouseleave="handleOnMouseLeave"
         @mousemove="show"
         @click="$emit('click', $event)" />
    <div class="relative" ref="slotContent" style="cursor: pointer" >
      <slot></slot>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";

@keyframes TooltipAnimation {
  0% { left: 30px; }
  100% { left: 40px; }
}

.p-toggle-tooltip {
  z-index: 1;
  position: absolute;
  left: 30px;
  opacity: 0;
  white-space: nowrap;
  transition: opacity 0.2s;
}

.p-toggle-tooltip-active {
  opacity: 1;
}

.p-toggle-tooltip-right {
  position: relative;
  left: 10px;
}

.p-toggle-tooltip .p-toggle-tooltip-arrow {
  &::before {
    content: "";
    position: absolute;
    border-width: 10px;
    border-style: solid;
    left: -10px;
    top: 50%;
    transform: translateY(-50%) translateX(-30%) rotate(180deg);
  }
}

.p-toggle-tooltip:not(.p-toggle-tooltip-highlight) {
  & .p-toggle-tooltip-arrow {
    &::before {
      border-color: transparent transparent transparent $color-coal;
    }
    border-right-color: $color-coal;
  }

  & .p-toggle-tooltip-text {
    background: $color-coal;
  }
}

.p-toggle-tooltip.p-toggle-tooltip-highlight {
  animation: TooltipAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in;

  & .p-toggle-tooltip-arrow {
    &::before {
      border-color: transparent transparent transparent #ff792c;
    }
    border-right-color: #ff792c;
  }

  & .p-toggle-tooltip-text {
    @extend %gradient-pumpkin;
  }
}

.p-toggle-tooltip .p-toggle-tooltip-text {
  color: #ffffff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 0.5rem;
}

</style>
<script lang="ts">
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    text: String,
    force: Boolean,
    disabled: Boolean,
    hidden: Boolean,
    visible: { type: Boolean, default: true },
    hideOnMouseEnter: { type: Boolean, default: false},
    hideOnMouseLeave: { type: Boolean, default: true}
  },
  emits: ['click', 'hide', 'show'],
  setup(props, { emit }) {
    const active = ref<boolean>(false);
    const forced = ref<boolean>(props.force ?? false);
    const displayed = computed(() => (forced.value || active.value) && !props.hidden && props.text)

    const show = () => {
      active.value = true;
      emit('show')
    }
    const hide = () => {
      forced.value = false;
      active.value = false;
      emit('hide')
    }
    const handleOnMouseEnter = () => {
      if (!props.hideOnMouseEnter) {
        return
      }

      hide()
    }

    const handleOnMouseLeave = () => {
      if (!props.hideOnMouseLeave) {
        return
      }

      hide()
    }

    return {
      active,
      forced,
      displayed,
      show,
      hide,
      handleOnMouseEnter,
      handleOnMouseLeave
    }
  }
})
</script>
