<template>
  <li v-if='item' :class="{ 'pt-4': typeof item === 'string' }">
    <span v-if="typeof item === 'string'" class="sidebar-heading" style="margin-top: 50px">{{ item }}</span>
    <router-link v-else class="bo-list-item" :to="getLink(item?.link)" v-show="item.visible">
      <i v-if="item.icon" :class="(item.iconClass ?? 'fas') + ' fa-' + item.icon + ' color-' + item.color" />
      <img v-else-if="item.iconPath" width="28" class="image" :src="item.iconPath" />
      <div v-else-if="item.iconHtml" :class="'color-' + item.color" v-html="item.iconHtml" />
      <span class="bo-list-item-content" v-html="$t(item?.name ?? '')" />
    </router-link>
  </li>
</template>

<script lang="ts">
import RouteHelper from '@/helpers/routehelper'
import { SubMenuItem } from '@/menu/menu'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'SidebarSingleMenuItem',
  props: {
    item: [SubMenuItem, String]
  },
  setup(props) {
    const { currentRoute } = useRouter()

    const getLink = (path: string) => {
      if (props.item instanceof String || !(props.item as SubMenuItem)?.preserveQueryParams) {
        return RouteHelper.getLink(path)
      }

      return RouteHelper.getLink(path, currentRoute.value.query)
    }
    return {
      getLink
    }
  }
})
</script>

<!-- <template>
  <li v-if='item'>
    <router-link class="bo-list-item" :to="getLink(item?.link)" v-show="item.visible">
      <i v-if="item.icon" :class="'fas fa-' + item.icon + ' color-' + item.color" />
      <img v-else-if="item.iconPath" width="28" class="image" :src="item.iconPath" />
      <div v-else-if="item.iconHtml" :class="'color-' + item.color" v-html="item.iconHtml" />
      <span class="bo-list-item-content" v-html="$t(item?.name)" />
    </router-link>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { SubMenuItem } from '@/menu/menu'
import RouteHelper from '@/helpers/routehelper'

export default defineComponent({
  name: 'SidebarSingleMenuItem',
  props: {
    item: SubMenuItem
  },
  setup() {
    const getLink = (path: string) => RouteHelper.getLink(path)

    return {
      getLink
    }
  }
})
</script> -->