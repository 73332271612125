import { InjectionKey, watch } from 'vue'
import { createStore, Store as VuexStore, useStore as useVuexStore } from 'vuex'

import { deepCopy } from '@/helpers/deepCopy'
import MeasureUnit from '@/models/measureunit'
import useProvider from '@/hooks/provider'
import { globalState } from '@/states/global'
import { authState } from '@/states/auth'

export interface MeasureUnitState {
    ready: boolean,
    measureUnits: MeasureUnit[]
}

const initialState: MeasureUnitState = {
    ready: false,
    measureUnits: []
}

export const measureUnitState: VuexStore<MeasureUnitState> = createStore<MeasureUnitState>({
    state: initialState,
    mutations: {
        ready (state, payload: boolean) { state.ready = payload },
        load (state, payload: Partial<MeasureUnitState>) {
            state.ready = true;
            if (payload.measureUnits) state.measureUnits = deepCopy(payload.measureUnits);
        },
    },
    getters: {
        ready(state){ return state.ready },
        measureUnits(state) { return state.measureUnits }
    },
    actions: {
        async load(store) {
            store.commit('ready', false);
            const provider= useProvider();

            if (!authState.state.organization || authState.state.organization?.is_system)
                return store.commit('load', { measureUnits: [] });

            const measureUnits =
                (globalState.state.selected.entityIds?.length ?? 0) > 0 ?
                    await provider.measureUnit.fetchMeasureUnits({ entityIds: globalState.state.selected.entityIds }) ?? [] :
                    []

            store.commit('load', { measureUnits })
        }
    },
    plugins: [
        (store) => watch(() => globalState.getters.selectedEntities, () => store.dispatch('load'))
    ]
})
export const measureUnitStateKey: InjectionKey<VuexStore<MeasureUnitState>> = Symbol();
export const useMeasureUnitState = (): VuexStore<MeasureUnitState> => useVuexStore(measureUnitStateKey);
export default useMeasureUnitState;