import moment from 'moment/moment';
import {ComposerTranslation, useI18n} from 'vue-i18n';

export class Period {
    public label: string;
    public start: Date;
    public end: Date;
    public previousPeriodStart?: Date;

    constructor(
        start: Date,
        end: Date,
        label: string,
        previousPeriodStart?: Date
    ) {
        this.label = label;
        this.start = start;
        this.end = end;

        this.previousPeriodStart =
            previousPeriodStart ??
            moment(start)
                .subtract(moment(end).diff(start))
                .toDate();
    }
}

export const startOfToday = moment(
    new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
    )
);
export const endOfToday = moment(startOfToday).add(1, 'days').add(-1, "milliseconds");

export const startOfTomorrow = moment(startOfToday).add(1, "days");
export const endOfTomorrow = moment(startOfTomorrow).add(1, 'days').add(-1, "milliseconds");

export const startOfYesterday = moment(startOfToday).add(-1, "days");
export const endOfYesterday = moment(startOfYesterday).add(1, 'days').add(-1, "milliseconds");

export const monday = moment(startOfToday).subtract(
    startOfToday.weekday() - 1,
    "days"
);
export const nextMonday = moment(monday).add(7, "days");
export const previousMonday = moment(monday).subtract(7, "days");
export const sevenDaysAgo = moment(startOfToday).subtract(7, "days");
export const startOfMonth = moment(
    new Date(startOfToday.year(), startOfToday.month(), 1)
);
export const startOfNextMonth = moment(startOfMonth).add(1, "months");
export const startOfPreviousMonth = moment(startOfMonth).subtract(1, "months");
export const startOfAntePreviousMonth = moment(startOfMonth).subtract(2, "months");
export const thirtyDaysAgo = moment(startOfToday).subtract(30, "days");

export const startOfQuarter = moment(
    new Date(
        startOfToday.year(),
        (Math.floor(startOfToday.month() / 3) * 3),
        1
    )
);
export const startOfNextQuarter = moment(startOfQuarter).add(3, "months");
export const startOfPreviousQuarter = moment(startOfQuarter).subtract(3, "months");
export const startOfAntePreviousQuarter = moment(startOfQuarter).subtract(6, "months");

export const startOfSemester = moment(
    new Date(
        startOfToday.year(),
        (Math.floor(startOfToday.month() / 6) * 6),
        1
    )
);
export const startOfPreviousSemester = moment(startOfSemester).subtract(
    6,
    "months"
);
export const startOfNextSemester = moment(startOfSemester).add(6, "months");
export const startOfYear = moment(new Date(startOfToday.year(), 0, 1));
export const startOfNextYear = moment(startOfYear).add(1, "years");
export const startOfPreviousYear = moment(startOfYear).subtract(1, "years");
export const startOfAntePreviousYear = moment(startOfYear).subtract(2, "years");
export const threeHundredAndSixtyDaysAgo = moment(startOfToday).add(-365, "days");

export const periods = (t: ComposerTranslation) => [
    new Period(
        startOfToday.toDate(),
        endOfToday.toDate(),
        t("period.today")
    ),
    new Period(
        startOfYesterday.toDate(),
        endOfYesterday.toDate(),
        t("period.yesterday")
    ),
    new Period(
        monday.toDate(),
        moment(nextMonday).add(-1, 'milliseconds').toDate(),
        t("period.week")),
    new Period(
        previousMonday.toDate(),
        moment(monday).add(-1, 'milliseconds').toDate(),
        t("period.previous_week")
    ),
    new Period(
        sevenDaysAgo.toDate(),
        endOfYesterday.toDate(),
        t("period.last_seven_days")
    ),
    new Period(
        startOfMonth.toDate(),
        moment(startOfNextMonth).add(-1, 'milliseconds').toDate(),
        t("period.month"),
        startOfPreviousMonth.toDate()
    ),
    new Period(
        startOfPreviousMonth.toDate(),
        moment(startOfMonth).add(-1, 'milliseconds').toDate(),
        t("period.previous_month"),
        startOfAntePreviousMonth.toDate()
    ),
    new Period(
        thirtyDaysAgo.toDate(),
        moment(endOfYesterday).toDate(),
        t("period.last_thirty_days")
    ),
    new Period(
        startOfQuarter.toDate(),
        moment(startOfNextQuarter).add(-1, 'milliseconds').toDate(),
        t("period.quarter"),
        startOfPreviousQuarter.toDate()
    ),
    new Period(
        startOfPreviousQuarter.toDate(),
        moment(startOfQuarter).add(-1, 'milliseconds').toDate(),
        t("period.last_quarter"),
        startOfAntePreviousQuarter.toDate()
    ),
    new Period(
        startOfSemester.toDate(),
        moment(startOfNextSemester).add(-1, 'milliseconds').toDate(),
        t("period.semester"),
        startOfPreviousSemester.toDate()
    ),
    new Period(
        startOfYear.toDate(),
        moment(startOfNextYear).add(-1, 'milliseconds').toDate(),
        t("period.civil_year"),
        startOfPreviousYear.toDate()
    ),
    new Period(
        startOfPreviousYear.toDate(),
        moment(startOfYear).add(-1, 'milliseconds').toDate(),
        t("period.civil_previous_year"),
        startOfAntePreviousYear.toDate()
    ),
    new Period(
        threeHundredAndSixtyDaysAgo.toDate(),
        moment(startOfTomorrow).add(-1, 'milliseconds').toDate(),
        t("period.civil_365_days_ago")
    ),
    new Period(
        startOfToday.toDate(),
        endOfToday.toDate(),
        t("period.custom")
    )
];
