<template>
  <div v-bind="$attrs" class="max-height-card-footer">
    <Card class="card-bluesky">
      <template #header>
        <div class="grid">
          <div class="col">
            <h1>
              <img v-if="datawarehouse?.icon_azure_path" width="28" class="image" style="width: 28px"
                :src="datawarehouse?.icon_azure_path" />&nbsp;&nbsp; {{ localization.localize(datawarehouse) }}
            </h1>
          </div>
          <div class="col text-right">
            <!-- <ExcelExport v-if="datawarehouse" :data="processedData()"
              :columns="datawarehouseResults.columns.map((c) => ({ label: c, field: c }))"
              file-name="Export datawarehouse" file-type="xlsx" sheet-name="Data" :nested="false">
              <Button :class="'p-button p-button-rounded p-button-sm background-gradient-sunrise'"
                icon="fas fa-file-download" :label="filters.cflt('download_xlsx')" />
            </ExcelExport> -->
          </div>
        </div>
      </template>

      <template #content>

        <Progress class="flex justify-content-center" v-if="loading" />

        <div class="dx-viewport" v-else>

          <div class="flex justify-content-between align-items-center mb-2">
            <Button v-if="authState.state.globalUser?.is_admin" @click="saveDefaultLayout"
              class="p-button p-button-rounded background-gradient-green" icon="fa-regular fa-floppy-disk"
              label="Save default layout (admin only)">

            </Button>

            <Button @click="openFieldChooser()" class="p-button p-button-rounded background-gradient-sunrise"
              icon="fa-regular fa-pen" :label="t('Datawarehouse.CustomizeLayout')">

            </Button>

            <div>
              <Dropdown v-model="selectedTemplate" :options="templates" optionLabel="name_fr"
                placeholder="Select a template" class="w-full md:w-16rem" @change="selectedTemplateChanged">
                <template #value="slotProps">
                  <div v-if="slotProps.value" :style="{
    'font-style': slotProps.value.is_default ? 'italic' : undefined,
  }">
                    {{ slotProps.value.name_fr }}
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="flex justify-content-between align-items-center">
                    <div :style="{
    'font-style': slotProps.option.is_default ? 'italic' : undefined,
  }">{{ slotProps.option.name_fr }}</div>
                    <div class="flex align-items-center" v-if="slotProps.option.is_default == false">
                      <Button icon="pi pi-pencil" class="p-button-icon-only p-button-rounded p-button-text"
                        @click="editTemplate(slotProps.option)" />
                      <Button icon="pi pi-trash" class="p-button-icon-only p-button-rounded p-button-text"
                        @click="deleteTemplate(slotProps.option)" />
                    </div>

                  </div>
                </template>
              </Dropdown>
              <Button :disabled="!selectedTemplate || selectedTemplate?.is_default"
                class="ml-2 p-button p-button-rounded background-gradient-sunrise" icon="fa-regular fa-floppy-disk"
                label="Save" @click="saveTemplate"></Button>
              <Button class="ml-2 p-button p-button-rounded background-gradient-sunrise"
                icon="fa-regular fa-floppy-disk" label="Save as" @click="saveTemplateAs"></Button>
            </div>
          </div>

          <DxPivotGrid id="pivotgrid" ref="pivotGridRef" :data-source="dataSource" :show-borders="true"
            :allow-sorting-by-summary="true" :allow-sorting="true" :allow-expand-all="true" :allow-filtering="true"
            :show-totals-prior="showTotalsPrior" :data-field-area="dataFieldArea" :row-header-layout="rowHeaderLayout"
            @exporting="onExporting" @option-changed="onOptionChanged">
            <DxHeaderFilter :visible="true">
              <DxSearch :enabled="true" />
            </DxHeaderFilter>
            <DxFieldPanel :visible="true" :show-filter-fields="true" />
            <DxFieldChooser :enabled="true" :height="600" :width="800" :allow-search="true" />
            <DxExport :enabled="true" />
          </DxPivotGrid>
          <div class="options">
            <div class="caption">Options</div>
            <div class="option">
              <DxCheckBox id="show-totals-prior" :value="showTotalsPrior == 'both'"
                :on-value-changed="onShowTotalsPriorChanged" text="Show Totals Prior" />
            </div>
            <div class="option">
              <DxCheckBox id="data-field-area" :value="dataFieldArea == 'row'"
                :on-value-changed="onDataFieldAreaChanged" text="Data Field Headers in Rows" />
            </div>
            <div class="option">
              <DxCheckBox id="row-header-layout" :value="rowHeaderLayout == 'tree'"
                :on-value-changed="onRowHeaderLayoutChanged" text="Tree Row Header Layout" />
            </div>
          </div>
        </div>

      </template>
    </Card>
    <BOAskText ref="boAskText" />
  </div>
</template>


<script lang="ts">
import { authState } from '@/states/auth'
import { defineComponent, inject, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
// import ExcelExport from "@/components/ExcelExport.vue";
import { useRoute, useRouter } from 'vue-router'
import useLocalization from '@/hooks/localization'
import Datawarehouse from '@/models/datawarehouse'
import DateHelper from '@/helpers/datehelper'

import filters from '@/helpers/filters'
import Progress, { Color } from '@/components/Progress.vue'

import 'devextreme/dist/css/dx.light.css'
import {
	DxExport,
	DxFieldChooser,
	DxFieldPanel,
	DxHeaderFilter,
	DxPivotGrid,
	DxPivotGridTypes,
	DxSearch
} from 'devextreme-vue/pivot-grid'
import PivotGridDataSource, { Field, PivotGridArea, SortOrder } from 'devextreme/ui/pivot_grid/data_source'
import frMessages from 'devextreme/localization/messages/fr.json'
import enMessages from 'devextreme/localization/messages/en.json'
import nlMessages from 'devextreme/localization/messages/nl.json'
import { loadMessages, locale } from 'devextreme/localization'

import { DxCheckBox } from 'devextreme-vue/check-box'
import { saveAs } from 'file-saver-es'
import { exportPivotGrid } from 'devextreme/excel_exporter'
import { UseProvider } from '@/hooks/provider'
import DatawarehouseTemplate, { ILayoutJSON, ILayoutJSONField } from '@/models/datawarehousetemplate'
import { globalState } from '@/states/global'
import { Workbook } from 'exceljs'
import { v4 as uuidv4 } from 'uuid'
import useNotification from '@/hooks/notification'
import BOAskText, { IAskTextOptions } from '@/components/Dialog/BOAskText.vue'
import { DropdownChangeEvent } from 'primevue/dropdown'
import ApplicationSymbols from '@/keys'

export default defineComponent({
  components: {
    Progress,
    // ExcelExport,
    DxPivotGrid,
    DxFieldChooser,
    DxCheckBox,
    DxExport,
    DxFieldPanel,
    DxHeaderFilter,
    BOAskText,
    DxSearch,
  },
  setup(props) {
    const i18n = useI18n();
    const { t } = useI18n();
    const route = useRoute();
    const localization = useLocalization();
    const notification = useNotification();
    const router = useRouter();

    const provider = inject<UseProvider>(ApplicationSymbols.useProvider)!;
    const loading = ref(true);

    const datawarehouse = ref<Datawarehouse>();
    const pivotGridRef = ref<DxPivotGrid>();
    const boAskText = ref();

    const showTotalsPrior = ref('none');
    const dataFieldArea = ref('column');
    const rowHeaderLayout = ref('tree');

    const selectedTemplate = ref<DatawarehouseTemplate>();
    const templates = ref<DatawarehouseTemplate[]>();

    const dataSource = ref<PivotGridDataSource>(new PivotGridDataSource());

    const datawarehouseResults: any = reactive({
      raw: [],
      data: [],
      dataColumns: [],
    });

    watch(
      () => router.currentRoute.value,
      (v) => {
        selectedTemplate.value = undefined;
      },
    );

    onMounted(() => {
      switch (i18n.locale.value) {
        case 'fr': loadMessages(frMessages); break;
        case 'en': loadMessages(enMessages); break;
        case 'nl': loadMessages(nlMessages); break;
        default: loadMessages(frMessages); break;
      }

      locale(i18n.locale.value ?? "fr");
    });

    const fetchDatawarehouseResults = async (dates: any) => {
      try {
        loading.value = true;
        const tmp: any =
            ((globalState.state.selected.storeIds?.length ?? 0) == 0) || isNaN(Number(route.params.datawarehouseId))
                ? { columns: [], rows: [] }
                : await provider.datawarehouse.getDashboardDatawarehouseResults(
                    Number(route.params.datawarehouseId),
                    globalState.state.selected.storeIds,
                    dates.from,
                    dates.to
                  );
        datawarehouseResults.raw = tmp.rows ? JSON.parse(JSON.stringify(tmp.rows)) : [];
        datawarehouseResults.data = tmp.rows ?? [];
        datawarehouseResults.dataColumns = tmp.columns ?? [];
        loading.value = false;
        console.log("datawarehouseResults", datawarehouseResults)
        loadDataSource();
        loadTemplate(selectedTemplate.value);
      } catch (r) {
        loading.value = false;
      }
    };

    const loadDataSource = () => {
      // force new data source
      dataSource.value = new PivotGridDataSource({
        retrieveFields: false,
        fields: datawarehouseResults.dataColumns.map((c: any) => {

          let dataType = 'string';
          let format = undefined;

          switch (c.type) {
            case "numeric":
              dataType = "number";
              format = {
                precision: 2,
                useGrouping: true,
              };
              break;

            case "date":
              dataType = "date";
              format = 'dd/MM/yyyy';
              break;

            case "datetime":
              dataType = "date";
              format = 'dd/MM/yyyy HH:mm';
              break;
          }

          return {
            name: c.columnName,
            caption: c.caption,
            dataField: c.columnName,
            summaryType: 'sum',
            dataType: dataType,
            format: format,
          } as Field;
        }),
        store: datawarehouseResults.data,
      });
    }

    const loadTemplate = (template: DatawarehouseTemplate | undefined) => {
      if (!template?.layout_json) return;

      const layoutJson = JSON.parse(template.layout_json) as ILayoutJSON;

      // example of layoutJSON saved in the database
      // { "showTotalsPrior": "none", "dataFieldArea": "column", "rowHeaderLayout": "tree", "fields": [{ "dataField": "table_identifier", "area": "data" }, { "dataField": "table_name", "area": "column", "filterValues": null }, { "dataField": "field_name", "area": "column" }, { "dataField": "User", "area": "row", "sortOrder": "desc", "filterValues": ["advensys"] }, { "dataField": "old_value", "area": "filter" }, { "dataField": "new_value", "area": "filter" }] }

      console.log("layoutJson ok", layoutJson)

      showTotalsPrior.value = layoutJson.showTotalsPrior;
      dataFieldArea.value = layoutJson.dataFieldArea;
      rowHeaderLayout.value = layoutJson.rowHeaderLayout;

      layoutJson.fields.forEach(c => {
        const field = dataSource.value.fields().find(f => f.dataField === c.dataField);

        if (field) {
          if (c.area)
            field.area = c.area as PivotGridArea;

          if (c.filterValues)
            field.filterValues = c.filterValues as string[];

          if (c.sortOrder)
            field.sortOrder = c.sortOrder as SortOrder;

          if (c.areaIndex)
            field.areaIndex = c.areaIndex;

          // if (field.area == 'data' && field.dataType == 'number') {
          //   field.format = {
          //     precision: 4,
          //   };
          // }
        }
      });
    };

    const fetchDatawarehouseTemplates = async () => {
      const newTemplates = await provider.datawarehouse.getTemplates(Number(datawarehouse.value?.id));

      console.log("from provider templates.value", templates.value)

      const defaultTemplate = await provider.datawarehouse.fetchDefaultLayout(Number(datawarehouse.value?.id));

      if (defaultTemplate.layout_json) {
        defaultTemplate.name_fr = t("default_template");

        newTemplates.splice(0, 0, defaultTemplate);

        if (!selectedTemplate.value)
          selectedTemplate.value = defaultTemplate;
      }

      templates.value = newTemplates;
    };

    const fetchDatawarehouse = async () => {
      datawarehouse.value = await provider.datawarehouse.getDashboardDatawarehouse(Number(route.params.datawarehouseId));
    };

    const refresh = async (dates: any) => {
      dataSource.value = new PivotGridDataSource({});

      await fetchDatawarehouse();
      await fetchDatawarehouseTemplates();
      await fetchDatawarehouseResults(dates);
    };

    const onShowTotalsPriorChanged = (data: any) => {
      showTotalsPrior.value = data.value ? 'both' : 'none';
    }
    const onDataFieldAreaChanged = (data: any) => {
      dataFieldArea.value = data.value ? 'row' : 'column';
    }
    const onRowHeaderLayoutChanged = (data: any) => {
      rowHeaderLayout.value = data.value ? 'tree' : 'standard';
    }

    const onOptionChanged = (e: DxPivotGridTypes.OptionChangedEvent) => {
      console.log("changed", e);
    };

    const onExporting = (e: DxPivotGridTypes.ExportingEvent) => {
      const name = `${localization.localize(selectedTemplate.value) ?? localization.localize(datawarehouse.value) ?? "Datawarehouse"}`;

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(name);

      exportPivotGrid({
        component: e.component,
        worksheet,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${name}.xlsx`);
        });
      });
    };

    const getLayoutJson = () => {
      const fields = dataSource.value.fields().filter(f => f.area).map(f => {
        return {
          dataField: f.dataField,
          area: f.area,
          sortOrder: f.sortOrder,
          filterValues: f.filterValues,
          areaIndex: f.areaIndex,
        } as ILayoutJSONField;
      });

      const layout: ILayoutJSON = {
        showTotalsPrior: showTotalsPrior.value, // 'none'
        dataFieldArea: dataFieldArea.value, // 'column'
        rowHeaderLayout: rowHeaderLayout.value, // 'standard'
        fields: fields,
      };

      return layout;
    };

    const saveDefaultLayout = () => {
      if (!datawarehouse.value?.id) return;

      const layout = getLayoutJson();

      provider.datawarehouse.saveDefaultLayout(datawarehouse.value?.id, JSON.stringify(layout));
    };

    const saveTemplate = async () => {
      if (!datawarehouse.value?.id) return;
      if (!selectedTemplate.value?.uid) return;

      const layout = getLayoutJson();

      selectedTemplate.value.layout_json = JSON.stringify(layout);

      await provider.datawarehouse.saveTemplate(datawarehouse.value?.id, selectedTemplate.value);

      await fetchDatawarehouseTemplates();
    };

    const saveTemplateAs = async () => {
      if (!datawarehouse.value?.id) return;

      boAskText.value.show("Nouveau nom", "", {
        accepted: async (value: string) => {

          const layout = getLayoutJson();

          const newTemplate = new DatawarehouseTemplate();
          newTemplate.uid = uuidv4();
          newTemplate.name_fr = value;
          newTemplate.name_en = value;
          newTemplate.name_nl = value;
          newTemplate.layout_json = JSON.stringify(layout);

          await provider.datawarehouse.saveTemplate(datawarehouse.value!.id!, newTemplate);

          await fetchDatawarehouseTemplates();
        },
        rejected: () => {

        },
      } as IAskTextOptions);
    };

    const editTemplate = (template: DatawarehouseTemplate) => {
      if (!datawarehouse.value?.id) return;

      boAskText.value.show("Nouveau nom", template.name_fr, {
        accepted: async (value: string) => {

          template.name_fr = value;
          template.name_en = value;
          template.name_nl = value;

          await provider.datawarehouse.saveTemplate(datawarehouse.value!.id!, template);

          await fetchDatawarehouseTemplates();
        },
        rejected: () => {

        },
      } as IAskTextOptions);
    };

    const deleteTemplate = (template: DatawarehouseTemplate) => {
      notification.askConfirmation(
        filters.cflt('delete_datawarehouse_template'),
        {
          accepted: async () => {
            await provider.datawarehouse.deleteTemplate(datawarehouse.value!.id!, template.uid!);

            await fetchDatawarehouseTemplates();
          }
        }
      );
    };

    const selectedTemplateChanged = (event: DropdownChangeEvent) => {
      loadDataSource();
      loadTemplate(selectedTemplate.value);
    };

    const openFieldChooser = () => {
      pivotGridRef.value?.instance?.getFieldChooserPopup().show();
    };

    return {
      filters,
      loading,
      datawarehouseResults,
      refresh,
      t,
      localization,
      datawarehouse,
      DateHelper,
      Color,
      dataSource,
      showTotalsPrior,
      dataFieldArea,
      rowHeaderLayout,
      onShowTotalsPriorChanged,
      onDataFieldAreaChanged,
      onRowHeaderLayoutChanged,
      onExporting,
      saveDefaultLayout,
      pivotGridRef,
      authState,
      selectedTemplate,
      templates,
      saveTemplate,
      saveTemplateAs,
      editTemplate,
      deleteTemplate,
      boAskText,
      selectedTemplateChanged,
      onOptionChanged,
      openFieldChooser,
    };
  },
});
</script>

<style scoped>
.options {
  padding: 20px;
  margin-top: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option:last-child {
  margin-right: 0;
}

.option {
  width: 33%;
  display: inline-block;
  margin-top: 10px;
  margin-right: 4px;
}
</style>