<template>
  <div class="flex flex-row">
    <Button
        class="p-button-link"
        @click="displayModal = !displayModal"
        :disabled="disabled"
    >
      <div class="p-button-vertical mr-2">
        <i class="p-button-icon far fa-calendar-alt fa-xxl"></i><br/>
        <span class="p-button-label"
              v-text="$filters.capitalizeFirstLetter($t('period_label'))" />
      </div>
      <span v-text="formatDate(period.start)"></span>
      <i class='fa fa-arrow-right mx-3'></i>
      <span v-text="formatDate(period.end)"></span>
    </Button>
    <Dialog
        :header="t('period_title')"
        v-model:visible="displayModal"
        :draggable="false"
        :modal="true"
        @hide="displayModal = false"
        @show="forceNextMonth"
    >
      <div class="flex flex-column xl:flex-row justify-content-center mt-2">
        <div class="mx-1 flex-order-1 lg:flex-order-0">
          <DataTable
              :value="periods(t).slice(0, -1)"
              selectionMode="single"
              dataKey="id"
              @rowSelect="onRowSelect"
              responsiveLayout="scroll"
              class="background-white period-selector">
            <Column field="label">
              <template #body="slotProps">
                <div :class="'period_' + slotProps.index">
                  {{ slotProps.data["label"] }}
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
        <div class="flex flex-column">
          <div class="period_custom mx-2 mb-0 md:mt-1 flex justify-content-between align-items-center">
            <div v-text="$filters.capitalizeFirstLetter($t('period.custom'))"></div>
            <ToggleChevron v-if="!['lg', 'xl'].includes(type)"
                           v-model:up="customUpChevron" />
          </div>
          <TogglePanel class="m-2" v-model:up="customUpPanel">
            <div class="flex flex-column lg:flex-row">
              <div class="flex flex-column mx-1"
                   :style="{ 'min-height' : ['lg', 'xl'].includes(type) ? '500px' : 'auto' }">
                <span class="p-input-icon-left">
                  <i class="fal fa-calendar-arrow-up" />
                  <InputMask v-model="inputMin" class="w-full"
                             autocomplete="off" spellcheck="false"
                             @blur="onInputBlur('min', $event)"
                             mask="99/99/9999" placeholder="99/99/9999" slotChar="dd/mm/yy" />
                </span>
                <Calendar aria-autocomplete="none"
                          ref="calendarMin" class="mt-1"
                          :class="{ 'hideNext': hideNext }"
                          v-model="dates.both" :inline="true"
                          :maxDate="dates.max"
                          :showOtherMonths="true" :selectOtherMonths="true"
                          selectionMode="range" />
              </div>
              <div class="flex flex-column mx-1"
                   :style="{ 'min-height' : ['lg', 'xl'].includes(type) ? '500px' : 'auto' }">
                <span class="p-input-icon-left">
                  <i class="fal fa-calendar-arrow-down" />
                  <InputMask v-model="inputMax" class="w-full"
                             autocomplete="off" spellcheck="false"
                             @blur="onInputBlur('max', $event)"
                             mask="99/99/9999" placeholder="99/99/9999" slotChar="dd/mm/yy" />
                </span>
                <Calendar aria-autocomplete="none"
                          ref="calendarMax" class="mt-1"
                          :class="{ 'hidePrev': hidePrev }"
                          v-model="dates.both" :inline="true" :minDate="dates.min"
                          :showOtherMonths="true" :selectOtherMonths="true"
                          selectionMode="range"
                />
              </div>
            </div>
            <div class="flex justify-content-end">
              <Button
                  v-if="!['lg', 'xl'].includes(type)"
                  :label="$filters.capitalizeFirstLetter($t('period_apply'))"
                  class="p-button p-button-rounded background-gradient-sunrise mx-1 mt-2"
                  icon="pi pi-check"
                  @click="apply"
                  autofocus
              />
            </div>
          </TogglePanel>
          <div v-if="['lg', 'xl'].includes(type)"
               class="flex justify-content-end mt-2">
            <Button
                :label="$filters.capitalizeFirstLetter($t('period_apply'))"
                class="p-button p-button-rounded background-gradient-sunrise mx-1"
                icon="pi pi-check"
                @click="apply"
                autofocus
            />
            <Button
                :label="$filters.capitalizeFirstLetter($t('period_cancel'))"
                class="p-button p-button-rounded p-button-outlined mx-1"
                icon="pi pi-cross"
                @click="cancel"
                autofocus
            />
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>
<style scoped>
  ::v-global(.p-calendar.hidePrev .p-datepicker-prev) { visibility: hidden }
  ::v-global(.p-calendar.hideNext .p-datepicker-next) { visibility: hidden }
</style>
<script lang="ts">

import {
  computed,
  defineComponent,
  nextTick,
  onMounted, PropType,
  reactive,
  ref
} from 'vue';
import {useI18n} from 'vue-i18n';
import {endOfToday, Period, periods, startOfToday, startOfTomorrow} from '@/models/common/period';
import moment from 'moment';
import ToggleChevron from '@/components/Tools/ToggleChevron/ToggleChevron.vue';
import TogglePanel from '@/components/Tools/TogglePanel/TogglePanel.vue';
import useBreakpoints from '@/compositions/useBreakpoints';
import {deepCompare} from '@/helpers/deepCopy';
import {globalState} from '@/states/global';

export default defineComponent({
  components: {TogglePanel, ToggleChevron},
  props: {
    default: Object as PropType<Period>,
    disabled: Boolean
  },
  emits: ["periodChanged"],
  setup(props, {emit}) {
    const { width, type } = useBreakpoints();

    const t = useI18n().t;
    const displayModal = ref(false);
    const period = ref<Period>(periods(t)[0]);
    const items = ref([{}]);

    const inputMin = ref<string>();
    const inputMax = ref<string>();

    const calendarMin = ref();
    const calendarMax = ref();

    const customUpChevron = ref<boolean>(['lg', 'xl'].includes(type.value));

    const customUpPanel = computed(
        () => !(customUpChevron.value || ['lg', 'xl'].includes(type.value))
    );

    const hidePrev = computed((): boolean =>
      (calendarMax.value?.currentYear * 12 + (calendarMax.value?.currentMonth ?? 0)) <=
      (calendarMin.value?.currentYear * 12 + (calendarMin.value?.currentMonth ?? 0)) + 1
    );

    const hideNext = computed((): boolean =>
      (calendarMin.value?.currentYear * 12 + (calendarMin.value?.currentMonth ?? 0)) >=
      (calendarMax.value?.currentYear * 12 + (calendarMax.value?.currentMonth ?? 0)) - 1
    );

    const formatDate = (d: Date) => moment(d).format('DD/MM/YYYY');

    const dates = reactive({
      min: startOfToday.toDate(),
      max: endOfToday.toDate(),
      both: computed({
        get: (): Array<Date> => { return [dates.min, dates.max]; },
        set: (d: Array<Date>) => {
          dates.min = d[0]; inputMin.value = moment(d[0]).format('DD/MM/YYYY');
          dates.max = d[1]; inputMax.value = moment(d[1]).format('DD/MM/YYYY');
          nextTick(() => forceNextMonth());
        }
      })
    });

    const forceNextMonth = () => {
      if ([null, undefined].includes(calendarMin.value?.currentYear) ||
          [null, undefined].includes(calendarMin.value?.currentMonth) ||
          [null, undefined].includes(calendarMax.value?.currentYear) ||
          [null, undefined].includes(calendarMax.value?.currentMonth))
        return;

      if ((calendarMin.value.currentYear * 12 + calendarMin.value.currentMonth) >=
          (calendarMax.value.currentYear * 12 + calendarMax.value.currentMonth)) {
        if (calendarMin.value.currentMonth == 11) {
          calendarMax.value.currentYear = calendarMin.value.currentYear + 1;
          calendarMax.value.currentMonth = 0;
        } calendarMax.value.currentMonth = calendarMin.value.currentMonth + 1;
      }
    }

    const onInputBlur = (m: 'min' | 'max', e: any) => {
      const d = moment(e.target.value, 'DD/MM/YYYY').toDate();

      if (d instanceof Date && !isNaN(d.getTime())) { dates[m] = d }
      else e.target.value = moment(dates[m]).format('DD/MM/YYYY');
    }

    const onRowSelect = (event: any) => {
      dates.min = event.data.start; inputMin.value = moment(event.data.start).format('DD/MM/YYYY');
      dates.max = event.data.end; inputMax.value = moment(event.data.end).format('DD/MM/YYYY');
      if (!['lg', 'xl'].includes(type.value)) apply(event.data);
      nextTick(() => forceNextMonth());
    };

    const apply = (p?: Period) => {
      const pOld = [period.value.start, period.value.end];

      if (p) period.value = p;
      else if ((period.value.start !== dates.min) || (period.value.end !== dates.max))
        period.value = periods(t)[13];

      period.value.start = dates.min ?? startOfToday.toDate();
      period.value.end = dates.max ?? dates.min;
      if (!deepCompare([pOld, [period.value.start, period.value.end]])) emit("periodChanged", period.value);
      displayModal.value = false;
    }

    const cancel = () => { displayModal.value = false; }

    onMounted(() => {
      const pOld = [period.value.start, period.value.end];
      inputMin.value = moment(dates.min).format('DD/MM/YYYY');
      inputMax.value = moment(dates.max).format('DD/MM/YYYY');
      period.value = props.default ?? period.value;
      if (!globalState.getters.period) globalState.dispatch('setPeriod', periods(useI18n().t)[0]);
      if (!deepCompare([pOld, [period.value.start, period.value.end]])) emit("periodChanged", period.value);
    })

    return {
      type,
      displayModal, customUpChevron, customUpPanel,
      inputMin, inputMax,
      calendarMin, calendarMax,
      periods, t, items, period, formatDate, dates,
      apply, cancel,
      onInputBlur, onRowSelect,
      hideNext, hidePrev,
      forceNextMonth
    };
  }
})
</script>