import Datawarehouse from "@/models/datawarehouse";
import BaseProvider from "./baseprovider"
import DatawarehouseTemplate from "@/models/datawarehousetemplate";
import DatawarehouseViewModel from "@/models/view/datawarehouseviewmodel";
import GlobalCategory from "@/models/globalcategory";
import Cached from '@/plugins/cached';
import moment from 'moment';

export default class DatawarehouseProvider extends BaseProvider {
    private static longTTL = 1000 * 60 * 30;

    public async fetchDefaultLayout(datawarehouseId: number): Promise<DatawarehouseTemplate> {
        const response = await Cached.request(
            'GET',
            `datawarehouses/${datawarehouseId}/template/default`,
            undefined, undefined,
            2,
            false,
            false);

        const r = this.getDatawarehouseTemplate(response.data);

        return r;
    }

    public async saveDefaultLayout(datawarehouseId: number, layoutJson: string): Promise<DatawarehouseTemplate> {
        const response = await Cached.request(
            'POST',
            `datawarehouses/${datawarehouseId}/template/default`,
            undefined, { layout_json: layoutJson },
            2,
            false,
            false);

        const template = this.getDatawarehouseTemplate(response.data);

        return template;
    }

    private getDatawarehouseTemplate(data: any): DatawarehouseTemplate {
        const template = new DatawarehouseTemplate(data);

        return template;
    }

    public async getTemplates(datawarehouseId: number): Promise<DatawarehouseTemplate[]> {
        const response = await Cached.request(
            'GET',
            `datawarehouses/${datawarehouseId}/templates`,
            undefined, undefined,
            2,
            false,
            false);

        const templates = response.data.map((d: any) => this.getDatawarehouseTemplate(d));

        return templates;
    }

    public async saveTemplate(datawarehouseId: number, template: DatawarehouseTemplate): Promise<DatawarehouseTemplate> {
        const response = await Cached.request(
            'POST',
            `datawarehouses/${datawarehouseId}/templates`,
            undefined, template,
            2,
            false,
            false);

        const t = this.getDatawarehouseTemplate(response.data);

        return t;
    }

    public async deleteTemplate(datawarehouseId: number, templateUid: string): Promise<boolean> {
        const response = await Cached.request(
            'DELETE',
            `datawarehouses/${datawarehouseId}/templates/${templateUid}`,
            undefined, undefined,
            2,
            false,
            false);

        return true;
    }
    
    public async getDashboardDatawarehouseViewModels(): Promise<DatawarehouseViewModel[]> {
        const response = await Cached.request(
            'GET',
            `datawarehouses/dashboard`,
            undefined, undefined,
            2,
            false,
            false);

        return this.received(response, d => this.getDatawarehouseViewModel(d));
    }

    public async getDashboardDatawarehouse(datawarehouseId: number): Promise<Datawarehouse> {
        const response = await Cached.request(
            'GET',
            `datawarehouse/dashboard/${datawarehouseId}`,
            undefined, undefined,
            2,
            false,
            false);

        return this.received(response, d => this.getDatawarehouseObject(d), false);
    }

    private getDatawarehouseObject(data: any): Datawarehouse {
        const dw = new Datawarehouse(data);

        return dw;
    }

    public async getDashboardDatawarehouseResults(datawarehouseId: number, storeIds: number[], fromDate: Date, toDate: Date): Promise<object> {
        const currentDate: Date = moment().startOf('day').toDate();
        const fromString: string = moment(fromDate).format("yyyy-MM-DD");
        const toString: string = moment(toDate).format("yyyy-MM-DD");

        const response = await Cached.request(
            'GET',
            `datawarehouse/dashboard/results/${datawarehouseId}/${fromString}/${toString}`,
            { storeIds }, undefined,
            2,
            false,
            toDate < currentDate ? { ttl: DatawarehouseProvider.longTTL } : true);

        return this.received(response)
    }

    private getDatawarehouseViewModel(data: any): DatawarehouseViewModel {
        return new DatawarehouseViewModel({
            datawarehouse: new Datawarehouse({
                id: data.id,
                name_fr: data.name_fr,
                name_en: data.name_en,
                name_nl: data.name_nl,
            }),
            globalCategory: new GlobalCategory({
                id: data.global_category_id,
                name_fr: data.global_category_name_fr,
                name_en: data.global_category_name_en,
                name_nl: data.global_category_name_nl,
            })
        });
    }
}
